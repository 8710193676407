export default {
    push(state, message) {
        state.messages.push(message)
    },
    remove(state, id) {
        const i = state.messages.findIndex((el) => {
            return el.id == id
        })
        if (i == -1) {
            return
        }

        state.messages.splice(i, 1)
    }
}
