<template>
    <div>
        <template v-if="footerName === 'msuservicestatus'">
            <MsuFooter />
        </template>
        <template v-else-if="footerName === 'technion'">
            <TechnionFooter />
        </template>
        <template v-else-if="footerName === 'ou'">
            <OuFooter />
        </template>
        <template v-else-if="footerName === 'educationscotland'">
            <EducationScotlandFooter />
        </template>
        <template v-else-if="footerName === 'assembla'">
            <AssemblaFooter />
        </template>
        <template v-else-if="footerName === 'stil'">
            <StilFooter />
        </template>
        <template v-else-if="footerName === 'citrix'">
            <CitrixFooter />
        </template>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AssemblaFooter from './CustomFooters/AssemblaFooter.vue'
import CitrixFooter from './CustomFooters/CitrixFooter.vue'
import EducationScotlandFooter from './CustomFooters/EducationScotlandFooter.vue'
import MsuFooter from './CustomFooters/MsuFooter.vue'
import OuFooter from './CustomFooters/OuFooter.vue'
import StilFooter from './CustomFooters/StilFooter.vue'
import TechnionFooter from './CustomFooters/TechnionFooter.vue'

export default {
    name: 'CustomFooter',
    components: {
        AssemblaFooter,
        CitrixFooter,
        EducationScotlandFooter,
        MsuFooter,
        OuFooter,
        StilFooter,
        TechnionFooter
    },
    computed: {
        ...mapGetters(['config']),
        footerName() {
            return this.config?.footer_name
        }
    }
}
</script>
