import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
    messages: []
};

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true
}
