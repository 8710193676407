export default {
    calendar(state, getters) {
        const maintenances = []
        for (let date in state.calendarMaintenances.days) {
            const dayObject = state.calendarMaintenances.days[date]

            const events = (dayObject.maintenance_ids || [])
                .map(id => getters.byId(id))
                .filter(maintenance => !!maintenance)

            const [year, month, day] = date.split('-')

            maintenances.push({
                date: new Date(year, month - 1, day).toLocaleDateString(),
                events,
                past: dayObject.past
            })
        }

        return maintenances
    },
    today(state) {
        for (let date in state.calendarMaintenances.days) {
            if (state.calendarMaintenances.days[date].today) {
                let localDate = new Date(date);
                return new Date(localDate.getUTCFullYear(),
                                localDate.getUTCMonth(),
                                localDate.getUTCDate());
            }
        }
        return new Date()
    },
    current(state) {
        return state.currentMaintenanceIds.map(id => state.maintenances[id]);
    },
    future(state) {
        return state.futureMaintenanceIds.map(id => state.maintenances[id])
    },
    planned(_, getters, rootGetters) {
        return rootGetters.config.maintenance_list_mode === 0 ?
            getters.calendar :
            getters.future
    },
    byId(state) {
        return (id) => {
            return state.maintenances[id] || { id }
        }
    },
    date(state) {
        return state.date
    },
    futureEventsExist(state, getters, rootGetters) {
        return rootGetters.config.maintenance_list_mode === 1 ?
            !!getters.future.length :
            !!state.calendarMaintenances.future_events_count
    }
}
