export default {
    services(state) {
        return (state.groups || []).reduce((acc, group) => {
            return acc.concat(
                (group.services || [])
                    .map(service => {
                        return {
                            id: service.id,
                            name: `[${group.name}] ${service.name}`
                        }
                    })
                )
            }, []);
    },
    data(state) {
        return (serviceId) => {
            const data = state.data[serviceId]

            return data ? {
                average: Math.floor(data.avg_uptime * 10000) / 100,
                labels: data.events.map(event => event.timestamp * 1000),
                values: data.events.map(event => event.uptime_value)
            } : {
                average: undefined,
                labels: [],
                values: []
            }
        }
    },
    range(_, getters) {
        const date = new Date()
        switch (getters.rangeName) {
            case 'day':
                date.setDate(date.getDate() - 1)
                break
            case 'month':
                date.setDate(date.getDate() - 31)
                break
            case 'year':
                date.setDate(date.getDate() - 365)
                break
            case 'week':
                date.setDate(date.getDate() - 7)
                break
        }
        return {
            name: getters.rangeName,
            from: date,
            to: new Date()
        }
    },
    rangeName(state, _, rootGetters) {
      return state.range || rootGetters.config.uptime_ranges?.[0] || 'day'
    },
    service(state) {
        return state.service;
    }
}
