<template>
    <div class="footer">
        <div class="container-fluid">
            <div class="footeripar iparsys parsys">
                <div class="footer2012 parbase section">
                    <div class="row-fluid">
                        <div class="footerColumn span4">
                            <div class="footerlogo"><img alt="OU" src="https://www.ou.edu/content/dam/homepage2012/design/footerlogo.png"/></div>
                            <div class="footeraddress"><a href="https://www.ou.edu/ouit.html">OU Information Technology</a><br/>660 Parrington Oval, Room 321<br />
                                Norman, OK 73019<br />
                                Phone: 405.325.4357<br /></div>
                        </div>
                        <div class="footerColumn span4">
                            <div><div>
                                <div>
                                    <ul class="2columns unstyled"><div class="first column" style="width:50%; float: left;">
                                        <li><a href="http://www.ou.edu/publicaffairs/WebPolicies/accessstatement.html">Accessibility</a></li>
                                        <li><a href="http://www.ou.edu/sustainability.html">Sustainability</a></li>
                                        <li><a href="http://ouhsc.edu/hipaa/">HIPAA</a></li>
                                        <li><a href="http://hr.ou.edu/jobs/">OU Job Search</a></li>
                                    </div>
                                        <div class="last column" style="width:50%; float: left;">
                                            <li><a href="http://www.ou.edu/web/landing/policy.html">Policies</a></li>
                                            <li><a href="http://www.ou.edu/web/landing/legalnotices.html">Legal Notices</a></li>
                                            <li><a href="http://www.ou.edu/content/publicaffairs/WebPolicies/copyright.html">Copyright</a></li>
                                            <li><a href="http://www.ou.edu/web/resources_offices.html">Resources &amp; Offices</a></li>
                                            <li><a href="https://ouregents.ethicspoint.com">OU Report It!</a></li>
                                        </div><br style="clear:both;"></ul>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div class="footerColumn span4">
                            <div>
                                <div>
                                    <p class="byline">OU IT Social Media</p>
                                </div></div>
                            <div id="footer_social_icons">
                                <div id="footer_social_icons">
                                    <div>
                                        <ul>
                                            <li><a target="_blank" href="https://www.facebook.com/ouitsolutions" class="facebook">facebook</a></li>
                                            <li><a target="_blank" href="https://twitter.com/ouitsolutions" class="twitter">twitter</a></li>
                                            <li><a target="_blank" href="https://www.youtube.com/user/OUTechnologyForAll" class="youtube">youtube</a></li>
                                            <li><a target="_blank" href="http://www.linkedin.com/company/university-of-oklahoma-information-technology" class="linkedin">linkedin</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row-fluid">
                        <div class="footerBottom span12">
                            <div><div></div></div>
                            Updated 8/10/2021 by <a href="http://www.ou.edu/ouit.html">OU Information Technology</a>: <a href="mailto:"></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OuFooter'
}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.footer {
    font-family: 'Roboto', Geneva, Tahoma, Verdana, sans-serif;
    line-height: 1.65em;
    font-size: 80%;
    font-weight: normal;
    background-image:url(https://www.ou.edu/content/dam/common/design2013/bg_black.gif);
    background-repeat:repeat;
    color:#ffffff;
    padding: auto;
    background-color:#333;
}

.container-fluid {
    padding-right: 20px;
    padding-left: 20px;
}

.footer a {
    outline: none;
    text-decoration: none;
}

.footer ul.unstyled {
    list-style: none;
    padding: 0;
    margin: 0 0 10px 0;
}

.footer .row-fluid { margin:2em auto; }

.footer a:link,
.footer a:visited {
    color:#FFF;
}
.footer a:hover{
    text-decoration:underline;
}
.footer .gc-cs-link {
    color:#999999 !important;
    text-decoration:none !important;
}
.footer .footerBottom { border-top:1px solid #474747; padding-top:10px; }

.footer .footerlogo img {
    width: 45px;
    height: 62px;
}

/* social icons */

.footer #footer_social_icons {
    overflow:auto;
    padding:0;
    margin:0;
}
.footer #footer_social_icons .text { overflow:auto; }
.footer #footer_social_icons .text p { padding:0; margin:0; }
.footer #footer_social_icons h1,
.footer #footer_social_icons h2,
.footer #footer_social_icons h3 {
    color: #ffffff;
    font-size: 100%;
    padding: 0;
    margin: 0 0 5px;
    line-height: normal;
    text-transform: none;
    width:186px;  /* accomodates 3 icons and the more arrow icon */
}
.footer #footer_social_icons ul {
    padding: 0;
    margin: 0;
    overflow: auto;
    width: 186px;  /* accomodates 3 icons and the more arrow icon */
    list-style-type: none;
}
.footer #footer_social_icons ul li {
    float:left;
    display:block;
}
.footer #footer_social_icons ul li a {
    display:block;
    width: 46px;
    height: 46px;
    margin: 0;
    padding: 0;
    text-indent: -9999px;
    background-image: url(https://www.ou.edu/content/dam/common/design2013/footer_social_icons.png);
}
.footer #footer_social_icons ul a.facebook { background-position: 0 0; }
.footer #footer_social_icons ul a.facebook:hover { background-position: -46px 0; }

.footer #footer_social_icons ul a.twitter { background-position: 0 -46px; }
.footer #footer_social_icons ul a.twitter:hover { background-position: -46px -46px; }

.footer #footer_social_icons ul a.youtube { background-position: 0 -92px; }
.footer #footer_social_icons ul a.youtube:hover { background-position: -46px -92px; }

.footer #footer_social_icons ul a.instagram { background-position: 0 -138px; }
.footer #footer_social_icons ul a.instagram:hover { background-position: -46px -138px; }

.footer #footer_social_icons ul a.linkedin { background-position: 0 -184px; }
.footer #footer_social_icons ul a.linkedin:hover { background-position: -46px -184px; }

.footer #footer_social_icons ul a.googleplus { background-position: 0 -230px; }
.footer #footer_social_icons ul a.googleplus:hover { background-position: -46px -230px; }

.footer #footer_social_icons ul a.pinterest { background-position: 0 -276px; }
.footer #footer_social_icons ul a.pinterest:hover { background-position: -46px -276px; }

.footer #footer_social_icons ul a.foursquare { background-position: 0 -322px; }
.footer #footer_social_icons ul a.foursquare:hover { background-position: -46px -322px; }

.footer #footer_social_icons ul a.more { background-position: 0 -368px; }
.footer #footer_social_icons ul a.more:hover { background-position: -46px -368px; }

.footer #footer_social_icons .extra-wide ul,
.footer #footer_social_icons .extra-wide h1,
.footer #footer_social_icons .extra-wide h2,
.footer #footer_social_icons .extra-wide h3 {
    width:278px; /* accomodates 5 icons and the more arrow icon */
}

.footer #footer_social_icons .wide ul,
.footer #footer_social_icons .wide h1,
.footer #footer_social_icons .wide h2,
.footer #footer_social_icons .wide h3 {
    width:232px; /* accomodates 4 icons and the more arrow icon */
}
.footer #footer_social_icons .narrow ul,
.footer #footer_social_icons .narrow h1,
.footer #footer_social_icons .narrow h2,
.footer #footer_social_icons .narrow h3 {
    width:140px; /* accomodates 2 icons and the more arrow icon */
}

@media (max-width: 768px) {
    .footer {
        padding: 2rem 0;
    }

    .footer .container-fluid {
        padding: 0 0.75em;
    }

    .footer .row-fluid {
        width: 100%;
        margin: 0 auto;
    }

    .footer .footerColumn {
        margin: 0 0 2em 0;
        text-align: center;
    }

    .footer .first.column,
    .footer .last.column {
        float: none !important;
        width: auto !important;
    }

    .footer .row-fluid [class*="span"] {
        display: inherit;
        float: none;
        width: auto;
        margin-left: auto;
    }

    .footer .footerlogo,
    .footer .footeraddress {
        display: block;
        padding: 0;
        width: 100%;
        margin: 0;
    }

    .footer .footerlogo {
        margin-bottom: 2em;
    }

    .footer #footer_social_icons {
        margin-bottom: 2em;
    }

    .footer #footer_social_icons ul {
        margin: 0 auto;
    }

    .footer .footerBottom {
        text-align: center;
    }
}

@media (min-width: 769px) {
    .footer .footerlogo {
        float: left;
    }

    .footer .footeraddress {
        float: left;
        margin-left: 10px;
    }

    .container-fluid {
        width:940px;
        padding: auto 20px;
        margin: 0 auto;
        *zoom: 1;
    }

    .container-fluid:before,
    .container-fluid:after {
        display: table;
        line-height: 0;
        content: "";
    }

    .container-fluid:after {
        clear: both;
    }

    .row-fluid {
        width: 100%;
        *zoom: 1;
    }

    .row-fluid:before,
    .row-fluid:after {
        display: table;
        line-height: 0;
        content: "";
    }

    .row-fluid:after {
        clear: both;
    }

    .row-fluid [class*="span"] {
        display: block;
        float: left;
        width: 100%;
        min-height: 30px;
        margin-left: 2.127659574468085%;
        *margin-left: 2.074468085106383%;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

    .row-fluid [class*="span"]:first-child {
        margin-left: 0;
    }

    .row-fluid .controls-row [class*="span"] + [class*="span"] {
        margin-left: 2.127659574468085%;
    }

    .row-fluid .span12 {
        width: 100%;
        *width: 99.94680851063829%;
    }

    .row-fluid .span4 {
        width: 31.914893617021278%;
        *width: 31.861702127659576%;
    }

    .row {
        margin-left: -30px;
        *zoom: 1;
    }

    .row:before,
    .row:after {
        display: table;
        line-height: 0;
        content: "";
    }

    .row:after {
        clear: both;
    }

    [class*="span"] {
        float: left;
        min-height: 1px;
        margin-left: 30px;
    }

    .container,
    .navbar-static-top .container,
    .navbar-fixed-top .container,
    .navbar-fixed-bottom .container {
        width: 1170px;
    }

    .span12 {
        width: 1170px;
    }

    .span4 {
        width: 370px;
    }

    .row-fluid {
        width: 100%;
        *zoom: 1;
    }

    .row-fluid:before,
    .row-fluid:after {
        display: table;
        line-height: 0;
        content: "";
    }

    .row-fluid:after {
        clear: both;
    }

    .row-fluid [class*="span"] {
        display: block;
        float: left;
        width: 100%;
        min-height: 30px;
        margin-left: 2.564102564102564%;
        *margin-left: 2.5109110747408616%;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

    .row-fluid [class*="span"]:first-child {
        margin-left: 0;
    }

    .row-fluid .controls-row [class*="span"] + [class*="span"] {
        margin-left: 2.564102564102564%;
    }

    .row-fluid .span12 {
        width: 100%;
        *width: 99.94680851063829%;
    }

    .row-fluid .span4 {
        width: 31.623931623931625%;
        *width: 31.570740134569924%;
    }
}
</style>
