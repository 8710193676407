<template>
    <div class="citrix-footer">
        <a href="https://www.citrix.com/about/legal/privacy/" target="_blank">
            Citrix Privacy Policy
        </a>
    </div>
</template>

<script>
export default {
    name: 'CitrixFooter'
}
</script>

<style lang="scss" scoped>
.citrix-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $backgroundColor;
    height: 55px;
    text-align: center;
    font-size: 0.9375rem;
    line-height: 3.4375rem;
    color: $textColor;

    a {
        color: $textColor;
        text-decoration: none;
        font-weight: bold;

        &:hover {
            text-decoration: underline;
        }
    }
}
</style>
