import api from '@/api-client'
import EventBus from '@/event-bus';
import i18n from '@/i18n'

const changeFavicon = (url) => {
    let link = window.document.querySelector('link[rel*="icon"]') || window.document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = url;
    window.document.getElementsByTagName('head')[0].appendChild(link);
}

export default {
    async fetchAll({ dispatch, rootGetters }) {
        let historyPromises = []
        const viewTypeFromConfig = rootGetters.config.view_type.toString()
        const viewTypeFromCookie = $cookies.get('view-type')

        let type = viewTypeFromConfig
        if (viewTypeFromCookie && type !== '2') {
            type = viewTypeFromCookie
        }

        try {
            await dispatch('fetchConfig')

            if (type === '1') {
                historyPromises = [ dispatch('calendar/fetch') ]
            } else if (type === '0') {
                historyPromises = [ dispatch('history/fetch') ]
            }

            if (rootGetters.config.uptime_chart) {
                historyPromises = [...historyPromises, dispatch('uptime/fetchGroupsWithServices')]
            }

            await Promise.all([
                dispatch('statuses/fetch'),
                dispatch('incidents/fetchCurrent'),
                dispatch('maintenances/fetchPlannedMaintenances'),
                dispatch('maintenances/fetchCurrent'),
                ...historyPromises
            ]);
        } catch (e) {
            throw e
        }
    },

    async refresh({ commit, dispatch }, fn) {
        try {
            commit('setRefreshing', true)
            fn ?
                await fn() :
                await dispatch('fetchAll')
            EventBus.$emit('data-refresh')
            commit('setRefreshing', false)
            dispatch('messages/push', i18n.global.t('communicates.data-refreshed'))
        } catch (e) {
            commit('setRefreshing', false)
            if (e?.response?.status === 401
                && e?.config?.url.indexOf('/config' !== -1)) {
                    // FIXME: use generic i18n key
                    dispatch('messages/push',
                        i18n.global.t('subscriptions.errors.undefined'))
                }
            throw e
        }
    },

    async fetchConfig({ commit }) {
        const { data: config } = await api.get('config');
        localStorage.removeItem('base-url')

        if (config.base_url) {
            localStorage.setItem('base-url', config.base_url)
        }

        if (config.favicon_url) {
            changeFavicon(config.favicon_url)
        }

        commit('setConfig', config);
        commit('setConfigLoaded')
        return config
    },
    async fetchHubsSwitcher({ commit }) {
        const { data: list } = await api.get('hubs_switcher');
        commit('setHubsSwitcher', list);
        return list
    },
    setAuthFail({ commit, dispatch }, authFailed = true) {
        commit('setAuthFail', authFailed)
        // FIXME: use generic i18n key
        dispatch('messages/push',
                 i18n.global.t('subscriptions.errors.undefined'))
    },
    setTimeZone({ commit }, tz) {
        commit('setTimeZone', tz)
    },

    set404({ commit }, is404 = true) {
        commit('set404', is404)
    },

    setOffline({ commit }, offline) {
        commit('setOffline', offline)
    }
};
