<template>
    <v-select
        class="select"
        :class="{'select--options-wrapped': wrapOptions}"
        :clearable=false
        :disabled="disabled"
        :label="labelKey"
        :multiple=false
        :options="options"
        :placeholder="placeholder"
        :modelValue="modelValue"
        @update:modelValue="onInput"
        @search:blur="onClose"
    >
        <template #list-header>
            <slot name="list-header"></slot>
        </template>
        <template
            v-if="displayKey"
            #selected-option="props"
        >
            <slot name="selected-option" :option="props">
                {{ props[displayKey] }}
            </slot>
        </template>
        <template #option="option">
            <slot name="custom-option" :option="option"></slot>
        </template>
        <template #open-indicator="{ attributes }">
            <span class="select--open-indicator"></span>
        </template>
        <template #no-options="{ search, searching, loading }">
            &nbsp;
        </template>
    </v-select>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        props: {
            disabled: {
                type: Boolean,
                default: false
            },
            labelKey: {
                type: String,
                default: ''
            },
            displayKey: {
                type: String,
                default: ''
            },
            options: {
                type: Array,
                required: true,
                default: () => []
            },
            placeholder: {
                type: String
            },
            modelValue: {},
            wrapOptions: {
                type: Boolean,
                default: true
            }
        },
        computed: {
            ...mapGetters(['config'])
        },
        methods: {
            onInput(value) {
                this.$emit('update:modelValue', value);
            },
            onClose() {
                this.$emit('close')
            }
        }
    }
</script>

<style lang="scss" scoped>
    .select {
        background-color: #FFF;
        color: $textColor;
        display: flex;
        text-transform: uppercase;

        &--open-indicator:after {
            border-left: 7px solid transparent;
            border-top: 7px solid #e7e7e7;
            border-right: 7px solid transparent;
            content: "";
            display: inline-block;
            right: 6px;
            top: calc(50% - 3px);
            position: absolute;
        }

        ::v-deep(input) {
            color: $textColor;
            font-size: 0.8125rem;
            font-weight: 400;
            font-family: Avenir, Open Sans,ITC Franklin Gothic,Helvetica Neue,helvetica,sans-serif;
            text-transform: uppercase;
        }

        &.focused {
            outline: 3px solid transparent;
        }
    }
</style>
