<template>
    <div
        class="service"
        :class="[themeClass, { 'service--few-cols': hasFewCols }]"
    >
        <div class="service__info">
            <h4 class="name">{{ name }}</h4>
        </div>
        <div class="service__cells">
            <div
                class="cell"
                v-for="(service, index) in hubsWithStatuses"
                :key="service.service_id"
                :style="{ width: columnWidth }"
            >
                <StatusDot class="status" :status="service.status" />
                <Maintenances
                    v-if="service.maintenance_ids && service.maintenance_ids.length"
                    :ids="service.maintenance_ids"
                />
                <Incidents
                    v-if="service.incident_ids && service.incident_ids.length"
                    :ids="service.incident_ids"
                />
            </div>
        </div>
    </div>
</template>
<script>
import Incidents from '../history/list/group/popovers/Incidents.vue'
import Maintenances from '../history/list/group/popovers/Maintenances.vue'
import StatusDot from '@/components/StatusDot.vue'
import { mapGetters } from 'vuex';
import theme from '@/mixins/theme'

export default {
    components: {
        Incidents,
        Maintenances,
        StatusDot
    },
    mixins: [theme],
    props: {
        columnWidth: {
            type: String,
            required: true
        },
        name: {
            type: String
        },
        services: {
            type: Array,
            required: true
        },
        hasFewCols: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapGetters({
            connectedHubServices: 'connectedHubs/services'
        }),
        hubsWithStatuses() {
            let statuses = []
            this.connectedHubServices.forEach((hub, idx) => {
                this.services.forEach((service) => {
                    if (hub.service_ids.includes(service.service_id)) {
                        if (!statuses[idx]) {
                            statuses.push(service)
                        }
                    }
                })
                if (!statuses[idx]) {
                    statuses.push({ status: 0 })
                }
            })
            return statuses
        }
    }
}
</script>
<style lang="scss" scoped>
.service {
    display: flex;
    flex-direction: column;
    padding-left: 8px;
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid #F9FAFC;

    @include breakpoint($md) {
        flex-direction: row;
        padding-left: 16px;
        padding-top: 16px;
        padding-bottom: 16px;
    }

    &__info {
        display: flex;

        @include breakpoint($md) {
            width: 250px;
        }
    }

    &__cells {
        display: flex;
        width: 100%;
        padding-top: 8px;
        padding-bottom: 8px;

        @include breakpoint($md) {
            width: calc(100% - 250px);
        }

        .cell {
            align-items: center;
            display: flex;
            justify-content: flex-start;
            flex-direction: column;

            @include breakpoint($md) {
                flex-direction: row;
                justify-content: center;
            }

            .status {
                margin-bottom: 3px;

                @include breakpoint($lg) {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.theme--compact {
    &.service {
        border-bottom: 1px solid #EFF2F9;

        @include breakpoint($md) {
            min-height: 38px;
            padding-left: 1.5rem;
            padding-top: 0.125rem;
            padding-bottom: 0.125rem;
        }
    }

    .service {
        &__info {
            align-items: center;

            .name {
                color: $textColor2;
                font-size: 0.875rem;
                font-weight: 500;
                margin: 0;
                line-break: anywhere;
            }

            .icon {
                margin-left: 0.5rem;
            }
        }

        .cell {
            .status {
                transform: scale(0.7);
            }

            ::v-deep(.icon) {
                transform: scale(0.7);
            }
        }

        &--few-cols {
            .service {
                &__info {
                    width: 100%;
                }

                &__cells .cell {
                    @include breakpoint($md) {
                        min-width: 100px;
                        max-width: 100px;
                    }
                }
            }
        }
    }
}
</style>
