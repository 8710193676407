<template>
    <div class="footer" :class="{ default: !customFooter && !isWhiteLabel }">
        <template v-if="customFooter">
            <CustomFooter />
        </template>
        <template v-else-if="isWhiteLabel"></template>
        <template v-else>{{ $t('components.footer.powered-by') }}
            <a class="footer__link" :href="`https://statushub.com/?utm_campaign=${url}&utm_content=status-pages&utm_medium=powered-by&utm_source=inapp`" target="_blank">
                {{ $t('components.footer.statushub') }}
            </a>
        </template>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import CustomFooter from './CustomFooter.vue'

export default {
    components: {
        CustomFooter
    },
    computed: {
        ...mapGetters(['config']),
        customFooter() {
            return this.config?.footer_name
        },
        isWhiteLabel() {
            return this.config?.whitelabel
        },
        url() {
            return window.location.host
        }
    }
}
</script>
<style lang="scss" scoped>
.footer.default {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $backgroundColor;
    height: 3.4375rem;
    text-align: center;
    font-size: 0.9375rem;
    line-height: 3.4375rem;
    color: $textColor;

    a {
        color: $textColor;
        text-decoration: none;
        font-weight: bold;
    }
}
</style>
