<template>
    <div class="maintenance-list">
        <EventShort
            :event="maintenance"
            event-type="maintenance"
            :no-icon="true"
            v-for="(maintenance, index) in maintenancesPage"
            :key="maintenance.id"
        >
        </EventShort>
        <pagination
            v-if="paginated"
            v-model="page"
            :per-page="perPage"
            :records="maintenances.length"
            :options="paginationOptions"
        />
    </div>
</template>
<script>
import EventShort from '@/views/hub/components/events/EventShort.vue'
import Pagination from 'v-pagination-3'
export default {
    components: {
        EventShort,
        Pagination
    },
    props: {
        maintenances: {
            type: Array,
            required: true
        },
        paginated: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            page: 1,
            perPage: 3
        }
    },
    computed: {
        maintenancesPage() {
            if (!this.paginated) { return this.maintenances }

            return this.maintenances.slice(
                (this.page - 1) * this.perPage,
                this.page * this.perPage
            )
        },
        paginationOptions() {
            return {
                chunk: 6,
                chunksNavigation: 'scroll'
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.maintenance-list {
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    padding: 32px 24px;
    background-color: #FFFFFF;
}
</style>
