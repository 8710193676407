<template>
    <div class="communicates">
        <transition-group name="toasts">
            <Toast
                v-for="message in messages"
                :message="message.body"
                :key="message.id"
                @close="acknowledge(message.id)"
            />
        </transition-group>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Toast from './Toast.vue'

export default {
    components: {
        Toast
    },
    computed: {
        ...mapGetters({
            messages: 'messages/messages'
        })
    },
    methods: {
        ...mapActions({
            remove: 'messages/remove'
        }),
        acknowledge(id) {
            this.remove(id)
        }
    }
}
</script>

<style lang="scss" scoped>
.communicates {
    z-index: 9;
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;

    @include breakpoint($lg) {
        width: 400px;
        bottom: 32px;
        right: 32px;
    }

    .toasts-enter-active, .toasts-leave-active {
        transition: all 0.5s;
    }
    .toasts-enter, .toasts-leave-to {
        opacity: 0;
        transform: translateX(100px);
    }
}
</style>
