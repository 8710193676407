import { createStore } from 'vuex'

import connectedHubs from './connectedHubs'
import history from './history';
import incidents from './incidents';
import maintenances from './maintenances';
import messages from './messages';
import statuses from './statuses';
import subscriptions from './subscriptions';
import calendar from './calendar'
import uptime from './uptime'

import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
    config: {
        'id': '',
        'all_statuses_default': false,
        'base_url': '',
        'big_circles_mode': 0,
        'custom_config': {
        },
        'description': '',
        'favicon_url': '',
        'google_analytics': {
           'custom_gaid_present': false,
           'custom_metrics': [],
           'internal_gaid': 'UA-00000000-0'
        },
        'logo_url': '',
        'name': '',
        'name_for_statuses': {
           '1': '',
           '2': '',
           '3': ''
        },
        'navbar_link': {
           'name': '',
           'url': ''
        },
        'no_tos_checkbox': false,
        'planned_maintenance_list_pagination': false,
        'platform_alert': {
           'enabled': false,
           'title': '',
           'message': '',
           'type_name': 'alert'
        },
        'pusher': {
           'cluster': 'mt1',
           'app_key': 'c677f349e33cd1845c47'
        },
        'show_group_names': false,
        'small_circles_mode': 0,
        'subdomain': '',
        'subscriptions': {
           'label': false,
           'methods': {},
           'region_selection': false
        },
        'theme': {
           'background_image': ''
        },
        'languages': {
           'available': []
        },
        'locale': {
          'is_iso_week': false
        },
        'time_zone_name': 'Etc/UTC',
        'updated_at': '',
        'uptime_chart': false,
        'view_type': 0,
        'whitelabel': false
    },
    authFailed: false,
    configLoaded: false,
    hubsSwitcher: [],
    offline: !navigator.onLine,
    pageNotFound: false,
    refreshing: false,
    timeZone: {
        abbr: 'UTC',
        text: '(UTC/GMT+00:00) UTC',
        value: 'Etc/UTC'
    }
};

export default createStore({
    modules: {
        calendar,
        connectedHubs,
        history,
        incidents,
        maintenances,
        messages,
        statuses,
        subscriptions,
        uptime
    },
    state,
    getters,
    actions,
    mutations,
    namespaced: true
})
