<template>
    <div class="incident">
        <div class="incident__header">
            <Icon class="icon" :name="iconName"></Icon>
            <router-link class="title" :to="`/incidents/${incident.id}`">
                <span class="title__incident-name">{{ title }}</span>
            </router-link>
        </div>
        <Update v-if="firstUpdate" :update="firstUpdate" class="incident__first-update" />
        <template v-if="historyVisible">
            <Update
                v-for="update in history"
                :key="update.id"
                :update="update"
                class="incident__update"
            />
        </template>
        <a
            href="#"
            class="incident__history-trigger"
            @click="toggleHistory"
            v-on:click.prevent
            v-if="history.length"
        >
            {{ $t('summary.notifications.incident.show-hide-history') }}
            <Icon v-if="!historyVisible" class="icon" name="chevron-down" />
            <Icon v-else class="icon" name="chevron-up" />
        </a>
    </div>
</template>
<script>
import Icon from '@/components/Icon.vue'
import incidentMixin from '@/mixins/incident.js'
import Update from './incident/Update.vue'
import { mapGetters } from 'vuex';

export default {
    name: 'Incident',
    components: {
        Icon,
        Update
    },
    mixins: [incidentMixin],
    data() {
        return {
            historyVisible: false
        }
    },
    computed: {
        ...mapGetters({
            byID: 'incidents/byId'
        }),
        iconName() {
            return this.incidentIconName(this.incident)
        },
        title() {
            return this.incidentTitle(this.incident)
        },
        firstUpdate() {
            return this.incident?.updates?.[0];
        },
        history() {
            return (this.incident?.updates || []).length > 1 && this.incident.updates.slice(1);
        }
    },
    props: {
        incident: {
            type: Object,
            required: true
        }
    },
    methods: {
        toggleHistory() {
            this.historyVisible = !this.historyVisible;
        }
    }
}
</script>
<style lang="scss" scoped>
.incident {
    border-radius: 6px;
    background: $backgroundColor;
    transition: height 0.3s;
    margin-bottom: 32px;
    overflow: hidden;

    &__header {
        padding: 16px 16px;
        display: flex;

        .icon {
            padding: 0 8px;
        }
        .title {
            font-size: 0.875rem;
            align-self: center;
            text-decoration: none;

            &__incident-name {
                color: #0077AA;
                margin-left: 4px;
                text-decoration: underline;
                font-weight: 600;
                letter-spacing: -0.29px;
                line-height: 1.1875rem;
            }
        }
    }

    &__update {
        transform-origin: top;
        height: 100%;
    }

    &__history-trigger {
        display: flex;
        justify-content: center;
        font-weight: 400;
        font-size: 0.8125rem;
        margin: 2px;
        transition: background-color 0.3s;
        cursor: pointer;
        padding-top: 14px;
        padding-bottom: 14px;
        color: $textColor;

        .icon {
            align-self: center;
            margin-left: 8px;
        }

        &:focus {
        }
    }
}

.slide-enter-active {
   transition-duration: 0.3s;
   transition-timing-function: linear;
}

.slide-leave-active {
   transition-duration: 0.3s;
   transition-timing-function: ease-out;
}

.slide-enter-to, .slide-leave {
   max-height: 100px;
   overflow: hidden;
}

.slide-enter, .slide-leave-to {
   overflow: hidden;
   max-height: 0;
   opacity: 0;
}
</style>
