<template>
    <div class="no-events">
        <span class="no-events__text">
            {{ text }}
        </span>
    </div>
</template>
<script>
export default {
    name: 'NoEventsBox',
    props: ['text']
}
</script>
<style lang="scss" scoped>

.no-events {
    border-radius: 6px;
    background-color: #F4F7FD;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;

    &__text {
        font-size: 1.0625rem;
        font-weight: 600;
        line-height: 1.4375rem;
        color: $textColor
    }
}
</style>
