import { uuidv7 } from 'uuidv7'

export default {
    push({ commit }, body) {
        const message = {
            id: uuidv7(),
            body
        }
        commit('push', message)
    },
    remove({ commit }, id) {
        commit('remove', id)
    }
}

