export default {
    setEvents(state, events) {
        events.forEach(event => {
            state.events[event.entry_id] = {
            ...event, start: event.start * 1000, end: (event.end * 1000) || new Date().getTime()
        }})
    },
    setCurrent(state, events) {
        state.current = events.map(event => event.entry_id)
    },
    setDays(state, days) {
        state.days = days
    },
    setDate(state, date) {
        state.date = date
    }
}
