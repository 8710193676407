<template>
    <label class="container">
        <div class="label">
            <slot name="label">
                <span v-if="label" class="default">{{ label }}</span>
            </slot>
        </div>
        <div class="switch">
            <input type="checkbox"
                   :checked="modelValue"
                   :value="modelValue"
                   @change="change"
            >
            <span class="slider" :class="{ round: rounded }"></span>
        </div>
    </label>
</template>
<script>
export default {
    props: {
        label: {
            type: String,
            default: ''
        },
        rounded: {
            type: Boolean,
            default: true
        },
        modelValue: {
            type: Boolean,
            required: true
        }
    },
    methods: {
        change() {
            this.$emit('update:modelValue', !this.modelValue);
        }
    }
}
</script>
<style lang="scss" scoped>
.container {
    display: flex;
    align-items: center;
    min-height: 40px;
    border-radius: 20px;
    box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.09);
    color: $textColor;
    padding-left: 14px;
    background-color: #fff;

    .label {
        display: flex;
        align-items: flex-start;
        padding-right: 68px;

        .default {
            line-height: 2.25rem;
            white-space: nowrap;
            font-size: 0.875rem;
            padding-right: 16px;
        }
    }

    .switch {
        transform: scale(0.85);
        height: 36px;
        position: relative;
        right: 60px;
        display: inline-block;

        input {
            opacity: 0;
            width: 0;
            height: 0;

            &:checked + .slider {
                background-color: $blue;
            }

            &:focus + .slider {
                outline: 3px solid $focusColor;
            }

            &:checked + .slider:before {
                -webkit-transform: translateX(24px);
                -ms-transform: translateX(24px);
                transform: translateX(24px);
            }

            &:focus + span {
                outline: 3px solid $focusColor;
            }
        }

        .slider {
            width: 60px;
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #ccc;
            -webkit-transition: .4s;
            transition: .4s;

            &:before {
                position: absolute;
                content: "";
                height: 28px;
                width: 28px;
                left: 4px;
                bottom: 4px;
                background-color: white;
                -webkit-transition: .4s;
                transition: .4s;
            }

            &:after {
                position: absolute;
                left: 11px;
                top: 8px;
                display: inline-block;
                content: '';
                transform: rotate(45deg);
                height: 12px;
                width: 6px;
                border-bottom: 3px solid #fff;
                border-right: 3px solid #fff;
            }

            &.round {
                border-radius: 34px;

                &:before {
                    border-radius: 50%;
                }
            }
        }
    }
}
</style>
