import VueGtag from 'vue-gtag'

const addCustomAnalyticsScript = (src, attributes) => {
    const script = document.createElement('script')
    script.setAttribute('src', src)
    Object.keys(attributes).forEach((attr) => {
        script.setAttribute(attr, attributes[attr])
    })
    document.head.appendChild(script)
}

const addFathomScript = (ids) => ids.forEach((id) => {
    addCustomAnalyticsScript('https://cdn.usefathom.com/script.js', {
        'data-spa': 'auto',
        'data-site': id,
        defer: 'defer'
    })
})

const addAdobeAnalyticsScript = (ids) => ids.forEach((id) => {
    addCustomAnalyticsScript(id, { async: 'async' })
})

const addGoogleAnalyticsV4Script = (ids, vue) => {
    vue.use(VueGtag, { config: { id: ids[0] } })

    window.dataLayer = window.dataLayer || []
    function gtag() { window.dataLayer.push(arguments) }
    gtag('js', new Date())

    for (let i = 1; i < ids.length; i++) {
        gtag('config', ids[i]);
    }
}

const HANDLER = {
    adobe: addAdobeAnalyticsScript,
    fathom: addFathomScript,
    ga4: addGoogleAnalyticsV4Script
}

export default function (vue, analytics = []) {
    try {
        const analyticsObj = {}
        analytics.forEach(({ type, id }) => analyticsObj[type]
            ? analyticsObj[type].ids.push(id)
            : analyticsObj[type] = { ids: [id] })

        Object.keys(analyticsObj)
            .forEach((type) => HANDLER[type](analyticsObj[type].ids, vue))
    } catch (e) {
        console.error(e)
    }
}
