export default {
    current(state) {
        return state.currentIncidentIds.map(id => state.incidents[id]);
    },
    currentIDs(state) {
        return state.currentIncidentIds
    },
    byId(state) {
        return (id) => state.incidents[id] || {};
    }
}
