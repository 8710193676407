<template>
    <tr class="service" :class="themeClass">
        <th class="service__info" scope="row">
            <h4 class="name">{{ serviceName(service) }}</h4>
            <Icon v-tooltip="{
                    content: service.service_description,
                    theme: 'info-tooltip'
                }"
                class="icon"
                name="info-circle"
                v-if="service.service_description"
            />
        </th>
        <td
            class="cell"
            v-for="(day, dayIndex) in toFullWeek(service.days)"
            :key="dayIndex"
        >
            <div class="cell__content-wrapper">
                <StatusDot
                    class="status"
                    role="status"
                    :status="day.status"
                    :aria-label="statusAriaLabel(day.status, serviceName(service), dayIndex)"
                />
                <Maintenances
                    v-if="day.maintenance_ids && day.maintenance_ids.length"
                    :ids="day.maintenance_ids"
                    :aria-label="maintenanceAriaLabel(serviceName(service), dayIndex)"
                />
                <Incidents
                    v-if="day.incident_ids && day.incident_ids.length"
                    :ids="day.incident_ids"
                    :aria-label="incidentAriaLabel(serviceName(service), dayIndex)"
                />
            </div>
        </td>
    </tr>
</template>

<script>
import Icon from '@/components/Icon.vue'
import Incidents from './popovers/Incidents.vue'
import Maintenances from './popovers/Maintenances.vue'
import StatusDot from '@/components/StatusDot.vue';
import connectedHubsGrid from '@/mixins/connectedHubsGrid';
import theme from '@/mixins/theme';

export default {
    components: {
        Icon,
        Incidents,
        Maintenances,
        StatusDot
    },
    mixins: [connectedHubsGrid, theme],
    props: {
        service: {
            type: Object,
            required: true
        },
        labels: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        days() {
            return Array.from(Array(7), (_, index) => index)
        }
    },
    methods: {
        statusAriaLabel(status, name, index) {
            return status > 1 ?
                this.$t('history.list.group.services.aria-status-issue', { name, day: this.labels[index] }) :
                this.$t('history.list.group.services.aria-status-up', { name, day: this.labels[index] })
        },
        incidentAriaLabel(name, index) {
            return this.$t('history.list.group.services.aria-incident', { name, day: this.labels[index] })
        },
        maintenanceAriaLabel(name, index) {
            return this.$t('history.list.group.services.aria-maintenance', { name, day: this.labels[index] })
        },
        toFullWeek(statuses) {
            return this.days.map((day, index) => {
                const status = statuses.find(status => status.position === index);

                return status || { status: 1 }
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.service {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    padding-left: 8px;
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid #F9FAFC;

    @include breakpoint($md) {
        grid-template-columns: 250px repeat(7, 1fr);
        padding-left: 16px;
        padding-top: 16px;
        padding-bottom: 16px;
    }

    &__info {
        display: flex;
        text-align: left;
        grid-column-start: 1;
        grid-column-end: 8;

        @include breakpoint($md) {
            grid-column-end: 2;
            width: 250px;
        }

        .name {
            color: $textColor2;
            font-size: 0.875rem;
            font-weight: 500;
            margin-block-start: 1em;
            margin-block-end: 1em;
            word-break: break-all;
        }

        .icon {
            min-width: 1rem;
        }

        .description {
            padding-right: 8px;
            padding-left: 4px;

            .icon {
                cursor: pointer;
                padding-top: 4px;
                fill: rgba(0,0,0,.7);
                transition: fill 0.3s;

                &:hover {
                    fill: rgba(0,0,0,1);
                }
            }
        }
    }

    .cell {
        &__content-wrapper {
            height: 100%;
            align-items: center;
            display: flex;
            justify-content: flex-start;
            flex-direction: column;

            @include breakpoint($md) {
                flex-direction: row;
                justify-content: center;
            }

            .status {
                margin-bottom: 3px;

                @include breakpoint($lg) {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.theme--compact {
    &.service {
        border-bottom: 1px solid #EFF2F9;

        @include breakpoint($md) {
            min-height: 38px;
            padding-left: 1.5rem;
            padding-top: 0.125rem;
            padding-bottom: 0.125rem;
        }
    }

    .service {
        &__info {
            align-items: center;

            .name {
                color: $textColor2;
                font-size: 0.875rem;
                font-weight: 500;
                margin: 0;
            }

            .icon {
                margin-left: 0.5rem;
            }
        }

        .cell {
            .status {
                transform: scale(0.7);
            }

            ::v-deep(.icon) {
                transform: scale(0.7);
            }
        }
    }
}
</style>
