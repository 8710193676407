<template>
    <Select class="timezone"
            :options="options"
            :disabled="offline"
            @update:modelValue="onTimezoneChange"
            label-key="text"
            display-key="abbr"
            v-model="selected"
            :placeholder="$t('summary.timezone.dropdown-placeholder')"
    />
</template>

<script>
import Select from '@/components/Select.vue'
import timeOperations from '@/mixins/timeOperations'
import timezones from '@/timezones.json'
import { mapActions, mapGetters } from 'vuex';
import { useMq } from 'vue3-mq'

export default {
    components: {
        Select
    },
    mixins: [
        timeOperations
    ],
    computed: {
        ...mapGetters({
                config: 'config',
                offline: 'offlineAttr'
        }),
        cookie() {
            return this.$cookies.get('timezone') || this.config?.time_zone_name;
        },
        isNarrowDisplay() {
            return this.mq.current === 'lg'
        },
        selected: {
            get() {
                const name = this.cookie;
                return this.timezone || this.timeZoneByName(name);
            },
            set(timezone) {
                this.$cookies.set('timezone', timezone.value);
                this.timezone = timezone;
            }
        }
    },
    data() {
        return {
            mq: useMq(),
            timezone: '',
            options: timezones
        }
    },
    methods: {
        ...mapActions({
            refresh: 'refresh',
            pushMessage: 'messages/push',
            setTimeZone: 'setTimeZone'
        }),
        async onTimezoneChange(event) {
            await this.refresh();
            this.setTimeZone(this.timezone)
            this.pushMessage(`${this.$t('summary.timezone.timezone-changed')}: <br /><strong>${this.timezone.text}</strong>`);
        }
    }
}
</script>
<style lang="scss" scoped>
    .timezone {
        width: 80px;
        height: 36px;

        @include breakpoint($lg) {
            ::v-deep([role="combobox"]) {
                width: 80px;
            }

            ::v-deep(ul[role="listbox"]) {
                width: 350px;
            }
        }

        &.vs--open ::v-deep(.vs__selected) {
            text-align: left;
        }
    }
</style>
