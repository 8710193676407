<template>
    <nav
        v-if="config"
        class="topbar"
    >
        <div class="topbar__bar" :style="{ backgroundColor, backgroundImage }"></div>
        <div class="topbar__menus">
            <a href="#"
               class="topbar__logo-link"
               @click="goToBrandUrl"
               v-on:click.prevent
            >
                <img
                    v-if="config.logo_url"
                    class="topbar__logo-image"
                    :alt="config.name"
                    :src="config.logo_url"
                />
                <span v-else class="topbar__logo-name">{{config.name}}</span>
            </a>
            <DesktopMenu
                class="topbar__desktop-menu"
                :signed-in="signedIn"
                :logout-url="logoutUrl"
                :can-subscribe="canSubscribe"
                :can-edit-subscription="canEditSubscription"
                :token="token"
            />
            <MobileMenu
                class="topbar__mobile-menu"
                :signed-in="signedIn"
                :logout-url="logoutUrl"
                :can-subscribe="canSubscribe"
                :can-edit-subscription="canEditSubscription"
                :token="token"
                @redirect="goToBrandUrl"
            />
        </div>
    </nav>
</template>
<script>
import { mapGetters } from 'vuex';
import MobileMenu from './Topbar/MobileMenu.vue'
import DesktopMenu from './Topbar/DesktopMenu.vue'

export default {
    components: {
        MobileMenu,
        DesktopMenu
    },
    data() {
        return {
            signedIn: false
        }
    },
    computed: {
        ...mapGetters({
            config: 'config',
            subscriber: 'subscriptions/subscriber'
        }),
        areSubscriptionsEnabled() {
            return (this.config.subscription_methods || []).length > 0
        },
        canSubscribe() {
            return this.areSubscriptionsEnabled &&
                !this.token &&
                !this.isOnSubscribersPath
        },
        canEditSubscription() {
            return this.areSubscriptionsEnabled &&
                !!this.token &&
                !this.isOnSubscribersPath
        },
        isOnSubscribersPath() {
            return this.$route.path.includes('subscriber')
        },
        logoutUrl() {
            return `/status_page_logout`;
        },
        token() {
            return this.subscriber?.token
        },
        backgroundColor() {
            return this.config.theme.background_color;
        },
        backgroundImage() {
            return `url(${this.config.theme.background_image}&fit=crop&h=4&crop=top)`;
        }
    },
    methods: {
        goToBrandUrl() {
            if (this.config.brand_link) {
                window.open(this.config.brand_link, '_blank')
            } else if (this.$router.currentRoute.value.path !== '/') {
                this.$router.push('/')
            }
        }
    },
    created() {
        // FIXME: (facepalm)
        setInterval(() => { this.signedIn = !!$cookies.get('jwt'); }, 500);
    }
}
</script>
<style lang="scss" scoped>
.topbar {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 4rem;
    min-height: 64px;
    border-bottom: 1px solid #979797;
    padding: 0 1rem;

    @include breakpoint($md) {
        padding: 0 2rem;
    }

    @include breakpoint($lg) {
        padding: 0 calc((100% - #{$screen-lg-min}) / 2 + 3rem);
    }

    a:hover, a:visited, a:link, a:active {
        text-decoration: none;
    }

    &__menus {
        display: flex;
        justify-content: space-between;
        flex-grow: 1;
        padding-top: 0.375rem;
    }

    &__bar {
        display: none;

        @include breakpoint($md) {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 0.375rem;
            background-size: cover;
        }
    }

    &__logo-link {
        overflow: hidden;
        white-space: nowrap;
        margin-right: 0.5rem;
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    &__logo-image {
        max-height: 3.125rem;
        max-width: 15.625rem;
    }

    &__logo-name {
        text-overflow: ellipsis;
        overflow: hidden;
        text-shadow: 0 1px 0 rgba(255,255,255,.25);
        font-weight: 300;
        font-size: 2.25rem;
        text-transform: uppercase;
        color: #000;
        text-decoration: none;
    }

    &__desktop-menu {
        display: none;

        @include breakpoint($lg) {
            display: flex;
        }
    }

    &__mobile-menu {
        @include breakpoint($lg) {
            display: none;
        }
    }
}
</style>
