<template>
    <div class="events events--ongoing">
        <EventShort
            :event="incident"
            event-type="incident"
            v-for="(incident, index) in incidents"
            :key="`i-${incident.id}`"
        >
        </EventShort>
        <EventShort
            :event="maintenance"
            event-type="maintenance"
            v-for="(maintenance, index) in maintenances"
            :key="`m-${maintenance.id}`">
        </EventShort>
    </div>
</template>
<script>

import EventShort from '@/views/hub/components/events/EventShort.vue'

export default {
    name: 'OngoingEvents',
    components: {
        EventShort
    },
    props: [
        'incidents',
        'maintenances'
    ]
}
</script>
<style lang="scss" scoped>
</style>
