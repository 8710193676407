<template>
    <div class="update">
        <div class="update__title">
            <Icon
                class="type"
                :name=incidentTypeIconName(this.update)
            />
            <span class="status">
                {{ $tm(i18nKey('incident-status'))[update.type] }},&nbsp;
            </span>
            <span class="date"> {{ formatTimestamp(update.timestamp) }}</span>
        </div>
        <div class="update__content">
            <TextExpander :text="message" />
        </div>
        <AffectedServices
            class="update__affected-services"
            v-if="update.services.length"
            :services="update.services"
        />
    </div>
</template>
<script>
import AffectedServices from '@/components/AffectedServices/AffectedServices.vue'
import customTranslations from '@/mixins/customTranslations'
import Icon from '@/components/Icon.vue'
import TextExpander from '@/components/TextExpander.vue'
import incident from '@/mixins/incident'
import timeOperations from '@/mixins/timeOperations'

export default {
    components: {
        AffectedServices,
        Icon,
        TextExpander
    },
    mixins: [
        customTranslations,
        incident,
        timeOperations
    ],
    props: {
        update: {
            type: Object,
            required: true
        }
    },
    computed: {
        type() {
            return this.update.type
        },
        message() {
            return this.update.message
        }
    }
}
</script>
<style lang="scss" scoped>
.update {
    overflow: hidden;

    &__title {
        display: flex;
        background-color: #fff;
        padding-top: 24px;
        padding-left: 24px;
        padding-right: 16px;
        font-size: 0.8125rem;
        font-weight: 600;
        color: $textColor;
        align-items: center;

        .type {
            padding-right: 8px;
            fill: $gray;
        }
    }

    &__content {
        display: flex;
        padding: 6px 24px;
        background-color: #fff;
    }
}
</style>
