<template>
    <div class="columns">
        <div
            class="column__element"
            v-for="childBlock in block.children"
        >
            <component
                :is=childBlock.component
                :block=childBlock
                v-bind="childBlock.config"
            >
            </component>
        </div>
    </div>
</template>

<script>
import layout from '@/mixins/layout'

export default {
    mixins: [layout],
    props: {
        block: {
            type: Object,
            required: true
        },
    }
}
</script>

<style lang="scss" scoped>
.columns {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 40px;
    row-gap: 16px;
    justify-content: space-between;

    @include breakpoint($lg) {
        column-gap: 92px;
    }

    .column__element {
        flex: 1 1 0;
    }
}
</style>
