import axios from 'axios';

const http = axios.create({
    baseURL: `//${window.location.host}/api`,
    withCredentials: true,
    headers: {
        'STATUSHUB-HUB-API-CLIENT': 'true',
        'HUB-HOST': window.location.host,
        'LOCALE': 'en-US'
    }
});

let refreshTokenPromise;

const redirectToLogin = () => {
    $cookies.remove('jwt')
    const url = localStorage.getItem('base-url') || window.location.origin
    window.location.replace(url + '/jwt/token');
}

const refreshToken = () => {
    if (refreshTokenPromise) { return refreshTokenPromise }
    refreshTokenPromise = refreshTokenAction();
    return refreshTokenPromise;
}

const refreshTokenAction = async () => {
    try {
        const { data } = await http.get('jwt/token');

        refreshTokenPromise = undefined;

        // hack
        if (data.token) {
            $cookies.set('jwt', data.token, { expires: '1h' })
        } else {
            throw new Error()
        }
    } catch (_) {
        redirectToLogin()
        throw new Error('authorization-required')
    }
}

http.interceptors.request.use(config => {
    const jwt = $cookies.get('jwt');
    const timezone = $cookies.get('timezone');

    const url = localStorage.getItem('base-url') || window.location.origin
    config.url = url + '/api/' + config.url

    if (timezone) {
        config.headers['TIMEZONE'] = timezone;
    }

    if (jwt) {
        config.headers['Authorization'] = `Bearer ${jwt}`;
    }

    return config;
}, (error) => {
    return Promise.reject(error);
});

http.interceptors.response.use(response => {
    (localStorage.getItem('auth-fail-count') && response.config.url.indexOf('/config') !== -1)
        && localStorage.removeItem('auth-fail-count')
    return response;
}, async (error) => {
    if (error.response && error.response.status === 401) {
        localStorage.setItem('base-url', error.response.data.base_url)

        let authFailCount = parseInt(localStorage.getItem('auth-fail-count')) || 0
        if (authFailCount < 5) {
            authFailCount++
            localStorage.setItem('auth-fail-count', authFailCount.toString())
        } else {
            localStorage.removeItem('auth-fail-count')
            // TODO: Convert to custom error
            return Promise.reject(new Error('unable-to-authorize'))
        }

        try {
            await refreshToken()
            return http.request({ ...error.config, url: error.config.url.split('/api/').pop() })
        } catch (e) {
            return Promise.reject(e);
        }
    }

    return Promise.reject(error);
});

export default http;
