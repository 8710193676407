import Pusher from 'pusher-js';

export default {
    install (app, { router, appKey, cluster, event, channel }) {
        const observables = []
        const pusher = new Pusher(appKey, {
            cluster
        });

        pusher.subscribe(event);
        pusher.channel(channel).bind('page_refresh', () => window.location.reload(true))
        pusher.channel(channel).bind('status_updated', (payload) => {
            const currentPath = router.currentRoute.value.path
            observables
                .filter(({ path }) => path === currentPath)
                .forEach(({ fns }) => {
                    fns.forEach(fn => fn(payload))
                })
        })

        app.config.globalProperties.$pusher = {
            onEvent(fns) {
                const path = router.currentRoute.value.path
                if (observables.find(observable => observable.path === path)) {
                    return;
                }

                observables.push({
                    fns: Array.isArray(fns) ? fns : [fns],
                    path
                })
            }
        }
    }
}
