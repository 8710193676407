<template>
    <div class="offline-alert">
        <Icon name="wifi" class="icon" />
        <span class="message">{{ $t('components.offline-alert.message') }}</span>
    </div>
</template>

<script>
import Icon from '@/components/Icon.vue'

export default {
    name: 'OfflineAlert',
    components: {
        Icon
    }
}
</script>

<style lang="scss" scoped>
.offline-alert {
    background-color: rgba(0,0,0,0.8);
    padding: 16px 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include breakpoint($lg) {
        padding: 16px calc((100% - #{$screen-lg-min}) / 2 + 48px);
    }

    .icon {
        color: #fff;
        margin-right: 16px;
        height: 24px;
        width: 24px;
    }

    .message {
        color: #fff;
    }
}
</style>
