<template>
    <div
        class="affected-service"
    >
        <StatusDot v-if="!noStatus" class="affected-service__status" :status="status" />
        <span class="affected-service__label">{{ name }}</span>
    </div>
</template>
<script>
import StatusDot from '@/components/StatusDot.vue';

export default {
    components: {
        StatusDot
    },
    props: {
        status: {
            type: Number,
            default: 1
        },
        name: {
            type: String,
            default: ''
        },
        noStatus: {
            type: Boolean,
            default: false
        }
    }
}
</script>
<style lang="scss" scoped>
.affected-service {
    display: flex;
    align-items: center;
    margin-right: 16px;
    line-height: 2rem;
    flex-shrink: 1;
    box-sizing: border-box;
    overflow: hidden;
    min-width: 70px;

    &__status {
        margin-right: 8px;
    }

    &__label {
        color: $textColor;
        font-size: 0.8125rem;
        font-weight: 350;
        overflow: hidden;
        text-transform: uppercase;
    }
}
</style>
