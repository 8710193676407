export default {
    methods: {
        incidentTypeIconName(update) {
            switch (update.type) {
                case 0:
                    return 'type-investigating'
                case 1:
                    return 'type-identified'
                case 2:
                    return 'type-monitoring'
                case 3:
                    return 'type-resolved'
                default:
                    return 'type-investigating'
            }
        },
        incidentIconName(incident) {
            if (incident?.flags?.unconfirmed) {
                return 'incident-unconfirmed'
            } else {
                return 'incident'
            }
        },
        incidentTitle(incident) {
            if (incident?.flags?.unconfirmed) {
                return `${this.$t('incidents.flags.unconfirmed-prefix')} ${incident.title}`
            } else {
                return incident?.title || ''
            }
        }
    }
}
