import api from '@/api-client';
import { dateAsISOTime } from '@/utils';
import EventBus from '@/event-bus';

export default {
    async fetchGroupsWithServices({ dispatch, commit, getters }) {
        const { data: groups } = await api.get(`groups`);

        commit('setGroups', groups)

        const promises = groups.map(group => {
            return new Promise(async (resolve) => {
                const { data: services } = await api.get(`groups/${group.id}/services`);
                commit('setServices', { groupId: group.id, services })
                resolve()
            })
        })

        await Promise.all(promises)

        if (getters.services.length) {
            dispatch('setService', getters.services[0])
            await dispatch('fetchChartData')
        } else {
            dispatch('setService', null)
        }
        EventBus.$emit('new-content-loaded')
        return groups
    },

    async fetchChartData({ commit, getters }, { serviceId = getters.service.id, from = getters.range.from, to = getters.range.to } = {}) {
        const { data } = await api
            .get(`services/${serviceId}/uptime?from=${dateAsISOTime(from)}&to=${dateAsISOTime(to)}`);
        commit('setChartData', { serviceId, data });
        EventBus.$emit('new-content-loaded')
    },

    setService({ commit }, service) {
        commit('setService', service)
    },

    async setRange({ commit, dispatch }, range) {
        commit('setRange', range)
        await dispatch('fetchChartData')
    }
}
