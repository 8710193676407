<template>
    <div class="container">
        <div class="assembla-footer">
            Copyright © 2006–2020 Assembla Inc.
        </div>
    </div>
</template>

<script>
export default {
    name: 'AssemblaFooter'
}
</script>

<style lang="scss" scoped>
.container {
    display: flex;
    justify-content: center;
    background-color: #49535a;
}

.assembla-footer {
    width: 100%;
    max-width: 974px;
    padding: 20px 20px;
    color: #ffffff;
    font-family: "Source Sans Pro",Arial,Helvetica,"Helvetica Neue",sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
}
</style>
