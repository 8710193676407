<template>
    <ul class="desktop-menu">
        <li class="desktop-menu__item" v-if="config.hubs_switcher">
            <HubSwitcher />
        </li>
        <li class="desktop-menu__item" v-if="config.languages.available && config.languages.available.length > 1">
            <LanguageSwitcher class="desktop-menu__language-switcher" />
        </li>
        <li class="desktop-menu__item" v-if="$route.path === '/'">
            <Timezone />
        </li>
        <li class="desktop-menu__item" v-if="config.navbar_link && config.navbar_link.name">
            <a
                :disabled="offline"
                target="_blank"
                class="desktop-menu__link--navbar-link"
                :href="config.navbar_link.url"
                :title="config.navbar_link.name.toUpperCase()">
                <p class="desktop-menu__link">
                    {{config.navbar_link.name}}
                </p>
            </a>
        </li>
        <li class="desktop-menu__item" v-if="canSubscribe">
            <router-link
                :disabled="offline"
                tag="a"
                class="desktop-menu__link subscribe"
                :to="{ path: '/subscribers' }"
            >{{ $t('topbar.subscribe') }}</router-link>
        </li>
        <li class="desktop-menu__item" v-if="canEditSubscription">
            <router-link
                :disabled="offline"
                tag="a"
                class="desktop-menu__link edit-subscription"
                :to="{ path: `/subscribers/${token}/edit` }"
            >{{ $t('topbar.edit-subscription') }}</router-link>
        </li>
        <li class="desktop-menu__item" v-if="signedIn">
            <a
                :disabled="offline"
                class="desktop-menu__link logout"
                :href="logoutUrl"
            >{{ $t('topbar.sign-out') }}</a>
        </li>
    </ul>
</template>
<script>
import HubSwitcher from '@/components/HubSwitcher.vue'
import Timezone from '@/components/Timezone.vue'
import LanguageSwitcher from '@/components/LanguageSwitcher.vue'
import { mapGetters } from 'vuex'

export default {
    components: {
        Timezone,
        HubSwitcher,
        LanguageSwitcher
    },
    props: {
        logoutUrl: String,
        token: String,
        canSubscribe: Boolean,
        canEditSubscription: Boolean,
        signedIn: Boolean
    },
    computed: {
        ...mapGetters({
            config: 'config',
            offline: 'offlineAttr'
        })
    }
}
</script>
<style lang="scss" scoped>
.desktop-menu {
    margin: 0;
    padding: 0;
    display: inline-flex;
    list-style-type: none;
    max-width: 716px;

    &__item {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding-left: 4px;
        padding-right: 4px;
        transition: background-color 0.3s;

        &:hover {
            background-color: #f2f2f2;
        }

        &[disabled] {
            pointer-events: none;
            opacity: 0.5;
        }
    }

    &__link {
        display: flex;
        align-items: center;
        margin: 0;
        text-transform: uppercase;
        text-decoration: none;
        font-size: 0.8125rem;
        font-weight: 600;
        color: $textColor;
        padding: 0 16px;
        height: 100%;
        max-height: 58px;

        &--navbar-link {
            text-decoration: inherit;
            color: inherit;
            height: 100%;
            max-width: 260px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            display: flex;
            align-items: center;
        }
    }
}
</style>
