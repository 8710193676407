import api from '@/api-client';

export default {
    async fetchConfig({ commit }) {
        const { data: config } = await api.get('subscriptions/config')
        commit('setConfig', config)
        return config
    },
    async fetchSubscriber({ commit }) {
        let { data: subscriber } = await api.get('subscriber');

        commit('setSubscriber', subscriber);

        return subscriber
    },

    async fetchSubscription({ commit, getters }, token) {
        token = token || getters.subscriber.token;

        const { data: subscription } =
            token ?
                await api.get(`subscriptions/${token}`) :
                {
                    data: {
                        methods: []
                    }
                };

        commit('setSubscription', subscription);

        return subscription
    },

    async fetchGroupsWithServices({ commit }) {
            const { data: groups } = await api.get(`groups`);

            const promises = groups.map(async (group) => {
                const { data: services } = await api.get(`groups/${group.id}/services`);
                group.services = services;
            });

            await Promise.all(promises);
            commit('setGroupsWithServices', groups);

            return groups
    },

    async addMethod({ dispatch, commit, getters }, payload) {
        const token = getters.subscriber.token;
        const { data: subscription } = token ?
            await api.post(`subscriptions/${token}/methods`, payload) :
            await api.post(`subscriptions/methods`, payload);

        commit('setSubscription', subscription);

        if (!token) {
            dispatch('updateToken', subscription.token);
        }

        return subscription;
    },

    async updateMethod({ commit, getters }, { type, payload }) {
        const token = getters.subscriber.token;
        const { data: subscription } = await api.patch(`subscriptions/${token}/methods/${type}`, payload);

        commit('setSubscription', subscription);

        return subscription
    },

    async deleteMethod({ commit, getters }, type) {
        const token = getters.subscriber.token;
        await api.delete(`subscriptions/${token}/methods/${type}`);
        commit('deleteMethod', type)
    },

    async deleteMethods({ commit, getters }) {
        const token = getters.subscriber.token;
        await api.delete(`subscriptions/${token}/methods`);
        commit('deleteAllMethods')
    },

    updateSelectedMethod({ commit }, method) {
        commit('updateSelectedMethod', method);
    },

    setEditMode({ commit }, mode) {
        commit('setEditMode', mode)
    },

    setTerms({ commit }, accepted) {
        commit('setTerms', accepted)
    },

    updateToken({ commit, getters }, token) {
        commit('setSubscriber', { ...getters.subscriber, token })
    },

    resetSelectedMethod({ commit }) {
        commit('resetSelectedMethod');
    },

    setStep({ commit }, step) {
        commit('setPreviousStep')
        commit('setStep', step)
    }
}
