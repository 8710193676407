<template>
    <div class="tab" v-show="active" v-if="rendered">
        <slot />
    </div>
</template>
<script>
export default {
    props: {
        label: {
            type: String,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            active: false,
            rendered: false
        }
    },
    methods: {
        show() {
            this.active = true
            this.rendered = true
        },
        hide() {
            this.active = false
        }
    },
    created() {
        try {
            this.$parent.register(this)
        } catch (e) {
            throw new Error('Could not register tab component in parent')
        }
    }
}
</script>
<style lang="scss" scoped>

</style>
