export default {
    setMaintenance(state, maintenance) {
        state.maintenances[maintenance.id] = maintenance
    },
    setCurrentMaintenances(state, maintenances) {
        state.currentMaintenanceIds =
            maintenances.map(maintenance => maintenance.id)
        maintenances.forEach(maintenance => {
            state.maintenances[maintenance.id] = maintenance
        })
    },
    setCalendarMaintenances(state, maintenances) {
        state.calendarMaintenances = maintenances
    },
    resetCalendarMaintenancesDays(state) {
        state.calendarMaintenances.days = {}
    },
    setFutureMaintenances(state, maintenances) {
        state.futureMaintenanceIds =
            maintenances.map(maintenance => maintenance.id)
        maintenances.forEach(maintenance => {
            state.maintenances[maintenance.id] ||= maintenance
        })
    },
    setDate(state, date) {
        state.date = date
    }
}
