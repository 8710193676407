import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
    groups: [],
    counters: {}
};

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true
}
