<template>
    <div class="counters">
        <div class="counter">
            <span class="count status" :class="{ 'status--up': upColor }">{{ upCount }}</span>
            <span class="label">{{ $t('blocks.counters.up') }}</span>
        </div>
        <div class="counter">
            <span class="count status" :class="{ 'status--affected': data.count_status_2 }"> {{ data.count_status_2 }}</span>
            <span class="label">{{ $t('blocks.counters.affected') }}</span>
        </div>
        <div class="counter">
            <span class="count status" :class="{ 'status--down': data.count_status_3 }">{{ data.count_status_3 }}</span>
            <span class="label">{{ $t('blocks.counters.down') }}</span>
        </div>
    </div>
</template>

<script>
import api from '@/api-client';
import EventBus from '@/event-bus';
import customTranslations from '@/mixins/customTranslations'

export default {
    mixins: [customTranslations],
    data() {
        return {
            data: {},
            meta: {
                up_counter_mode: 0
            }
        }
    },
    computed: {
        hideUpCount() {
            return this.meta.up_counter_mode === 1
        },
        upColor() {
            if (this.hideUpCount) {
                return this.data.all_up
            }

            return this.data.count_status_1
        },
        upCount() {
            if (!this.hideUpCount) {
                return this.data.count_status_1
            }

            if (this.data.all_up) {
                return this.$t(this.i18nKey('blocks.counters.all'))
            } else {
                return ''
            }
        }
    },
    methods: {
        async get() {
            const { data } = await api.get('blocks/traffic_lights/v1')
            this.data = data.data
            this.meta = data.meta
        }
    },
    mounted() {
        this.get()
        EventBus.$on('data-refresh', () => this.get())
    }
}
</script>
<style lang="scss" scoped>
.counters {
    display: flex;

    .counter {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #fff;
        flex: 0 1 33%;
        box-sizing: border-box;
        padding: 24px 8px;
        border-radius: 6px;

        &:not(:last-child) {
            margin-right: 12px;
        }

        @include breakpoint($md) {
            padding-top: 24px;
            padding-bottom: 24px;
        }

        .count {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 60px;
            height: 60px;
            border-radius: 100%;
            font-weight: 600;
            text-transform: uppercase;
            color: #000;
            font-size: 1.5rem;

            @include breakpoint($md) {
                width: 107px;
                height: 107px;
                font-size: 2.375rem;
            }
        }

        .label {
            display: block;
            font-weight: 600;
            text-transform: uppercase;
            font-size: 0.75rem;
            letter-spacing: 1.07px;
            padding-top: 12px;
            color: $textColor;
            text-align: center;
            @include antialiasing();

            @include breakpoint($md) {
                font-size: 0.9375rem;
            }
        }

        .status {
            background-color: silver;

            &--up {
                background-color: $green;
            }

            &--down {
                background-color: $red;
            }

            &--affected {
                background-color: $orange;
            }
        }
    }
}
</style>
