<template>
    <div class="tabs">
        <ul
            class="tabs__items"
            :class="{ 'tabs__items--more-than-two': tabs.length > 2 }"
        >
            <li v-for="(tab, index) in tabs"
                :key="index"
            >
                <a
                    href="#"
                    @click="change(index)"
                    class="tabs__items__item"
                    :class="{
                        'tabs__items__item--active': index === modelValue,
                        'tabs__items__item--disabled': tab.disabled
                    }"
                    :data-test-id="tabTestID(tab, index)"
                    v-on:click.prevent
                >
                    <span class="item">{{ tab.label }}</span>
                </a>
            </li>
        </ul>
        <div class="tabs__content">
            <slot />
        </div>
    </div>
</template>
<script>
import Tab from './Tab.vue'
import EventBus from '@/event-bus';

export default {
    name: 'Tabs',
    emits: [
        'update:modelValue'
    ],
    components: {
        Tab
    },
    data() {
        return {
            tabs: []
        }
    },
    props: {
        modelValue: {
            type: Number,
            required: true
        },
        calendarID: {
            type: Number
        }
    },
    computed: {
        currentTab() {
            return this.tabs[this.currentTabIdx]
        },
        currentTabIdx() {
            if (this.tabs[this.modelValue] != undefined) {
                return this.modelValue
            } else {
                return 0
            }
        }
    },
    methods: {
        register(tab) {
            // FIXME - Switch to object instead of array so views can be
            // registered with a key instead assuming ascending and continuous
            // order of view type values
            this.tabs.push(tab)
        },
        switchToCalendar() {
            if (!this.calendarID) return

            this.change(this.calendarID)
        },
        tabTestID(tab, index) {
            const tabSlug = tab.label?.toLowerCase().replace(' ', '-')
            return `tab-${tabSlug || index}`
        },
        change(index) {
            this.currentTab.hide()
            this.$emit('update:modelValue', index)
            this.tabForIndex(index).show()
            EventBus.$emit('historyTabSwitch')
            EventBus.$emit('new-content-loaded')
        },
        showCurrentTab() {
            if (this.tabs.length === 0) {
                return
            }

            this.currentTab.show()
        },
        tabForIndex(index) {
            return this.tabs[index] || this.tabs[0]
        }
    },
    mounted() {
        this.showCurrentTab()
        if (this.modelValue !== this.currentTabIdx) {
            this.change(this.currentTabIdx)
        }
        EventBus.$on('switch-to-calendar', () => this.switchToCalendar())
    }
}
</script>
<style lang="scss" scoped>
.tabs {
    width: 100%;

    &__items {
        display: flex;
        justify-content: center;
        margin-bottom: 16px;
        padding-bottom: 20px;
        padding-inline-start: 0px;
        row-gap: 20px;

        a:hover, a:visited, a:link, a:active {
            text-decoration: none;
        }

        li {
            list-style: none;
        }

        @include breakpoint($sm) {
            padding-bottom: 0px;
        }

        &--more-than-two {
            justify-content: space-between;

            @include breakpoint($sm) {
                justify-content: center;
            }

            .tabs__items__item {
                padding: 0.5rem 0.25rem;

                @include breakpoint($sm) {
                    padding: 0.5rem 1rem;
                }
            }
        }

        &__item {
            padding: 8px 16px;
            cursor: pointer;
            box-sizing: border-box;
            border-bottom: 2px solid transparent;
            transition: all 0.3s;
            white-space: nowrap;

            @include breakpoint($sm) {
                padding: 16px 32px;
            }

            .item {
                text-transform: uppercase;
                font-size: 0.8125rem;
                color: $textColor;
                font-weight: 600;
                letter-spacing: 2px;
            }

            &--active {
                border-bottom: 2px solid $blue;

                .item {
                    color: $blue;
                }
            }

            &--disabled {
                pointer-events: none;
                opacity: 0.5;
            }

            &:hover:not(.tabs__items__item--active) {
                border-bottom: 2px solid $textColor;
            }
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
    }
}
</style>
