<template>
    <div
        class="item"
    >
        <Icon
            v-if="!noIcon"
            class="icon"
            :name="eventIconName">
        </Icon>
        <div class="item__data">
            <router-link class="item__title" :to="{ path: `${eventType}s/${event.id}` }">
                {{ eventTitle(event, eventType) }}
            </router-link>
            <div v-if="hasStartDate && hasEndDate">
                <span class="item__date">
                    {{ getDate(event.timestamp_start) }}
                    <span class="time">
                        {{ getTime(event.timestamp_start) }}
                    </span> -
                    <span class="item__date--end">
                        {{ getDate(event.timestamp_end) }}
                        <span class="time">
                            {{ getTime(event.timestamp_end) }}
                        </span>
                    </span>
                    <span class="item__date--duration">
                        {{ getDuration(event.timestamp_start, event.timestamp_end) }}
                    </span>
                </span>
            </div>
            <div v-else-if="hasStartDate">
                <span class="item__date">
                    {{ getDate(event.timestamp_start) }}
                    <span class="time">
                        {{ getTime(event.timestamp_start) }}
                    </span>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import Icon from '@/components/Icon.vue'
import incidentMixin from '@/mixins/incident.js'
import { DateTime } from 'luxon'
import timeOperations from '@/mixins/timeOperations.js'

export default {
    name: 'EventShort',
    components: {
        Icon
    },
    mixins: [incidentMixin, timeOperations],
    props: {
        event: {
            required: true
        },
        eventType: {
            type: String,
            default: 'incident'
        },
        noIcon: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        eventIconName() {
            if (this.eventType === 'incident') {
                return this.incidentIconName(this.event)
            } else {
                return 'maintenance'
            }
        },
        hasEndDate() {
            return (this.event.timestamp_end || 0) !== 0
        },
        hasStartDate() {
            return (this.event.timestamp_start || 0) !== 0
        }
    },
    methods: {
        eventTitle(event, eventType) {
            if (this.eventType === 'incident') {
                return this.incidentTitle(this.event)
            } else {
                return this.event.title
            }
        },
        getDate(timestamp) {
            return this.formatTimestamp(timestamp, 'date')
        },
        getTime(timestamp) {
            return this.formatTimestamp(timestamp, 'time')
        },
        getDuration (timestampStart, timestampEnd) {
            const start = DateTime.fromSeconds(timestampStart)
            const end = DateTime.fromSeconds(timestampEnd)

            const timeUnits = ['days', 'hours', 'minutes']
            const diff = end.diff(start, timeUnits).toObject()
            let duration = ''
            timeUnits.forEach((unit) => {
                if (diff[unit]) {
                    duration +=
                        ` ${Math.floor(diff[unit])}${this.$t(`components.event.timeAbbreviations.${unit}`)}`
                }
            })

            return `(${this.$t('components.event.duration')}${duration})`
        }
    }
}
</script>
<style lang="scss" scoped>
.item {
    display: flex;
    flex-direction: row;

    &__data {
        display: flex;
        flex-direction: column;

    }

    &__title {
        color: $textColorBlue;
        font-size: 1rem;
        font-weight: 600;
        letter-spacing: -0.33px;
        line-height: 1.375rem;
    }

    &__date {
        margin-top: 4px;
        font-size: 0.875rem;
        line-height: 1.1875rem;
        color: $textColor;

        .time {
            font-weight: bold;
        }

        &--end {
            display: none;

            @include breakpoint($sm) {
                display: inline;
            }
        }

        &--duration {
            @include breakpoint($sm) {
                display: none;
            }
        }
    }

    .icon {
        padding-right: 16px;
    }
}

.item:not(:last-child) {
    margin-bottom: 32px;
}
</style>
