<script>
import Icon from '@/components/Icon.vue'
import incidentMixin from '@/mixins/incident.js'

export default {
    name: 'CalendarEvent',
    mixins: [incidentMixin],
    components: {
        Icon
    },
    props: {
        event: {
            type: Object,
            required: true
        }
    },
    computed: {
        eventIconName() {
            if (!this.event) {
                return undefined
            } else if (this.event.event_type === 0) {
                return this.incidentIconName(this.event)
            } else {
                return 'maintenance'
            }
        },
    }
}
</script>

<template>
    <div v-if="event" class="event">
        <div class="event__duration-indicator"></div>
        <div class="event__content">
            <Icon :name="eventIconName" class="event__icon" />
            <p class="event__title">{{ event.title }}</p>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.event {
    display: flex;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    transition: background-color 0.3s;
    cursor: pointer;
    text-decoration: none;
    color: #000;

    &__duration-indicator {
        display: none;
        min-width: 4px;
        height: 100%;
        min-height: 2px;
        background-color: $blue;
    }

    &__content {
        padding: 4px;
        display: flex;
        align-items: center;
        min-height: 30px;
        flex-grow: 1;
        background-color: #e0e5ee;
    }

    &__title {
        margin: 0 4px;
        font-weight: 400;
        font-size: 13px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__icon {
        height: 18px;
        width: 18px;
        margin-right: 0.25rem;
    }
}
</style>
