<template>
    <div class="education-scotland-footer">
        <div class="links">
            <a href="https://education.gov.scot/">Education Scotland Home  | </a>
            <a href="https://education.gov.scot/privacy-and-cookies">Privacy & Cookies Policy | </a>
            <a href="https://glowconnect.org.uk/">Glow Connect  | </a>
            <a href="https://glow.rmunify.com/">Glow Login | </a>
            <a href="https://education.gov.scot/education-scotland/service-status">Accessibility Statement</a>
        </div>
        <div class="copyright">
            Copyright © Education Scotland 2020
        </div>
    </div>
</template>

<script>
export default {
    name: 'EducationScotlandFooter'
}
</script>

<style lang="scss" scoped>
.education-scotland-footer {
    text-align: center;
    padding: 0 15px 10px;
    font-family: 'Arial';
    font-size: 0.875rem;
    font-weight: 500;
    color: black;
    line-height: 1.3125rem;

    a {
        text-decoration: none;
        color: black;

        &:hover {
            text-decoration: underline;
        }
    }
}
</style>
