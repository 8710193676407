<template>
    <div id="container">
        <SkipToContent/>
        <template v-if="!pageNotFound && !authFailed">
            <OfflineAlert v-if="offline" />
            <PlatformAlert v-if="platformAlert" />
            <Topbar />
            <!--<keep-alive>-->
                <router-view class="router-content"/>
            <!--</keep-alive>-->
            <Footer />
        </template>
        <PageNotFound v-if="pageNotFound" />
        <Communicates />
        <!-- <Tooltip /> -->
        <transition name="overlay-fade">
            <div class="overlay" v-if="isRefreshing"></div>
        </transition>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PageNotFound from '@/views/404/Index.vue';
import Communicates from '@/components/Communicates.vue';
import PlatformAlert from '@/components/PlatformAlert.vue';
import OfflineAlert from '@/components/OfflineAlert.vue';
import Topbar from '@/components/Topbar.vue';
import Footer from '@/components/Footer.vue'
import locale from '@/mixins/locale'
import timeOperations from '@/mixins/timeOperations'
import { localize } from '@vee-validate/i18n'
import progress from '@/mixins/progress';
import SkipToContent from '@/components/SkipToContent.vue';

export default {
    name: 'App',
    mixins: [
        locale,
        progress,
        timeOperations
    ],
    components: {
        SkipToContent,
        OfflineAlert,
        Communicates,
        PlatformAlert,
        Topbar,
        Footer,
        PageNotFound
    },
    computed: {
        ...mapGetters({
            config: 'config',
            isRefreshing: 'refreshing',
            pageNotFound: 'pageNotFound',
            authFailed: 'authFailed',
            offline: 'offline'
        }),
        platformAlert() {
            return this.config?.platform_alert?.enabled;
        }
    },
    methods: {
        ...mapActions({
            allowAnalytics: 'allowAnalytics',
            disallowAnalytics: 'disallowAnalytics',
            pushMessage: 'messages/push',
            setTimeZone: 'setTimeZone'
        }),
        installCookieHub() {
            if (!this.config.cookies_notice) {
                return
            }

            const cpm = {
                onAllow: (category) => {
                    if (category === 'analytics') {
                    // FIXME
                    }
                },
                onRevoke: (category) => {
                    if (category === 'analytics') {
                    // FIXME
                    }
                }
            };
            (function(document, window, cpm) {
                const element = document.createElement('script')
                element.async = true
                element.src = 'https://cookiehub.net/c2/8dd6ff3d.js';
                element.onload = () => {
                    window.cookiehub.load(cpm)
                }
                document.head.appendChild(element);
            })(document, window, cpm);
        },
        setDefaultTimeZone() {
            const name = $cookies.get('timezone')
            this.setTimeZone(this.timeZoneByName(name))
        }
    },
    created() {
        const onOnline = () => {
            this.pushMessage(this.$t('communicates.online'))
        }

        const onOffline = () => {
            this.pushMessage(this.$t('communicates.offline'))
        }

        if (!navigator.onLine) {
            onOffline()
        }

        window.addEventListener('offline', onOffline);
        window.addEventListener('online', onOnline);
        localize(this.$i18n.locale.split('-')[0].replace('dk', 'da'))
    },
    mounted() {
        this.setDocumentLang()
        this.setDefaultTimeZone()
        this.installCookieHub()
    }
}
</script>

<style lang="scss">
@import '@/assets/sass/fonts.scss';

html, body {
    margin: 0;
    padding: 0;
    font-family: Avenir, Open Sans,ITC Franklin Gothic,Helvetica Neue,helvetica,sans-serif;
    font-weight: 400;
    color: $textColor2;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%; // important for footer
}

pre {
    white-space: pre-wrap;
}

input[type="search"] {
   -webkit-appearance: none;
}

body {
    overflow-y: scroll;

    @include tooltip;

    // important for footer
    #app {
        min-height: 100%;
        position: relative;
    }

    #container {
        .router-content {
            padding-bottom: 4rem;
        }
    }

    .overlay {
        z-index: 99999;
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.3);
    }

    .overlay-fade-enter-active, .overlay-fade-leave-active {
        transition: background-color .3s;
    }
    .overlay-fade-enter, .overlay-fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        background-color: rgba(0, 0, 0, 0);
    }

    &.scroll-disabled {
        overflow: hidden;
    }
}
</style>
