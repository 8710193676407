export default {
    data() {
        return {
            loading: false
        }
    },
    methods: {
        progress(fn) {
            return new Promise(async (resolve, reject) => {
                this.loading = true;
                try {
                    await fn();
                    resolve();
                } catch (e) {
                    reject(e);
                }
                this.loading = false;
            });
        }
    }
}
