<template>
    <div class="container">
        <footer class="msu-footer">
            <div class="msu-footer__col msu-footer__col--logo">
                <a href="http://msu.edu" alt="Michigan State University Wordmark">
                    <img
                        src="https://s3.amazonaws.com/statushub/customers/msu/images/msu-wordmark-green.png"
                        alt="Michigan State University Wordmark"
                    >
                </a>
            </div>
            <div class="msu-footer__col msu-footer__col--content">
                <div class="standard-footer-site-links">
                    <ul class="msu-links">
                        <li>
                            <a href="https://tech.msu.edu/contact/">Contact Information</a>
                        </li>
                        <li>
                            <a href="https://tech.msu.edu/privacy/">Privacy Statement</a>
                        </li>
                        <li>
                            <a href="https://tech.msu.edu/site-accessibility/">Site Accessibility</a>
                        </li>
                    </ul>
                </div>
                <div class="msu-footer-info">
                    <ul class="msu-info-list">
                        <li>
                            Call MSU: <strong class="msu-phone">(517) 355-1855</strong>
                        </li>
                        <li>
                            Visit <strong class="msu-phone"><a href="http://msu.edu">msu.edu</a></strong>
                        </li>
                        <li>
                            MSU is an affirmative-action, equal-opportunity employer.
                        </li>
                        <li>
                            <strong class="msu-phone">
                                <a href="http://oie.msu.edu/">Notice of Nondiscrimination</a>
                            </strong>
                        </li>
                    </ul>
                </div>
                <div class="msu-footer-copyright">
                    <ul class="copyright">
                        <li class="spartans-will">Spartans Will.</li>
                        <li>&#169; Michigan State University</li>
                    </ul>
                </div>
            </div>
        </footer>
    </div>
</template>

<script>
export default {
    name: 'MsuFooter'
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 992px) {
    .msu-footer {
        flex-direction: column-reverse;
        text-align: center;

        ul {
            justify-content: center;
        }

        .standard-footer-site-links {
            max-width: none !important;
        }

        .msu-footer-copyright {
            margin-bottom: 12px;
        }
    }
}

.container {
    display: flex;
    justify-content: center;
}

.msu-footer {
    width: 100%;
    max-width: 1040px;
    padding: 25px 0 20px;
    font-family: "Gotham SSm A", "Gotham SSm B", arial, sans-serif;
    border-top: 1px solid #eee;
    display: flex;

    &__col {
        padding: 0 15px;
    }

    &__col--content {
        flex: 1;
        font-size: 0.625rem;
        color: #151a22;

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            display: flex;

            li:not(:last-child) {
                padding-right: 6px;
                border-right: 1px solid #444444;
                margin-right: 6px;
            }

            &.msu-info-list {
                flex-wrap: wrap;

                li {
                    margin-bottom: 8px;
                }
            }
        }

        a {
            text-decoration: none;
            color: #4c7e00;

            &:hover {
                text-decoration: underline;
            }
        }

        .msu-phone {
            color: #4c7e00;
            font-weight: bold;
        }

        .standard-footer-site-links {
            position: relative;
            top: -4px;
            font-size: 0.875rem;
            line-height: 1.3125rem;
            padding-bottom: 4px;
            border-bottom: 1px dotted #808080;
            margin-bottom: 2px;
            max-width: 550px;

            a {
                color: #18453b;
            }
        }

        .msu-footer-copyright .spartans-will {
            font-weight: bold;
            text-transform: uppercase;
        }
    }
}
</style>
