<template>
    <div class="legend" :class="{ 'legend--horizontal': horizontal }">
        <div class="legend__dot">
            <span class="legend__text">{{ nameForUp }}</span>
            <StatusDot class="status" :status="1" />
        </div>
        <div class="legend__dot">
            <span class="legend__text">{{ nameForIssue }}</span>
            <StatusDot class="status" :status="2" />
        </div>
        <div class="legend__dot">
            <span class="legend__text">{{ nameForDown }}</span>
            <StatusDot class="status" :status="3" />
        </div>
        <div v-if="withNA" class="legend__dot">
            <span class="legend__text">{{ nameForNA }}</span>
            <StatusDot class="status" :status="0" />
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import StatusDot from '@/components/StatusDot.vue'

export default {
    name: 'Legend',
    props: {
        horizontal: {
            type: Boolean,
            default: false
        },
        withNA: {
            type: Boolean,
            default: false
        }
    },
    components: {
        StatusDot
    },
    computed: {
        ...mapGetters([
            'config'
        ]),
        nameForUp() {
            return this.config?.name_for_statuses[1] || this.$t('history.legend.up');
        },
        nameForIssue() {
            return this.config?.name_for_statuses[2] || this.$t('history.legend.issue');
        },
        nameForDown() {
            return this.config?.name_for_statuses[3] || this.$t('history.legend.down');
        },
        nameForNA() {
            return this.$t('history.legend.na');
        }
    }
}
</script>

<style lang="scss" scoped>
.legend {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: $textColor;
    margin: 16px 0;

    &__dot {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .status {
            transform: scale(0.7)
        }
    }

    &__text {
        padding-right: 16px;
        font-size: 0.875rem;
    }

    @include breakpoint($md) {
        &--horizontal {
            text-align: left;
            flex-direction: row;

            .legend__dot {
                display: flex;
                align-items: center;
                flex-direction: row-reverse
            }

            .legend__text {
                padding-left: 8px;
                font-size: 0.875rem;
                margin-right: 16px;
            }
        }
    }
}
</style>
