<template>
    <Select
        v-if="showSwitcher"
        class="hub-switcher"
        :options="hubsSwitcher"
        :placeholder="$t('components.hub-switcher.placeholder')"
        label-key="name"
        @update:modelValue="onHubChanged"
    />
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import Select from './Select.vue'

export default {
    components: {
        Select
    },
    computed: {
        ...mapGetters({
            hubsSwitcher: 'hubsSwitcher'
        }),
        showSwitcher() {
            return this.hubsSwitcher.length > 0
        }
    },
    methods: {
        ...mapActions({
            fetch: 'fetchHubsSwitcher'
        }),
        onHubChanged(hub) {
            window.location.href = hub.url;
        }
    },
    async created() {
      await this.fetch()
    }
}
</script>
<style lang="scss" scoped>
.hub-switcher {
    width: 150px;
    height: 36px;
}
</style>
