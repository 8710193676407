export default {
    setHistory(state, history) {
        state.history = {}
        history.forEach((group, index) => {
            if (!state.history[group.group_id]) {
                state.history[group.group_id] = {
                    group: null,
                    labels: [],
                    order: index
                }
            }
            state.history[group.group_id].group = group
        })
    },
    setLabels(state, labels) {
        for (const key in state.history) {
            state.history[key].labels = labels
        }
    },
    setGroupHistory(state, { groupId, history }) {
        state.history[groupId].group.services = history
    },
    setGroupLabels(state, { groupId, labels }) {
        state.history[groupId].labels = labels
    },
    setFilter(state, value) {
        state.filter = value
    },
    setView(state, view) {
        state.view = view
    }
}
