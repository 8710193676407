import store from '@/store'
import VueCookies from 'vue-cookies'

export default {
    install (app) {
        const cookies = VueCookies
        app.config.globalProperties.$cookies = cookies
        store.$cookies = cookies
    }
}
