<template>
    <div class="events">
      <div class="events__details">
        <Notifications class="flexable" />
        <PlannedMaintenance class="flexable" />
      </div>
      <div class="events__legend">
        <Legend horizontal v-if="showLegend"/>
      </div>
    </div>
</template>
<script>
import Legend from '@/components/Legend.vue'
import Notifications from './events/Notifications.vue'
import PlannedMaintenance from './events/PlannedMaintenance.vue'
import { mapGetters } from 'vuex';

export default {
    components: {
        Legend,
        Notifications,
        PlannedMaintenance
    },
    computed: {
        ...mapGetters({
            config: 'config'
        }),
        showLegend() {
            return this.config.view_type === 2
        }
    }
}
</script>
<style lang="scss" scoped>
.events {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    &__details {
      display: flex;
      flex-direction: column;

      @include breakpoint($lg) {
          flex-direction: row;
          padding: 0 calc((100% - #{$screen-lg-min}) / 2 + 48px);
      }

      @include breakpoint($lg) {
          @include flexable(48px)
      }
    }

    &__legend {
      margin-top: -24px;
      margin-bottom: 8px;
    }
}
</style>
