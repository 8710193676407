<template>
    <footer class="footer-stil">
        <div class="footer-stil__column">
                <h1>Ansvarlig myndighed</h1>
                <ul>
                    <li>
                        <a href="https://stil.dk">
                            <img
                                src="https://statushub.s3.amazonaws.com/customers/stil/images/stil-logo.svg"
                                class="logo"
                                alt="Logo for Styrelsen for It og Læring"
                                title="Styrelsen for It og Læring"
                            >
                        </a>
                    </li>
                </ul>
        </div>
        <div class="footer-stil__column">
                <h1>Kontakt</h1>
                <ul>
                    <li><a href="tel:+4535878881">(+45) 3587 8881</a></li>
                    <li>
                        <a href="https://www.stil.dk/support/vejledninger-og-kontaktoplysninger">
                            Vejledninger og kontaktoplysninger
                        </a>
                    </li>
                </ul>
        </div>
        <div class="footer-stil__column">
                <h1>Hjælpende links</h1>
                <ul>
                    <li><a href="https://viden.stil.dk/">Videnbase</a></li>
                </ul>
        </div>
        <div class="footer-stil__column">
                <h1>Webtilgængelighed og cookies</h1>
                <ul>
                    <li>
                        <a href="https://www.was.digst.dk/driftsinfo-stil-dk">
                            Tilgængelighedserklæring
                        </a>
                    </li>
                    <li>
                        <a href="https://viden.stil.dk/x/jgZFBw">
                            Privatlivspolitik (cookies)
                        </a>
                    </li>
                </ul>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'StilFooter'
}
</script>

<style lang="scss" scoped>
.footer-stil {
    display: flex;
    flex-direction: column;
    max-width: 1064px;
    padding-top: 23px;
    padding-left: 8px;
    margin: 0 auto;
    border-top: 1px solid #000;

    @include breakpoint($md) {
        flex-direction: row;
    }

    @include breakpoint ($lg) {
        padding-left: 0;
    }

    &__column {
        width: 25%;
        min-width: 150px;
        padding-bottom: 24px;

        h1 {
            font-size: 1rem;
            font-weight: 600;
            line-height: 1.5rem;
            padding-right: 8px;
            white-space: nowrap;
        }

        ul {
            line-height: 2;
            padding: 0 8px 8px 0;
            margin: 0;
            list-style-type: none;

            .logo {
                min-width: 190px;
                max-width: 230px;
                border: none;
            }

            a {
                color: #000;
            }
        }
    }
}
</style>
