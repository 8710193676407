import api from '@/api-client';
import EventBus from '@/event-bus';

export default {
    async fetch({ commit }) {
        const { data: statuses } = await api.get('statuses', { data: {} });
        commit('setCurrentStatuses', statuses);
        EventBus.$emit('new-content-loaded')
        return statuses
    }
}
