import api from '@/api-client';
import EventBus from '@/event-bus';
import { dateFrom, dateTo, dateAsISOTime } from '@/utils';

export default {
    async fetch({ commit, getters, dispatch }, { from, to, offset = 0, limit = 30 } = {}) {
        const filter = getters.filter;
        const [{ data: history }, labels] = await Promise.all([
            api.get(!from || !to ?
                filter ? `history?q=${filter}` : `history` :
                filter ? `history?from=${dateAsISOTime(from)}&to=${dateAsISOTime(to)}&offset=${offset}&limit=${limit}&q=${filter}` :
                         `history?from=${dateAsISOTime(from)}&to=${dateAsISOTime(to)}&offset=${offset}&limit=${limit}`
            ),
            dispatch('fetchLabels', { from, to })
        ])

        commit('setHistory', history);
        commit('setLabels', labels);
        EventBus.$emit('new-content-loaded')
        return history
    },

    async fetchGroupHistory({ commit, getters, dispatch }, { groupId, from = dateFrom(), to = dateTo(), offset = 0, limit = 30 } = {}) {
        const filter = getters.filter;
        const [{ data: history }, labels] = await Promise.all([
            api.get(
                filter ?
                `groups/${groupId}/history?from=${dateAsISOTime(from)}&to=${dateAsISOTime(to)}&offset=${offset}&limit=${limit}&q=${filter}` :
                `groups/${groupId}/history?from=${dateAsISOTime(from)}&to=${dateAsISOTime(to)}&offset=${offset}&limit=${limit}`
                ),
            dispatch('fetchLabels', { from, to })
        ])
        commit('setGroupHistory', { groupId, history });
        commit('setGroupLabels', { groupId, labels });
        EventBus.$emit('new-content-loaded')
        return history
    },

    async fetchLabels(_, { from, to }) {
        const { data: labels } = await api.get(!from || !to ?
            `history/labels` :
            `history/labels?from=${dateAsISOTime(from)}&to=${dateAsISOTime(to)}`
        );
        EventBus.$emit('new-content-loaded')
        return labels
    },

    setFilter({ commit }, value) {
        commit('setFilter', value);
    },

    setView({ commit }, view) {
        commit('setView', view)
        $cookies.set('view-type', view)
    }
}
