<template>
    <div class="platform-alert"
         :style="{ backgroundColor: alertData.color }"
    >
        <div class="alert-icon">
            <Icon :name=alertData.iconName size="3x" />
        </div>
        <div class="text">
            <div class="title">{{ config.platform_alert.title }}</div>
            <div v-if="expanded && config.platform_alert.message" class="message" v-html="config.platform_alert.message"></div>
            <div class="show-more" @click="expanded = !expanded">{{ !expanded ? $t('components.platform-alert.show-more') : $t('components.platform-alert.show-less') }}</div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Icon from '@/components/Icon.vue'

export default {
    name: 'PlatformAlert',
    components: {
        Icon
    },
    computed: {
        ...mapGetters([
            'config'
        ]),
        alertData() {
            return this.alertTypes[this.config.platform_alert.type_name] ||
                this.alertTypes['alert']
        }
    },
    data() {
        return {
            alertTypes: {
                alert: {
                    color: '#f00',
                    iconName: 'bullhorn'
                },
                notification: {
                    color: '#00b0f0',
                    iconName: 'info-circle'
                }
            },
            expanded: this.$store.getters.config.platform_alert.auto_expand
        }
    }
}
</script>
<style lang="scss" scoped>
@import '@/views/events.scss';

.platform-alert {
    display: flex;
    color: #fff;
    z-index: 10;
    padding: 32px 16px;

    @include breakpoint($md) {
        padding: 32px;
    }

    @include breakpoint($lg) {
        padding: 32px calc((100% - #{$screen-lg-min}) / 2 + 48px);
    }

    .alert-icon {
        align-self: center;
    }

    .text {
        padding-left: 32px;
        padding-right: 8px;

        .title {
            font-weight: bold;
            font-size: 1.125rem;
        }

        .message ::v-deep(a) {
            color: #fff;
        }

        .message {
            @include quill-styles(16px);
        }

        .show-more {
            margin-top: 12px;
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

</style>
