<template>
    <div class="group" :class="themeClass">
        <div class="group__header">
            <div class="group__header__info">
                <h3 class="group-name">{{ group.group_name }}</h3>
            </div>
        </div>
        <div class="group__services">
            <div
                class="group__services__headers"
                :class="{ 'group__services__headers--few-cols': hasFewCols }"
            >
                <div class="header">
                    <span class="service-name-label">{{ $t('history.list.group.services.label') }}</span>
                </div>
                <div class="labels">
                    <span class="label labels__label_narrow"
                          v-for="(label, index) in labels"
                          :key="`narrow-${index}`"
                          :style="{ width: columnWidth }"
                    >
                        {{ label }}
                    </span>
                    <span class="label labels__label_regular"
                          v-for="(label, index) in labels"
                          :key="index"
                          :style="{ width: columnWidth }"
                    >
                        {{ label }}
                    </span>
                </div>
            </div>
            <NowService
                v-for="(services, name, index) in groupedServices"
                :column-width="columnWidth"
                :key="index"
                :services="services"
                :name="name"
                :labels="labels"
                :has-few-cols="hasFewCols"
            />
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import NowService from './NowService.vue'
import theme from '@/mixins/theme';

const FEW_COLS_THRESHOLD = 8

export default {
    components: {
        NowService
    },
    mixins: [theme],
    props: {
        group: {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapGetters({
            connectedHubServices: 'connectedHubs/services',
            filter: 'history/filter',
            config: 'config',
            offline: 'offlineAttr'
        }),
        columnWidth() {
            if (!this.labels?.length) {
                return '100%'
            }
            return 100 / this.labels.length + '%'
        },
        hasFewCols() {
            return this.labels?.length < FEW_COLS_THRESHOLD
        },
        groupedServices() {
            let grouped = {}
            this.group.services.forEach((service) => {
                if (!grouped[service.service_name]) {
                    grouped[service.service_name] = [];
                }
                grouped[service.service_name].push(service)
            })
            return grouped
        },
        labels() {
            return this.connectedHubServices.map(
                connectedHub => connectedHub.name)
        }
    },
    methods: {
        ...mapActions({
            fetch: 'history/fetchGroupHistory'
        })
    }
}
</script>
<style lang="scss" scoped>
.group {
    margin-bottom: 32px;

    &__header {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0;

        @include breakpoint($md) {
            flex-direction: row;
            padding-top: 16px;
            padding-bottom: 16px;
        }

        &__info {
            padding-top: 8px;
            padding-bottom: 8px;
            padding-right: 8px;

            @include breakpoint($md) {
                text-align: left;
                flex: 1;
            }

            .group-name {
                font-size: 1.125rem;
                font-weight: 500;
                white-space: pre-wrap;
                margin-block-start: 0em;
                margin-block-end: 0em;
            }
        }
    }

    &__services {
        padding-left: 0px;
        border: 2px solid #F9FAFC;

        &__headers {
            display: flex;
            flex-direction: column;
            padding-top: 16px;
            padding-bottom: 16px;
            padding-left: 8px;
            background-color: #F9FAFC;

            @include breakpoint($md) {
                flex-direction: row;
                padding-left: 16px;
            }

            .header {
                width: 100%;
                text-align: center;

                @include breakpoint($md) {
                    text-align: left;
                    width: 250px;
                }

                .service-name-label {
                    color: $textColor2;
                    text-transform: uppercase;
                    font-size: 0.6875rem;
                    font-weight: 600;
                    letter-spacing: 0.46px;
                    text-align: center;
                }
            }

            .labels {
                display: flex;
                width: 100%;
                text-align: center;

                @include breakpoint($md) {
                    width: calc(100% - 250px);
                }

                .label {
                    align-self: center;
                    color: $textColor;
                    font-size: 0.6875rem;
                    letter-spacing: 0.46px;
                    font-weight: 600;
                    text-transform: uppercase;

                }

                &__label_regular {
                    display: none;

                    @include breakpoint($md) {
                        display: block;
                    }
                }

                &__label_narrow {
                    display: block;

                    @include breakpoint($md) {
                        display: none;
                    }
                }
            }
        }
    }
}

.theme--compact {
    &.group {
        .group-name {
            line-break: anywhere;
        }

        &__services {
            border: none;

            .service-name-label {
                font-weight: 700;
            }

            .label {
                font-weight: 700;
            }
        }
    }

    .group__services__headers--few-cols {
        .header {
            width: 100%;
        }

        @include breakpoint($md) {
            .labels .label {
                min-width: 100px;
                max-width: 100px;
            }
        }

    }
}
</style>
