import i18n from '@/i18n'

export function dateAsISOTime(date) {
    return date.toISOString()
}

export function dateFrom(date = new Date(Date.now())) {
    date.setDate(date.getDate() - 6);

    return date;
}

export function dateTo(date = new Date(Date.now())) {
    return date;
}

export function setLocale(languages) {
    const locale = $cookies.get('locale')

    if (locale && languages.available.indexOf(locale) !== -1) {
        i18n.global.locale = $cookies.get('locale')
    } else {
        $cookies.remove('locale')
        i18n.global.locale = languages.default
    }
}
