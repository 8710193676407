import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
    maintenances: {},
    currentMaintenanceIds: [],
    futureMaintenanceIds: [],
    calendarMaintenances: {
        days: {},
        future_events_count: 0
    },
    date: new Date()
};

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true
}
