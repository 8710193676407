<template>
    <div class="page-404">
        <img src="/img/404-o.png" class="page-404__image" />
        <div id="main" class="page-404__container">
            <h2>{{$t('page404.header')}}</h2>
            <p
                class="page-404__text"
                :class="{ 'page-404__text--subscribers': onSubscribers }"
                v-html="message"
            ></p>
            <div v-if="configLoaded" class="page-404__button-wrapper">
                <Button
                    class="page-404__button-back"
                    @click="onBackClick"
                >
                    {{ $t('subscriptions.back-to-status-page') }}
                </Button>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import Button from '@/components/Button.vue'

export default {
    components: {
        Button
    },
    computed: {
        ...mapGetters(['configLoaded']),
        onSubscribers() {
            return [
                'subscribers',
                'subscribers-new',
                'subscribers-with-token-new',
                'subscribers-edit',
                'subscribers-unsub']
                .indexOf(this.$route.name) !== -1
        },
        message() {
            if (this.onSubscribers) {
                return this.$t('page404.messages.subscription-not-found')
            } else {
                return this.$t('page404.messages.default')
            }
        }
    },
    methods: {
        ...mapActions(['set404']),
        onBackClick() {
            this.$router.push({ path: '/' })
            this.$watch('$route', () => {
                this.set404(false)
            })
        }
    }
}
</script>
<style lang="scss" scoped>
@import '../subscriptions/styles';

.page-404 {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 0.75rem;
    margin: 0 auto;
    position: relative;
    height: 100%;
    min-height: 100vh;

    @include breakpoint($lg) {
        font-size: 1.1875rem;
    }

    &__container {
        max-width: 700px;
        padding: 0 16px;
    }

    &__image {
        width: 100%;
        padding: 36px 0;

        @include breakpoint($lg) {
            width: $screen-lg-min;
        }
    }

    &__text {
        text-align: center;

        @include breakpoint($lg) {
            width: 600px;
        }

        ::v-deep(a) {
            color: #475AD1;
            text-decoration: none
        }

        &--subscribers {
            text-align: left;
        }
    }

    &__button-wrapper {
        display: flex;
        justify-content: center;
        padding: 24px 0 42px;
    }

    &__button-back {
        @include nav-button();

        min-width: 170px;
        min-height: 36px;
        cursor: pointer;
    }
}
</style>
