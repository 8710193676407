<template>
    <div class="container">
        <footer class="technion-footer">
            <div class="technion-footer__col technion-footer__col--logo">
                <img src="https://s3.amazonaws.com/statushub/customers/technion/images/technion_logo_heb.png">
            </div>
            <div class="technion-footer__col technion-footer__col--content">
                <div class="standarad-footer-site-links">
                    <ul class="technion-links">
                        <li>
                            <a
                                href="https://www.technion.ac.il/%D7%9E%D7%99%D7%93%D7%A2-%D7%97%D7%99%D7%A8%D7%95%D7%9D/"
                            >
                                Emergency Information
                            </a>
                        </li>
                        <li>
                            <a href="https://hd.technion.ac.il/">Help Desk</a>
                        </li>
                        <li>
                            <a href="https://safety.net.technion.ac.il/">Safety Department</a>
                        </li>
                    </ul>
                </div>
                <div>
                    <ul class="technion-info-list">
                        <li>
                            Call Tech Support <strong class="technion-phone">(+972) 04-829-5600</strong>
                        </li>
                        <li>
                            Visit <strong class="technion-phone"><a href="https://www.technion.ac.il/">www.technion.ac.il</a></strong>
                        </li>
                    </ul>
                </div>
                <div>
                    <ul class="copyright">
                        <li>&#169; Technion Israel Institute of Technology</li>
                    </ul>
                </div>
            </div>
        </footer>
        <div class="powered-by">
            Powered by <a href="https://www.statushub.io/" style="color: white;text-decoration:underline">StatusHub</a>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TechnionFooter'
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 760px){
    footer.technion-footer {
        display: flex;
        flex-direction: column-reverse;
        text-align: center !important;

        ul {
            justify-content: center;
        }
    }
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #A58039;
}

a {
    text-decoration: none;
    color: white;

    &:hover {
        text-decoration: underline;
    }
}

.technion-footer {
    width: 100%;
    max-width: 1040px;
    padding: 20px 0 0;
    font-family: "Gotham SSm A", "Gotham SSm B", arial, sans-serif;
    display: flex;

    &__col--content {
        flex: 1;
        font-size: 0.75rem;
        color: white;
        margin: 0 15px;

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            display: flex;

            li:not(:last-child) {
                padding-right: 5px;
                border-right: 1px solid #444444;
                margin-right: 5px;
            }
        }

        & > div {
            margin-bottom: 10px;
        }
    }

    .technion-links {
        font-size: 1rem;
        margin-bottom: 4px;

        a {
            color: #18453b;
        }
    }
}

.powered-by {
    color: white;
    font-size: 0.75rem;
    margin-bottom: 10px;
}
</style>
