<template>
    <div class="datepicker" :class="{ 'datepicker--loading': loading }">
        <div class="datepicker__header">
            <a href="#"
               class="navigation"
               v-on:click.prevent
               @click="previous"
            >
                <div class="previous"
                     :aria-label="$t('components.datepicker.previous-month')"
                ></div>
            </a>
            <div class="datepicker__header__date">
                <Select
                    class="year-dropdown"
                    :options="years"
                    v-model="year"
                />
                <Select
                    class="month-dropdown"
                    :options="months"
                    v-model="month"
                />
            </div>
            <a href="#"
               class="navigation"
               v-on:click.prevent
               @click="next"
            >
                <div class="next"
                     :aria-label="$t('components.datepicker.next-month')"
                >
                </div>
            </a>
        </div>
        <div class="datepicker__month">
            <table class="datepicker__month__content">
                <tr class="datepicker__month__header">
                    <th
                        scope="col"
                        v-for="(label, i) in headers()"
                        :key="i"
                        class="label"
                    >
                        {{ label }}
                    </th>
                </tr>
                <tr v-for="(week, w) in grid" :key="w">
                    <td v-for="(day, d) in week"
                        :key="d" class="day"
                        :class="{
                            'day--event': day.events.length,
                            'day--today': day.today,
                            'day--current': day.currentMonth,
                            'day--past': day.past
                        }"
                        v-bind:role="ariaRole(day)"
                        @click="click(day)"
                    >
                        <slot name="day" v-bind:day="day">
                            <div :class="{ 'circle': day.events.length }">
                                {{ day.day }}
                            </div>
                        </slot>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>
<script>
import Select from '@/components/Select.vue'
import calendar from '@/mixins/calendar'

export default {
    components: {
        Select
    },
    data() {
        return {
            date: new Date(),
            years: Array.from({ length: 23 }, (_, i) => 2017 + i).map(year => year.toString()),
            monthLabels: ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'],
            loading: false
        }
    },
    mixins: [calendar],
    props: {
        isISOWeek: {
            type: Boolean,
            default: true
        },
        today: {
            type: [Object, Date],
            default: () => new Date()
        },
        pastDateFn: {
            type: Function,
            default: (date) => {
                const now = new Date()
                now.setHours(0, 0, 0, 0)

                return now.getTime() > date.getTime()
            }
        },
        events: {
            type: Array,
            default: () => []
        },
        timestampKeyFrom: {
            type: String,
            default: 'date_from'
        },
        timestampKeyTo: {
            type: String,
            default: 'date_to'
        },
        onDateChange: {
            type: Function,
            default: async () => true
        }
    },
    computed: {
        grid() {
            let date = new Date(this.date);
            date.setDate(1)
            let offset;
            if (this.isISOWeek === true) {
                offset = date.getDay() === 0 ? 6 : date.getDay() - 1
            } else {
                offset = date.getDay()
            }
            date = new Date(date.getFullYear(), date.getMonth() + 1, 0)
            const daysCount = date.getDate()
            const weeksCount = Math.floor((offset + daysCount) / 7) + 1

            const isToday = (someDate) => {
                const today = this.today
                return someDate.getDate() === today.getDate() &&
                    someDate.getMonth() === today.getMonth() &&
                    someDate.getFullYear() === today.getFullYear()
            }

            return ((rows) => {
                date = new Date(date)
                date.setDate(1)
                date.setDate(date.getDate() - offset)

                return Array.from({ length: rows }, () => {
                    return Array.from({ length: 7 }, () => {
                        const day = this.events.find(event => event.date === date.toLocaleDateString())
                        const events = day ? day.events : []

                        const result = {
                            currentMonth: this.date.getMonth() === date.getMonth(),
                            today: isToday(date),
                            date: new Date(date),
                            day: date.getDate(),
                            dayID: date.getMonth() + '-' + date.getDate(),
                            events,
                            past: this.pastDateFn(date)
                        };
                        date.setDate(result.day + 1)

                        return result
                    })
                })
            })(weeksCount)
        },
        month: {
            get() {
                return this.months[this.date.getMonth()]
            },
            set(month) {
                this.date.setMonth(this.months.indexOf(month))
                this.date = new Date(this.date)
                this.changeDate(new Date(this.date))
            }
        },
        year: {
            get() {
                return this.date.getFullYear()
            },
            set(year) {
                this.date.setFullYear(year)
                this.date = new Date(this.date)
                this.changeDate(new Date(this.date))
            }
        },
        months() {
            return this.monthLabels.map(m => this.$t(`components.datepicker.months.${m}`))
        }
    },
    methods: {
        addMonths(date, num) {
            const d = date.getDate();

            date.setMonth(date.getMonth() + num);
            if (date.getDate() !== d) {
                date.setDate(0);
            }

            this.date = new Date(date)

            return new Date(date);
        },
        ariaRole(day) {
            if (day.events.length === 0) {
                return 'presentation'
            }
            return null
        },
        next() {
            this.changeDate(this.addMonths(this.date, 1))
        },
        previous() {
            this.changeDate(this.addMonths(this.date, -1))
        },
        click(day) {
            this.$emit('dayClick', day)
        },
        async changeDate(date) {
            this.loading = true
            try {
                await this.onDateChange(date)
                this.$emit('dateChanged', date)
            } catch (e) {
                this.$emit('dateChangeError', e)
            }
            this.loading = false
        }
    }
}
</script>
<style lang="scss" scoped>
.datepicker {
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 6px;
    padding-bottom: 16px;
    transition: all 0.3s;
    overflow: hidden;

    &--loading > *{
        pointer-events: none;
        opacity: 0.5;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        padding: 12px 8px;
        background-color:#F4F7FD;
        align-items: center;

        @include breakpoint($md) {
            padding: 12px;
        }

        &__date {
            display: flex;
            align-items: center;

            .month-dropdown, .year-dropdown {
                appearance: none;
                background: none;
                background-color: #FFF;
                border: none;
                cursor: pointer;
                color: $textColor;
                margin-left: 8px;
                margin-right: 8px;
                text-transform: none;
                width: 100px;

                ::v-deep(.vs__selected) {
                    font-weight: 600;
                    font-size: 0.9375rem;
                }

                ::v-deep(.vs__dropdown-menu) {
                    max-height: 300px;
                }

                ::v-deep(.vs__dropdown-option) {
                    font-weight: 600;
                    font-size: 0.9375rem;
                }
            }

        }

        .navigation {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            width: 36px;
            height: 36px;

            .previous {
                width: 0;
                height: 0;
                border-top: 7px solid transparent;
                border-bottom: 7px solid transparent;
                border-right: 7px solid#191D33;
            }

            .next {
                width: 0;
                height: 0;
                border-top: 7px solid transparent;
                border-bottom: 7px solid transparent;
                border-left: 7px solid#191D33;
            }
        }
    }

    &__month {
        display: flex;
        flex-direction: column;

        &__content {
            width: 100%;
            border-collapse: collapse;

            .day {
                position: relative;
                width: calc(100% / 7);
                position: relative;
                font-size: 0.8125rem;
                font-weight: 500;
                text-align: center;
                transition: all 0.1s;
                height: 48px;
                color: #D3D3D3;

                &:hover {
                    background: $backgroundColor;
                    color: #203F55;
                }

                &--current {
                    color: #203F55;
                }

                &--today {
                    background-color: $backgroundColorDarker;
                }

                &--event {
                    .circle {
                        background-color: $blue;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 36px;
                        width: 36px;
                        border-radius: 50%;
                        margin: auto;
                        color:#fff;
                        line-height: 2.25rem;
                        text-align: center;
                        cursor: pointer;
                    }
                }

                &--past.day--event {
                    .circle {
                        background-color: $textColor;
                    }
                }
            }

            .label {
                font-weight: 500;
                height: 36px;
                width: calc(100% / 7);
                text-align: center;
                color: $textColor2;
                font-size: 0.8125rem;
            }
        }

        &__header {
            th {
                padding-top: 2px;
                padding-bottom: 2px;
            }
        }
    }
}
</style>
