<template>
    <div class="events-list" v-if="anyEventExists">
        <Tabs
            class="events-list__tabs"
            v-model="currentView"
            compact
        >
            <Tab
                :disabled="offline || !existsOngoing"
                :label="$t('summary.events_list.ongoing')"
            >
            </Tab>
            <Tab
                :disabled="offline || !existsPlanned"
                :label="$t('summary.events_list.planned')">
            </Tab>
        </Tabs>
        <OngoingEvents
            v-if="ongoingSelected"
            :incidents="incidents"
            :maintenances="maintenances"
        >
        </OngoingEvents>
        <PlannedEvents
            v-else-if="plannedSelected"
            :maintenances-planned="maintenancesPlanned"
        >
        </PlannedEvents>
    </div>
    <div v-else>
        <div class="no-events">
            <span class="no-events__text">
                {{ $t('summary.notifications.no-events') }}
            </span>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import OngoingEvents
    from '@/views/hub/components/events/events-list/OngoingEvents.vue'
import PlannedEvents
    from '@/views/hub/components/events/events-list/PlannedEvents.vue'
import Tabs from '@/components/Tabs/Tabs.vue'
import Tab from '@/components/Tabs/Tab.vue'

export default {
    name: 'EventsList',
    components: {
        PlannedEvents,
        OngoingEvents,
        Tab,
        Tabs
    },
    data() {
        return {
            manuallySelected: false,
            view: 0
        }
    },
    computed: {
        ...mapGetters({
            incidents: 'incidents/current',
            maintenances: 'maintenances/current',
            maintenancesPlanned: 'maintenances/future',
            offline: 'offlineAttr'
        }),
        anyEventExists() {
            return this.maintenances.length +
                this.incidents.length +
                this.maintenancesPlanned.length > 0
        },
        currentView: {
            get() {
                return this.view
            },
            set(view) {
                this.view = view
                this.manuallySelected = true
            }
        },
        defaultView() {
            return this.existsOngoing ? 0 : 1
        },
        ongoingSelected() {
            return this.view === 0
        },
        plannedSelected() {
            return this.view === 1
        },
        existsOngoing() {
            return this.maintenances.length + this.incidents.length > 0
        },
        existsPlanned() {
            return this.maintenancesPlanned.length > 0
        }
    },
    mounted() {
        this.view = this.defaultView
    },
    watch: {
        existsOngoing() {
            if (this.manuallySelected) {
                return
            }

            this.view = this.defaultView
        }
    }
}
</script>
<style lang="scss" scoped>
.events-list {
    --event-min-height-mobile: 72.5px;
    --event-min-height-desktop: 84.5px;

    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    background-color: #FFFFFF;
    overflow: hidden;
    font-family: Open Sans,ITC Franklin Gothic,Helvetica Neue,helvetica,sans-serif;

    &__tabs {
        box-sizing: border-box;
        background-color: $backgroundColorLight;
        border-bottom: 1px solid $borderColor;
        padding: 0 1.25rem;
        position: relative;

        @include breakpoint($sm) {
            padding: 0 3.5rem;
        }

        :deep(.tabs__items) {
            width: 100%;
            height: 3rem;
            padding-bottom: 0;
            margin: 0;
            justify-content: space-around;

            @include breakpoint($sm) {
                padding: 0 0.5rem;
            }

            li {
                flex-grow: 1;

                @include breakpoint($sm) {
                    flex-grow: 0;
                }
            }

            .tabs__items__item {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-grow: 1;
                height: 100%;

                @include breakpoint($sm) {
                    min-width: 8rem;
                }

                &--active {
                    border-bottom: 3px solid $blue;
                }

                &:hover,
                &--active {
                    padding-top: 9px;

                    @include breakpoint($sm) {
                        padding-top: 16.5px;
                    }
                }

                &:hover:not(.tabs__items__item--active) {
                    border-bottom: 3px solid $textColor;
                }
            }
        }
    }

    :deep(.events) {
        &.events--ongoing,
        *.events--planned__list {
            margin-left: 0.875rem;

            @include breakpoint($sm) {
                margin-left: 1.5rem;
            }
        }

        &.events--ongoing {
            max-height: calc(var(--event-min-height-mobile) * 4);
            overflow-y: auto;

            @include breakpoint($sm) {
                max-height: calc(var(--event-min-height-desktop) * 4);
            }
        }

        .item {
            border-bottom: 1px solid $borderColor;
            padding: 0.875rem 0.875rem 0.875rem 0;
            margin: 0;
            align-items: flex-start;

            @include breakpoint($sm) {
                padding: 1.25rem 1.5rem 1.25rem 0;
            }

            &__title {
                font-size: 0.9375rem;
            }

            .icon {
                width: 1.4375rem;
            }
        }
    }
}

.no-events {
    border-radius: 6px;
    background-color: #F4F7FD;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;

    &__text {
        font-size: 1.0625rem;
        font-weight: 600;
        line-height: 1.4375rem;
        color: $textColor
    }
}
</style>
