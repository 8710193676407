<template>
    <div class="affected-services">
        <span class="affected-services__label">{{ $t('components.affected-services.label') }}</span>
        <div class="affected-services__services">
            <Service
                v-for="(service, index) in statuses"
                :key="index"
                :name="service.label"
                :status="service.status"
            />
        </div>
    </div>
</template>
<script>
import connectedHubsGrid from '@/mixins/connectedHubsGrid';
import Service from './Service.vue';
import { mapGetters } from 'vuex';

export default {
    components: {
        Service
    },
    mixins: [connectedHubsGrid],
    props: {
        services: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        ...mapGetters({
            config: 'config'
        }),
        count() {
            return this.services.length;
        },
        statuses() {
            if (this.config.event_services_list_mode === 0) {
                const [ upCount, affectedCount, downCount ] = [
                    this.services.filter(service => service.status === 1).length,
                    this.services.filter(service => service.status === 2).length,
                    this.services.filter(service => service.status === 3).length
                ]
                return [
                    {
                        count: upCount,
                        label: `${this.$t('summary.details.counters.up')} - ${upCount}`,
                        status: 1
                    }, {
                        count: affectedCount,
                        label: `${this.$t('summary.details.counters.affected')} - ${affectedCount}`,
                        status: 2
                    }, {
                        count: downCount,
                        label: `${this.$t('summary.details.counters.down')} - ${downCount}`,
                        status: 3
                    }
                ].filter(({ count }) => count)
            } else {
                return this.services.map(service => ({
                    label: this.serviceName(service),
                    status: service.status
                }));
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.affected-services {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    flex-wrap: wrap;

    @include breakpoint($md) {
        flex-direction: row;
        align-items: center;
    }

    &__label {
        display: flex;
        justify-content: flex-start;
        font-weight: 400;
        transition: background-color 0.3s;
        padding: 16px 24px;
        color: $textColor2;
        font-size: 0.8125rem;
        padding-right: 0;
        white-space: nowrap;
    }

    &__services {
        display: flex;
        word-break: break-word;
        padding: 16px;
        padding-left: 24px;
        box-sizing: border-box;
        flex-direction: row;
        flex-wrap: wrap;
        max-width: 100%;
    }
}
</style>
