export default {
    setConfig(state, config) {
        state.config = config;
    },
    setSubscriber(state, subscriber) {
        state.subscriber = subscriber;
    },

    setSubscription(state, subscription) {
        state.subscription = subscription;
        state.subscriber.token = subscription.token
    },

    setGroupsWithServices(state, groupsWithServices) {
        state.groupsWithServices = groupsWithServices;
    },

    updateSelectedMethod(state, method) {
        state.method = { ...state.method, ...method };
    },

    deleteMethod(state, type) {
        state.subscription.methods.splice(state.subscription.methods.findIndex(method => method.type === type), 1)
    },

    deleteAllMethods(state) {
        state.subscription.methods = []
    },

    setTerms(state, terms) {
        state.terms = terms
    },

    setEditMode(state, mode) {
        state.editing = mode
    },

    resetSelectedMethod(state) {
        state.method = state.subscriber?.template
            ? { mandatory_service_ids: state.subscriber.template.mandatory_service_ids }
            : null
    },

    setStep(state, step) {
        state.step = step
    },

    setPreviousStep(state) {
        state.previousStep = state.step
    }
}
