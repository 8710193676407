<template>
    <div class="history">
        <div class="header" v-if="showHeader">
            <h2 class="header__text">{{ $t(i18nKey('history.list.label')) }}</h2>
        </div>
        <template v-if="showTabs">
            <Tabs id="history-tabs"
                :calendarID="calendarID"
                v-model="currentView"
                ref="tabs"
            >
                <Tab v-if="isGridView"
                     :disabled="offline"
                     :label="$t(i18nKey('history.view-switcher.current'))"
                >
                    <NowView />
                </Tab>
                <Tab :disabled="offline"
                     :label="$t(i18nKey('history.view-switcher.list-label'))">
                    <ListView />
                </Tab>
                <Tab :disabled="offline"
                     :label="$t(i18nKey('history.view-switcher.calendar-label'))">
                    <CalendarView />
                </Tab>
            </Tabs>
        </template>
        <UptimeView
            v-if="config.uptime_chart"
            :uptime-ranges="config.uptime_ranges"
        />
        <Metrics v-if="config.external_metrics" />
    </div>
</template>

<script>
import CalendarView from './history/Calendar.vue'
import customTranslations from '@/mixins/customTranslations'
import EventBus from '@/event-bus';
import ListView from './history/List.vue'
import NowView from './now/NowView.vue'
import UptimeView from './history/Uptime.vue'
import Metrics from './history/Metrics.vue'
import Tab from '@/components/Tabs/Tab.vue'
import Tabs from '@/components/Tabs/Tabs.vue'
import { mapGetters, mapActions } from 'vuex';

export default {
    components: {
        NowView,
        Tabs,
        Tab,
        ListView,
        CalendarView,
        UptimeView,
        Metrics
    },
    mixins: [customTranslations],
    computed: {
        ...mapGetters({
            config: 'config',
            isGridView: 'isGridView',
            offline: 'offlineAttr',
            view: 'history/view'
        }),
        calendarID() {
            return this.isGridView ? 2 : 1
        },
        currentView: {
            get() {
                return this.view
            },
            set(view) {
                this.setView(view)
            }
        },
        showHeader() {
            return [0, 1].includes(this.config.view_type)
        },
        showTabs() {
            return this.config.view_type !== 2
        }
    },
    methods: {
        ...mapActions({
            setView: 'history/setView'
        }),
        switchToCalendar() {
            this.scrollToCalendar()
        },
        scrollToCalendar() {
            const element = document.getElementById('history-tabs');
            window.scrollTo(0, element.offsetTop);
        }
    },
    mounted() {
        EventBus.$on('switch-to-calendar', () => this.switchToCalendar())
    }
}
</script>
<style lang="scss" scoped>
.history {
    padding-bottom: 48px;
    display: flex;
    flex-direction: column;
    padding-left: 8px;
    padding-right: 8px;
    box-sizing: border-box;
    align-items: center;

    @include breakpoint($lg) {
        padding: 0 calc((100% - #{$screen-lg-min}) / 2 + 48px);
    }

    .header {
        text-align: left;

        &__text {
            color: $textColor2;
            font-size: 1.75rem;
            font-weight: 400;
            margin-block-start: 0.67em;
            margin-block-end: 0.67em;
        }
    }
}
</style>
