import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters({
            config: 'config',
            hubNames: 'connectedHubs/hubNames',
            isGridView: 'isGridView'
        })
    },
    methods: {
        serviceName(service) {
            if (!this.isGridView) {
                return service.service_name
            }

            const hubName = this.hubNames[service.service_id]
            if (hubName === undefined) {
                return service.service_name
            }

            return `${service.service_name} (${hubName})`
        }
    }
}
