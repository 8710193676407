<template>
    <div class="list">
        <div class="list__top">
            <Input
                role="search"
                :short-key="['ctrl', 'f']"
                name="filter"
                input-type="search"
                v-model="filterValue"
                :debounce="500"
                :placeholder="$t('history.list.filter-placeholder')"
                class="search"
                :aria-label="$t('history.list.search-field')"
            />
            <Legend />
        </div>
        <div class="list__groups">
            <template v-if="!loading">
                <Group
                    v-for="(record, index) in records"
                    :key="record.group.group_id"
                    :group="record.group"
                    :labels="record.labels.days"
                />
                <span v-if="!records.length"
                      class="no-results"
                      role="alert"
                >
                    {{ $t('history.list.no-results') }}
                </span>
            </template>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import Group from './list/Group.vue'
import Input from '@/components/Input.vue'
import progress from '@/mixins/progress';
import Legend from '@/components/Legend.vue'

export default {
    name: 'ListView',
    mixins: [progress],
    components: {
        Group,
        Input,
        Legend
    },
    computed: {
        ...mapGetters({
            records: 'history/records',
            filter: 'history/filter'
        }),
        filterValue: {
            get() {
                return this.filter;
            },
            set(value) {
                this.setFilter(value.value);

                this.progress(() => {
                    this.fetchFiltered();
                });
            }
        }
    },
    methods: {
        ...mapActions({
            fetch: 'history/fetch',
            pushMessage: 'messages/push',
            setFilter: 'history/setFilter'
        }),
        async fetchFiltered() {
            try {
                await this.fetch();
            } catch (e) {
                this.pushMessage(this.$t('communicates.errors.fetch-history'))
            }
        }
    },
    async created() {
        await this.fetchFiltered();
    }
}
</script>
<style lang="scss" scoped>
.list {
    width: 100%;
    box-sizing: border-box;

    &__top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;

        @include breakpoint($md) {
            flex-direction: row;
            justify-content: space-between;
        }

        .search {
            min-width: 250px;
        }
    }
}
</style>
