export default {
    authFailed(state) {
        return state.authFailed
    },

    config(state) {
        return state.config;
    },

    configLoaded(state) {
        return state.configLoaded
    },

    customTranslations(state) {
        return state.config.custom_translations || {}
    },
    hubsSwitcher(state) {
        return state.hubsSwitcher || []
    },
    isGridView(state) {
        return state.config?.view_type === 3
    },

    offline(state) {
        return state.offline === true
    },
    offlineAttr(state) {
        return state.offline ? true : null
    },

    pageNotFound(state) {
        return state.pageNotFound
    },

    refreshing(state) {
        return state.refreshing;
    },

    subscriptionsConfig(state) {
        return state.config?.subscriptions || {}
    },
    timeZone(state) {
        return state.timeZone
    }
}
