<template>
    <div class="icon"
         v-if="icon"
         v-html="icon"
    ></div>
    <font-awesome-icon v-else
        :icon="name"
        :size="size"
    />
</template>
<script>

const icons = {
    'incident': `
        <svg width="24"
             height="22"
             viewBox="0 0 24 22"
             version="1.1"
             xmlns="http://www.w3.org/2000/svg"
             xmlns:xlink="http://www.w3.org/1999/xlink"
             xml:space="preserve"
             xmlns:serif="http://www.serif.com/"
             style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"
             aria-labelledby="title desc"
             role="img"
        >
            <title id="title">
                Incident
            </title>
            <desc id="desc">
                Incident triangle icon
            </desc>
            <g transform="matrix(1,0,0,1,3.5001,-0.383075)">
                <path d="M8.8,17.883C7.9,17.883 7.2,17.183 7.2,16.283C7.2,15.383 7.9,14.683 8.8,14.683C9.7,14.683 10.4,15.383 10.4,16.283C10.4,17.183 9.7,17.883 8.8,17.883Z" style="fill:white;"/>
            </g>
            <g transform="matrix(1,0,0,1,3.5001,-0.383075)">
                <path d="M7.3,8.383C7.4,8.283 7.5,8.283 7.6,8.283L9.9,8.283C10,8.283 10.1,8.283 10.2,8.383C10.3,8.483 10.3,8.583 10.3,8.683L10,13.483C10,13.683 9.8,13.883 9.6,13.883L7.9,13.883C7.7,13.883 7.5,13.683 7.5,13.483L7.2,8.683C7.2,8.583 7.2,8.483 7.3,8.383Z" style="fill:white;"/>
            </g>
            <path d="M23.7,18.3L13.7,1C13.3,0.4 12.7,0 12,0C11.3,0 10.6,0.4 10.3,1L0.3,18.3C-0.1,18.9 -0.1,19.7 0.3,20.3C0.7,20.9 1.3,21.3 2,21.3L22,21.3C22.7,21.3 23.4,20.9 23.7,20.3C24.1,19.7 24.1,19 23.7,18.3ZM10.8,8C10.9,7.9 11,7.9 11.1,7.9L13.4,7.9C13.5,7.9 13.6,7.9 13.7,8C13.8,8.1 13.8,8.2 13.8,8.3L13.5,13.1C13.5,13.3 13.3,13.5 13.1,13.5L11.4,13.5C11.2,13.5 11,13.3 11,13.1L10.7,8.3C10.7,8.2 10.7,8.1 10.8,8ZM12.3,17.5C11.4,17.5 10.7,16.8 10.7,15.9C10.7,15 11.4,14.3 12.3,14.3C13.2,14.3 13.9,15 13.9,15.9C13.9,16.8 13.2,17.5 12.3,17.5Z" style="fill:rgb(117,117,117);fill-rule:nonzero;"/>
        </svg>
    `,
    'incident-multiple': `
        <svg width="27"
             height="23"
             viewBox="0 0 27 23"
             version="1.1"
             xmlns="http://www.w3.org/2000/svg"
             xmlns:xlink="http://www.w3.org/1999/xlink"
             xml:space="preserve"
             xmlns:serif="http://www.serif.com/"
             style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"
             aria-labelledby="title desc"
             role="img"
        >
            <title id="title">
                Multiple incidents
            </title>
            <desc id="desc">
                Multiple incident triangle icon
            </desc>
            <g transform="matrix(1,0,0,1,3.02644,-0.251285)">
                <path d="M6.774,8.051C6.774,7.851 6.974,7.651 7.174,7.651L9.074,7.651C9.274,7.651 9.474,7.851 9.374,8.051L9.174,12.151C9.174,12.351 8.974,12.451 8.774,12.451L7.274,12.451C7.074,12.451 6.874,12.251 6.874,12.151L6.774,8.051Z" style="fill:white;"/>
            </g>
            <g transform="matrix(1,0,0,1,3.02644,-0.251285)">
                <path d="M6.774,14.451C6.774,13.651 7.374,13.051 8.174,13.051C8.974,13.051 9.574,13.651 9.574,14.451C9.574,15.251 8.974,15.851 8.174,15.851C7.374,15.851 6.774,15.251 6.774,14.451Z" style="fill:white;"/>
            </g>
            <path d="M26,18L17.9,4C17.7,3.6 17.4,3.3 17,3.1C16.1,2.6 15,2.7 14.2,3.4L13,1.2C12.8,0.8 12.5,0.5 12.1,0.3C11,-0.3 9.5,0.1 8.8,1.2L0.3,16C0.1,16.4 0,16.8 0,17.2C0,18.5 1.1,19.6 2.4,19.6L5,19.6C5,19.7 4.9,19.9 4.9,20C4.9,21.3 6,22.4 7.3,22.4L23.4,22.4C23.9,22.4 24.4,22.3 24.9,22C26.3,21.2 26.8,19.4 26,18ZM9.8,7.8C9.8,7.6 10,7.4 10.2,7.4L12.1,7.4C12.3,7.4 12.5,7.6 12.4,7.8L12.2,11.9C12.2,12.1 12,12.2 11.8,12.2L10.3,12.2C10.1,12.2 9.9,12 9.9,11.9L9.8,7.8ZM9.8,14.2C9.8,13.4 10.4,12.8 11.2,12.8C12,12.8 12.6,13.4 12.6,14.2C12.6,15 12,15.6 11.2,15.6C10.4,15.6 9.8,15 9.8,14.2ZM23.5,21L7.3,21C6.7,21 6.3,20.5 6.3,20C6.3,19.9 6.3,19.7 6.4,19.6L19.4,19.6C19.8,19.6 20.2,19.5 20.6,19.3C21.8,18.6 22.1,17.2 21.5,16L15,4.7C15.1,4.6 15.2,4.4 15.3,4.4C15.8,4.1 16.4,4.3 16.7,4.8L24.8,18.8C24.9,19 25,19.3 25,19.6C25,20.3 24.3,21 23.5,21Z" style="fill:rgb(117,117,117);fill-rule:nonzero;"/>
        </svg>
    `,
    'incident-unconfirmed': `
        <svg width="23"
             height="23"
             viewBox="0 0 23 23"
             version="1.1"
             xmlns="http://www.w3.org/2000/svg"
             xmlns:xlink="http://www.w3.org/1999/xlink"
             xml:space="preserve"
             xmlns:serif="http://www.serif.com/"
             style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"
             aria-labelledby="title desc"
             role="img"
        >
            <title id="title">
                Unconfirmed event
            </title>
            <desc id="desc">
                Unconfirmed event icon
            </desc>
            <g id="g4">
                <path d="M20,0 L3,0 C1.3,0 0,1.3 0,3L0,20C0,21.7 1.3,23 3,23L20,23C21.7,23 23,21.7 23,20L23,3C23,1.3 21.7,0 20,0Z" style="fill:rgb(117,117,117);fill-rule:nonzero;" id="path1" />
                <g transform="matrix(1.274106,-0.03429853,0.01661183,1.5989961,0.96829646,-7.1269754)" id="g2">
                    <path d="m 6.774,8.051 c 0,-0.2 0.2,-0.4 0.4,-0.4 h 1.9 c 0.2,0 0.4,0.2 0.3,0.4 l -0.2,4.1 c 0,0.2 -0.2,0.3 -0.4,0.3 h -1.5 c -0.2,0 -0.4,-0.2 -0.4,-0.3 z" style="fill:#ffffff" id="path2" />
                </g>
                <g transform="matrix(1.2742842,0,0,1.5992197,0.99352438,-7.1807667)" id="g3">
                    <path d="m 6.774,14.451 c 0,-0.8 0.6,-1.4 1.4,-1.4 0.8,0 1.4,0.6 1.4,1.4 0,0.8 -0.6,1.4 -1.4,1.4 -0.8,0 -1.4,-0.6 -1.4,-1.4 z" style="fill:#ffffff" id="path3" />
                </g>
            </g>
        </svg>
    `,
    'maintenance': `
        <svg width="23"
             height="23"
             viewBox="0 0 23 23"
             version="1.1"
             xmlns="http://www.w3.org/2000/svg"
             xmlns:xlink="http://www.w3.org/1999/xlink"
             xml:space="preserve"
             xmlns:serif="http://www.serif.com/"
             style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"
             aria-labelledby="title desc"
             role="img"
        >
            <title id="title">
                Maintenance
            </title>
            <desc id="desc">
                Maintenance wrench icon
            </desc>
            <g>
                <path d="M20,0L3,0C1.3,0 0,1.3 0,3L0,20C0,21.7 1.3,23 3,23L20,23C21.7,23 23,21.7 23,20L23,3C23,1.3 21.7,0 20,0ZM17.3,11.6C16.5,12.4 15.5,12.8 14.4,12.8L14,12.8C13.7,12.8 13.4,12.9 13.2,13.1L7.8,18.5C7.4,18.9 6.8,19.2 6.2,19.2C5.6,19.2 5,19 4.6,18.5C4.2,18.1 3.9,17.5 3.9,16.9C3.9,16.3 4.1,15.7 4.6,15.3L10,9.9C10.2,9.7 10.4,9.3 10.3,9C10.2,7.8 10.6,6.6 11.5,5.7C12.3,4.9 13.3,4.5 14.4,4.5C15.1,4.5 15.9,4.7 16.6,5L13.9,7.7C13.9,8 14,8.2 14.2,8.5C14.5,8.9 14.9,9.1 15.3,9.2L18,6.5C18.3,7.2 18.5,7.9 18.5,8.6C18.5,9.8 18.1,10.8 17.3,11.6Z" style="fill:rgb(0,112,240);fill-rule:nonzero;"/>
                <path d="M17.3,11.6C16.5,12.4 15.5,12.8 14.4,12.8L14,12.8C13.7,12.8 13.4,12.9 13.2,13.1L7.8,18.5C7.4,18.9 6.8,19.2 6.2,19.2C5.6,19.2 5,19 4.6,18.5C4.2,18.1 3.9,17.5 3.9,16.9C3.9,16.3 4.1,15.7 4.6,15.3L10,9.9C10.2,9.7 10.4,9.3 10.3,9C10.2,7.8 10.6,6.6 11.5,5.7C12.3,4.9 13.3,4.5 14.4,4.5C15.1,4.5 15.9,4.7 16.6,5L13.9,7.7C13.9,8 14,8.2 14.2,8.5C14.5,8.9 14.9,9.1 15.3,9.2L18,6.5C18.3,7.2 18.5,7.9 18.5,8.6C18.5,9.8 18.1,10.8 17.3,11.6Z" style="fill:white;"/>
                <circle cx="6" cy="17" r="1" style="fill:rgb(0,112,240);"/>
            </g>
        </svg>
    `,
    'type-identified': `
        <svg version="1.1"
             id="Layer_1"
             xmlns="http://www.w3.org/2000/svg"
             xmlns:xlink="http://www.w3.org/1999/xlink"
             x="0px"
             y="0px"
             width="19.9"
             height="19.9"
             viewBox="0 0 19.9 19.9"
             style="enable-background:new 0 0 19.9 19.9;"
             xml:space="preserve"
             aria-labelledby="title desc"
             role="img"
        >
            <title id="title">
                Identified
            </title>
            <desc id="desc">
                Identified incident icon
            </desc>
            <style type="text/css">
                .svg-type-identified-0{fill:#757575;}
            </style>
            <g>
                <path class="svg-type-identified-0" d="M19.1,9.2h-0.9c-0.4-3.9-3.5-7-7.4-7.4V0.8C10.8,0.4,10.4,0,10,0S9.2,0.4,9.2,0.8v0.9c-3.9,0.4-7,3.5-7.4,7.4 H0.8C0.4,9.2,0,9.5,0,10s0.4,0.8,0.8,0.8h0.9c0.4,3.9,3.5,7,7.4,7.4v0.9c0,0.4,0.4,0.8,0.8,0.8s0.8-0.4,0.8-0.8v-0.9 c3.9-0.4,7-3.5,7.4-7.4h0.9c0.4,0,0.8-0.4,0.8-0.8S19.6,9.2,19.1,9.2z M14.3,10.8h2.3c-0.4,3-2.8,5.4-5.8,5.8v-2.3 c0-0.4-0.4-0.8-0.8-0.8s-0.8,0.4-0.8,0.8v2.3c-3-0.4-5.4-2.8-5.8-5.8h2.3c0.4,0,0.8-0.4,0.8-0.8S6,9.2,5.6,9.2H3.4 c0.4-3,2.8-5.4,5.8-5.8v2.3C9.2,6,9.5,6.4,10,6.4s0.8-0.4,0.8-0.8V3.4c3,0.4,5.4,2.8,5.8,5.8h-2.3c-0.4,0-0.8,0.4-0.8,0.8 S13.9,10.8,14.3,10.8z"/>
                <path class="st0" d="M10,8.7c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3S10.7,8.7,10,8.7z"/>
            </g>
        </svg>
    `,
    'type-investigating': `
        <svg version="1.1"
             id="Layer_1"
             xmlns="http://www.w3.org/2000/svg"
             xmlns:xlink="http://www.w3.org/1999/xlink"
             x="0px"
             y="0px"
             width="17.8"
             height="17.8"
             viewBox="0 0 17.8 17.8"
             style="enable-background:new 0 0 17.8 17.8;"
             xml:space="preserve"
             aria-labelledby="title desc"
             role="img"
        >
            <title id="title">
              Investigating
            </title>
            <desc id="desc">
              Incident during investigation icon
            </desc>
            <style type="text/css">
                .svg-type-investigating-0{fill:#757575;}
            </style>
            <g>
                <path class="svg-type-investigating-0" d="M17.2,16.1l-3.3-3.3c1.1-1.4,1.8-3.1,1.8-5c0-4.3-3.5-7.9-7.9-7.9C3.5,0,0,3.5,0,7.9c0,4.3,3.5,7.9,7.9,7.9 c1.9,0,3.6-0.7,5-1.8l3.3,3.3c0.2,0.2,0.4,0.2,0.6,0.2s0.4-0.1,0.6-0.2C17.5,16.9,17.5,16.4,17.2,16.1z M7.9,14.1 c-3.4,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3S11.3,14.1,7.9,14.1z"/>
                <path class="st0" d="M5.3,7.3C4.5,7.3,3.7,8,3.7,8.9s0.7,1.6,1.6,1.6c0.9,0,1.6-0.7,1.6-1.6S6.2,7.3,5.3,7.3z"/>
            </g>
        </svg>
    `,
    'type-monitoring': `
        <svg version="1.1"
             id="Layer_1"
             xmlns="http://www.w3.org/2000/svg"
             xmlns:xlink="http://www.w3.org/1999/xlink"
             x="0px"
             y="0px"
             width="21.8"
             height="14.1"
             viewBox="0 0 21.8 14.1"
             style="enable-background:new 0 0 21.8 14.1;"
             xml:space="preserve"
             aria-labelledby="title desc"
             role="img"
        >
            <title id="title">
                Monitoring
            </title>
            <desc id="desc">
                Incident under monitoring icon
            </desc>
            <style type="text/css">
                .svg-type-monitoring-0{fill:#757575;}
            </style>
            <g id="Monitoring" transform="translate(-26.2 -186.051)">
                <path class="svg-type-monitoring-0" d="M35.9,200.2c-0.3,0-0.5-0.1-0.7-0.4l-2.6-4.3H27c-0.4,0-0.8-0.4-0.8-0.8s0.4-0.8,0.8-0.8h6 c0.3,0,0.5,0.1,0.7,0.4l1.8,3l2.5-10.6c0.1-0.4,0.4-0.6,0.8-0.6c0,0,0,0,0,0c0.4,0,0.7,0.3,0.8,0.6l2.6,10.6l1.7-3 c0.1-0.2,0.4-0.4,0.7-0.4h2.6c0.4,0,0.8,0.4,0.8,0.8s-0.4,0.8-0.8,0.8h-2.1l-2.5,4.3c-0.2,0.3-0.5,0.4-0.8,0.4 c-0.3,0-0.6-0.3-0.7-0.6l-2.3-9.3l-2.2,9.3c-0.1,0.3-0.3,0.6-0.7,0.6C35.9,200.2,35.9,200.2,35.9,200.2z"/>
            </g>
        </svg>
    `,
    'type-resolved': `
        <svg version="1.1"
             id="Layer_1"
             xmlns="http://www.w3.org/2000/svg"
             xmlns:xlink="http://www.w3.org/1999/xlink"
             x="0px"
             y="0px"
             width="17.5"
             height="12.8"
             viewBox="0 0 17.5 12.8"
             style="enable-background:new 0 0 17.5 12.8;"
             xml:space="preserve"
             aria-labelledby="title desc"
             role="img"
        >
            <title id="title">
                Resolved
            </title>
            <desc id="desc">
                Resolved incident icon
            </desc>
            <style type="text/css">
                .svg-type-resolved-0{fill:#757575;}
            </style>
            <g id="Resolved" transform="translate(-30.536 -112.519)">
                <path class="svg-type-resolved-0" d="M36.1,125.3c-0.2,0-0.5-0.1-0.6-0.3l-4.3-4.3c-0.4-0.4-0.4-0.9,0-1.3c0.4-0.4,0.9-0.4,1.3,0l3.6,3.6l10-10 c0.4-0.4,0.9-0.4,1.3,0c0.4,0.4,0.4,0.9,0,1.3l-10.6,10.6C36.5,125.2,36.3,125.3,36.1,125.3z"/>
            </g>
        </svg>
    `,
    'arrow-left': `
        <svg viewBox="0 0 16 14"
             width="16"
             height="14"
             version="1.1"
             xmlns="http://www.w3.org/2000/svg"
             xmlns:xlink="http://www.w3.org/1999/xlink"
             aria-labelledby="title desc"
             role="img"
        >
            <title id="title">
                Left arrow
            </title>
            <desc id="desc">
                Arrow pointing to the left
            </desc>
            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="v32" transform="translate(-35.000000, -92.000000)">
                    <g id="Right-5" transform="translate(43.000000, 99.000000) scale(-1, 1) translate(-43.000000, -99.000000) translate(35.000000, 91.000000)">
                        <g id="Right-4">
                            <g id="Right-3">
                                <rect id="Rectangle" x="0" y="0" width="16" height="16"></rect>
                                <path d="M1,8 L14.5,8" id="Stroke" stroke="#404853" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                <polyline id="Stroke" stroke="#404853" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" points="10 2.5 15 8 10 13.5"></polyline>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>`,
    'phone': `
        <svg width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg" aria-labelledby="title desc" role="img">
            <rect x="13" y="1" width="18" height="12" rx="2" transform="rotate(90 13 1)" stroke="#0070F0" stroke-width="1.5" fill="none"></rect>
            <path d="M5 16H9" stroke="#0070F0" stroke-width="1.5" stroke-linecap="round" data-type="line"></path>
        </svg>
    `,
    'envelope': `
        <svg width="17px"
             height="12px"
             viewBox="0 0 17 12"
             version="1.1"
             xmlns="http://www.w3.org/2000/svg"
             xmlns:xlink="http://www.w3.org/1999/xlink"
             aria-labelledby="title desc"
             role="img"
        >
            <title id="title">
                Envelope
            </title>
            <desc id="desc">
                Envelope icon representing email
            </desc>
            <g id="Work-In-progress" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Subscription1" transform="translate(-419.000000, -382.000000)" fill="#0070F0" fill-rule="nonzero">
                    <g id="envelope" transform="translate(419.000000, 382.000000)">
                        <path d="M0,1.8735031 C0.000409538511,1.37602715 0.204491972,0.899100972 0.567318805,0.547719283 C0.930145638,0.196337595 1.42197193,-0.000692953112 1.93452273,3.67224387e-06 L15.0654773,3.67224387e-06 C16.1326136,3.67224387e-06 17,0.840753414 17,1.8735031 L17,10.1265006 C16.9995905,10.6239765 16.795508,11.1009027 16.4326812,11.4522844 C16.0698544,11.8036661 15.5780281,12.0006966 15.0654773,12 L1.93452273,12 C0.867386364,12 0,11.1592502 0,10.1265006 L0,1.8735031 Z M15.4545455,4.69912723 L8.89795455,8.51812606 C8.65305216,8.66093584 8.34694784,8.66093584 8.10204545,8.51812606 L1.54545455,4.69875223 L1.54545455,10.1261256 C1.54545455,10.3297505 1.72047727,10.4996255 1.93452273,10.4996255 L15.0654773,10.4996255 C15.1680811,10.5003238 15.2667573,10.4613807 15.3397445,10.3913844 C15.4127317,10.321388 15.4540349,10.2260878 15.4545455,10.1265006 L15.4545455,4.69875223 L15.4545455,4.69912723 Z M14.8352045,3.31050266 C15.1531818,3.12525271 15.4545455,2.60925287 15.4545455,2.25262798 L15.4545455,1.8738781 C15.4545464,1.66783124 15.2827898,1.50062321 15.0705,1.50000321 L1.9295,1.50000321 C1.7172102,1.50062321 1.54545358,1.66783124 1.54545455,1.8738781 L1.54545455,2.25262798 C1.54545455,2.60737787 1.84875,3.12637771 2.16479545,3.31012766 L8.5,7.00050152 L14.8352045,3.31050266 Z" id="Shape"></path>
                    </g>
                </g>
            </g>
        </svg>
    `,
    'slack': `
        <svg width="22px"
             height="22px"
             viewBox="0 0 15 15"
             version="1.1"
             xmlns="http://www.w3.org/2000/svg"
             xmlns:xlink="http://www.w3.org/1999/xlink"
             aria-labelledby="title desc"
             role="img"
        >
            <title id="title">
                Slack
            </title>
            <desc id="desc">
                Slack service icon
            </desc>
            <g id="Work-In-progress" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Subscription1" transform="translate(-416.000000, -431.000000)" fill-rule="nonzero">
                    <g id="Slack_Mark" transform="translate(416.000000, 431.000000)">
                        <g id="Group" transform="translate(0.000000, 8.000000)" fill="#E01E5A">
                            <path d="M3.13220339,1.56610169 C3.13220339,2.40847458 2.4440678,3.09661017 1.60169492,3.09661017 C0.759322034,3.09661017 0.0711864407,2.40847458 0.0711864407,1.56610169 C0.0711864407,0.723728814 0.759322034,0.0355932203 1.60169492,0.0355932203 L3.13220339,0.0355932203 L3.13220339,1.56610169 Z" id="Path"></path>
                            <path d="M3.90338983,1.56610169 C3.90338983,0.723728814 4.59152542,0.0355932203 5.43389831,0.0355932203 C6.27627119,0.0355932203 6.96440678,0.723728814 6.96440678,1.56610169 L6.96440678,5.39830508 C6.96440678,6.24067797 6.27627119,6.92881356 5.43389831,6.92881356 C4.59152542,6.92881356 3.90338983,6.24067797 3.90338983,5.39830508 L3.90338983,1.56610169 Z" id="Path"></path>
                        </g>
                        <g id="Group" fill="#36C5F0">
                            <path d="M5.43389831,3.13220339 C4.59152542,3.13220339 3.90338983,2.4440678 3.90338983,1.60169492 C3.90338983,0.759322034 4.59152542,0.0711864407 5.43389831,0.0711864407 C6.27627119,0.0711864407 6.96440678,0.759322034 6.96440678,1.60169492 L6.96440678,3.13220339 L5.43389831,3.13220339 Z" id="Path"></path>
                            <path d="M5.43389831,3.90338983 C6.27627119,3.90338983 6.96440678,4.59152542 6.96440678,5.43389831 C6.96440678,6.27627119 6.27627119,6.96440678 5.43389831,6.96440678 L1.60169492,6.96440678 C0.759322034,6.96440678 0.0711864407,6.27627119 0.0711864407,5.43389831 C0.0711864407,4.59152542 0.759322034,3.90338983 1.60169492,3.90338983 L5.43389831,3.90338983 Z" id="Path"></path>
                        </g>
                        <g id="Group" transform="translate(8.000000, 0.000000)" fill="#2EB67D">
                            <path d="M3.86779661,5.43389831 C3.86779661,4.59152542 4.5559322,3.90338983 5.39830508,3.90338983 C6.24067797,3.90338983 6.92881356,4.59152542 6.92881356,5.43389831 C6.92881356,6.27627119 6.24067797,6.96440678 5.39830508,6.96440678 L3.86779661,6.96440678 L3.86779661,5.43389831 Z" id="Path"></path>
                            <path d="M3.09661017,5.43389831 C3.09661017,6.27627119 2.40847458,6.96440678 1.56610169,6.96440678 C0.723728814,6.96440678 0.0355932203,6.27627119 0.0355932203,5.43389831 L0.0355932203,1.60169492 C0.0355932203,0.759322034 0.723728814,0.0711864407 1.56610169,0.0711864407 C2.40847458,0.0711864407 3.09661017,0.759322034 3.09661017,1.60169492 L3.09661017,5.43389831 Z" id="Path"></path>
                        </g>
                        <g id="Group" transform="translate(8.000000, 8.000000)" fill="#ECB22E">
                            <path d="M1.56610169,3.86779661 C2.40847458,3.86779661 3.09661017,4.5559322 3.09661017,5.39830508 C3.09661017,6.24067797 2.40847458,6.92881356 1.56610169,6.92881356 C0.723728814,6.92881356 0.0355932203,6.24067797 0.0355932203,5.39830508 L0.0355932203,3.86779661 L1.56610169,3.86779661 Z" id="Path"></path>
                            <path d="M1.56610169,3.09661017 C0.723728814,3.09661017 0.0355932203,2.40847458 0.0355932203,1.56610169 C0.0355932203,0.723728814 0.723728814,0.0355932203 1.56610169,0.0355932203 L5.39830508,0.0355932203 C6.24067797,0.0355932203 6.92881356,0.723728814 6.92881356,1.56610169 C6.92881356,2.40847458 6.24067797,3.09661017 5.39830508,3.09661017 L1.56610169,3.09661017 Z" id="Path"></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    `,
    'people': `
        <svg width="24px"
             height="22px"
             viewBox="0 0 2229 2074"
             version="1.1"
             xmlns="http://www.w3.org/2000/svg"
             xmlns:xlink="http://www.w3.org/1999/xlink"
             aria-labelledby="title desc"
             role="img"
        >
            <title id="title">
                People
            </title>
            <desc id="desc">
                People icon representing MS Teams service
            </desc>
            <defs>
                <linearGradient x1="17.372046%" y1="-6.51032331%" x2="82.6280767%" y2="106.510437%" id="linearGradient-1">
                    <stop stop-color="#5A62C3" offset="0%"></stop>
                    <stop stop-color="#4D55BD" offset="50%"></stop>
                    <stop stop-color="#3940AB" offset="100%"></stop>
                </linearGradient>
            </defs>
            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="MS-teams-logo" fill-rule="nonzero">
                        <path d="M1554.637,777.5 L2130.35,777.5 C2184.741,777.5 2228.833,821.592 2228.833,875.983 C2228.833,875.983 2228.833,875.983 2228.833,875.983 L2228.833,1400.381 C2228.833,1600.282 2066.782,1762.333 1866.881,1762.333 L1866.881,1762.333 L1865.17,1762.333 C1665.269,1762.361 1503.195,1600.333 1503.166,1400.432 C1503.166,1400.415 1503.166,1400.398 1503.166,1400.38 L1503.166,828.971 C1503.166,800.544 1526.211,777.5 1554.637,777.5 L1554.637,777.5 Z" id="Path" fill="#5059C9"></path>
                        <circle id="Oval" fill="#5059C9" cx="1943.75" cy="440.583" r="233.25"></circle>
                        <circle id="Oval" fill="#7B83EB" cx="1218.083" cy="336.917" r="336.917"></circle>
                        <path d="M1667.323,777.5 L717.01,777.5 C663.267,778.83 620.753,823.431 622,877.176 L622,1475.281 C614.495,1797.8 869.657,2065.441 1192.167,2073.334 C1514.677,2065.441 1769.838,1797.8 1762.334,1475.281 L1762.334,877.176 C1763.579,823.431 1721.066,778.83 1667.323,777.5 Z" id="Path" fill="#7B83EB"></path>
                        <path d="M1244,777.5 L1244,1615.645 C1243.742,1654.08 1220.451,1688.609 1184.91,1703.243 C1173.594,1708.03 1161.432,1710.5 1149.145,1710.5 L667.613,1710.5 C660.875,1693.395 654.655,1676.29 649.471,1658.667 C631.327,1599.19 622.069,1537.36 621.999,1475.177 L621.999,877.02 C620.753,823.361 663.197,778.83 716.854,777.5 L1244,777.5 Z" id="Path" fill="#000000" opacity="0.1"></path>
                        <path d="M1192.167,777.5 L1192.167,1667.478 C1192.167,1679.765 1189.697,1691.927 1184.91,1703.243 C1170.276,1738.784 1135.747,1762.076 1097.312,1762.333 L691.975,1762.333 C683.163,1745.228 674.87,1728.123 667.613,1710.5 C660.356,1692.877 654.655,1676.29 649.471,1658.667 C631.327,1599.191 622.069,1537.36 621.999,1475.177 L621.999,877.02 C620.753,823.361 663.197,778.83 716.854,777.5 L1192.167,777.5 Z" id="Path" fill="#000000" opacity="0.2"></path>
                        <path d="M1192.167,777.5 L1192.167,1563.812 C1191.772,1616.035 1149.535,1658.272 1097.312,1658.667 L649.472,1658.667 C631.328,1599.191 622.07,1537.36 622,1475.177 L622,877.02 C620.754,823.361 663.198,778.83 716.855,777.5 L1192.167,777.5 Z" id="Path" fill="#000000" opacity="0.2"></path>
                        <path d="M1140.333,777.5 L1140.333,1563.812 C1139.938,1616.035 1097.701,1658.272 1045.478,1658.667 L649.472,1658.667 C631.328,1599.191 622.07,1537.36 622,1475.177 L622,877.02 C620.754,823.361 663.198,778.83 716.855,777.5 L1140.333,777.5 Z" id="Path" fill="#000000" opacity="0.2"></path>
                        <path d="M1244,509.522 L1244,672.797 C1235.188,673.315 1226.895,673.834 1218.083,673.834 C1209.271,673.834 1200.978,673.316 1192.166,672.797 C1174.67,671.636 1157.318,668.86 1140.333,664.504 C1035.37,639.647 948.654,566.035 907.083,466.501 C899.93,449.786 894.377,432.43 890.496,414.668 L1149.144,414.668 C1201.449,414.866 1243.801,457.217 1244,509.522 Z" id="Path" fill="#000000" opacity="0.1"></path>
                        <path d="M1192.167,561.355 L1192.167,672.797 C1174.671,671.636 1157.319,668.86 1140.334,664.504 C1035.371,639.647 948.655,566.035 907.084,466.501 L1097.312,466.501 C1149.616,466.699 1191.968,509.051 1192.167,561.355 Z" id="Path" fill="#000000" opacity="0.2"></path>
                        <path d="M1192.167,561.355 L1192.167,672.797 C1174.671,671.636 1157.319,668.86 1140.334,664.504 C1035.371,639.647 948.655,566.035 907.084,466.501 L1097.312,466.501 C1149.616,466.699 1191.968,509.051 1192.167,561.355 Z" id="Path" fill="#000000" opacity="0.2"></path>
                        <path d="M1140.333,561.355 L1140.333,664.503 C1035.37,639.646 948.654,566.034 907.083,466.5 L1045.478,466.5 C1097.783,466.699 1140.134,509.051 1140.333,561.355 Z" id="Path" fill="#000000" opacity="0.2"></path>
                        <path d="M95.01,466.5 L1045.322,466.5 C1097.795,466.5 1140.332,509.038 1140.332,561.51 L1140.332,1511.822 C1140.332,1564.295 1097.794,1606.832 1045.322,1606.832 L95.01,1606.832 C42.537,1606.832 0,1564.294 0,1511.822 L0,561.51 C0,509.038 42.538,466.5 95.01,466.5 Z" id="Path" fill="url(#linearGradient-1)"></path>
                        <polygon id="Path" fill="#FFFFFF" points="820.211 828.193 630.241 828.193 630.241 1345.49 509.211 1345.49 509.211 828.193 320.123 828.193 320.123 727.844 820.211 727.844"></polygon>
                    </g>
                </g>
        </svg>`,
    'webhook': `
        <svg xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 48 48"
             width="22px"
             height="22px"
             aria-labelledby="title desc"
             role="img"
        >
            <title id="title">
                Webhook
            </title>
            <desc id="desc">
                Icon representing Webhooks
            </desc>
            <path fill="#37474f" d="M35,37c-2.2,0-4-1.8-4-4s1.8-4,4-4s4,1.8,4,4S37.2,37,35,37z"/>
            <path fill="#37474f" d="M35,43c-3,0-5.9-1.4-7.8-3.7l3.1-2.5c1.1,1.4,2.9,2.3,4.7,2.3c3.3,0,6-2.7,6-6s-2.7-6-6-6 c-1,0-2,0.3-2.9,0.7l-1.7,1L23.3,16l3.5-1.9l5.3,9.4c1-0.3,2-0.5,3-0.5c5.5,0,10,4.5,10,10S40.5,43,35,43z"/>
            <path fill="#37474f" d="M14,43C8.5,43,4,38.5,4,33c0-4.6,3.1-8.5,7.5-9.7l1,3.9C9.9,27.9,8,30.3,8,33c0,3.3,2.7,6,6,6 s6-2.7,6-6v-2h15v4H23.8C22.9,39.6,18.8,43,14,43z"/>
            <path fill="#e91e63" d="M14,37c-2.2,0-4-1.8-4-4s1.8-4,4-4s4,1.8,4,4S16.2,37,14,37z"/>
            <path fill="#37474f" d="M25,19c-2.2,0-4-1.8-4-4s1.8-4,4-4s4,1.8,4,4S27.2,19,25,19z"/>
            <path fill="#e91e63" d="M15.7,34L12.3,32l5.9-9.7c-2-1.9-3.2-4.5-3.2-7.3c0-5.5,4.5-10,10-10c5.5,0,10,4.5,10,10 c0,0.9-0.1,1.7-0.3,2.5l-3.9-1c0.1-0.5,0.2-1,0.2-1.5c0-3.3-2.7-6-6-6s-6,2.7-6,6c0,2.1,1.1,4,2.9,5.1l1.7,1L15.7,34z"/>
        </svg>`,
    'triangle-double': `
        <svg xmlns="http://www.w3.org/2000/svg"
             width="22"
             height="22"
             viewBox="0 0 17 14"
             aria-labelledby="title desc"
             role="img"
        >
            <title id="title">
                Multiple incidents
            </title>
            <desc id="desc">
                Multiple incident double triangle icon
            </desc>
            <g>
                <g>
                    <path d="M11.7 3.4L16.9 12.8C17 13 17 13.3 16.9 13.6 16.8 13.7 16.7 13.8 16.6 13.9 16.4 14 16.3 14 16.1 14L5.9 14C5.7 14 5.6 14 5.4 13.9 5.3 13.8 5.2 13.7 5.1 13.6 5 13.3 5 13 5.1 12.8 5.3 12.4 5.4 12.2 5.5 12 6.8 12 9 12 12 12 13.1 12 14.8 11.6 13.2 8.7 12.7 7.8 11.7 6.1 10.3 3.4 10.3 3.3 10.4 3.2 10.6 3.1 10.7 3 10.8 3 11 3 11.2 3 11.3 3 11.4 3.1 11.6 3.2 11.7 3.3 11.7 3.4Z"/>
                    <path d="M6.9 9.1L6.9 7.8C6.9 7.8 6.8 7.7 6.8 7.7 6.8 7.6 6.7 7.6 6.6 7.6L5.4 7.6C5.3 7.6 5.2 7.6 5.2 7.7 5.2 7.7 5.1 7.8 5.1 7.8L5.1 9.1C5.1 9.2 5.2 9.2 5.2 9.2 5.2 9.3 5.3 9.3 5.4 9.3L6.6 9.3C6.7 9.3 6.8 9.3 6.8 9.2 6.8 9.2 6.9 9.2 6.9 9.1ZM6.8 6.6L7 3.6C7 3.5 6.9 3.5 6.9 3.5 6.8 3.4 6.8 3.4 6.7 3.4L5.3 3.4C5.2 3.4 5.2 3.4 5.1 3.5 5.1 3.5 5 3.5 5 3.6L5.2 6.6C5.2 6.7 5.2 6.7 5.2 6.7 5.3 6.8 5.3 6.8 5.4 6.8L6.6 6.8C6.7 6.8 6.7 6.8 6.8 6.7 6.8 6.7 6.8 6.7 6.8 6.6ZM6.7 0.4L11.9 9.8C12 10 12 10.3 11.9 10.6 11.8 10.7 11.7 10.8 11.6 10.9 11.4 11 11.3 11 11.1 11L0.9 11C0.7 11 0.6 11 0.4 10.9 0.3 10.8 0.2 10.7 0.1 10.6 0 10.3 0 10 0.1 9.8L5.3 0.4C5.3 0.3 5.4 0.2 5.6 0.1 5.7 0 5.8 0 6 0 6.2 0 6.3 0 6.4 0.1 6.6 0.2 6.7 0.3 6.7 0.4Z"/>
                </g>
            </g>
        </svg>`
}
export default {
    data() {
        return {
            icons
        }
    },
    props: {
        name: {
            type: String,
            required: true
        },
        size: {
            type: String,
            default: '1x'
        }
    },
    computed: {
        icon() {
            return this.icons[this.name]
        }
    }
}
</script>
<style lang="scss" scoped>
.icon {
    display: flex;
    align-items: center;

    ::v-deep(svg) {
        fill: inherit !important;
        transition: inherit !important;
    }
}
</style>
