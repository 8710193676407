<template>
    <div
        v-if="groups.length"
        class="statuses"
        :class="{ 'statuses--three-columns': threeColumnsMode }"
    >
        <template v-if="threeColumnsMode">
            <div
                v-for="(column, i) in columns"
                :key="i"
                class="statuses__column"
            >
                <Group
                    v-for="(group, index) in column"
                    :key="group.group_id"
                    :group="group"
                />
            </div>
        </template>
        <template v-else-if="groupByStatus">
            <div
                v-for="(groups, statusI) in groupsByStatus"
                :key="statusI"
                >
                <Group
                    v-for="(group, groupI) in groups"
                    :key="groupI"
                    :group="group"
                />
            </div>
        </template>
        <template v-else>
            <Group
                v-for="(group, i) in groups"
                :key="group.group_id"
                :group="group"
            />
        </template>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Group from './statuses/Group.vue'

export default {
    components: {
        Group
    },
    computed: {
        ...mapGetters({
            config: 'config',
            groups: 'statuses/forGroups'
        }),
        columns() {
            let column = 0
            let columns = [[], [], []]
            let columnSize = Math.ceil(this.groups.length / 3)
            if (columnSize === 0) { return columns }

            const groups = [...this.groups]
            groups.forEach((group, i) => {
                column = Math.floor(i / columnSize)
                columns[column].push(group)
            })
            return columns
        },
        groupByStatus() {
            return this.mode === 2
        },
        groupsByStatus() {
            const statuses = [[], [], []]
            statuses.forEach((statusGroup, i) => {
                let filteredGroups = []
                this.groups.forEach((group, groupI) => {
                    const filtered = group.services.filter((service) => {
                        return service.status === i + 1
                    })
                    filteredGroups.push({
                        ...group,
                        services: filtered
                    })
                })
                statuses[i] = filteredGroups.filter((group) => {
                    return group.services.length > 0
                })
            })
            return statuses.reverse()
        },
        mode() {
            return this.config.small_circles_mode
        },
        threeColumnsMode() {
            return this.mode === 1
        }
    }
}
</script>
<style lang="scss" scoped>
.statuses {
    &--three-columns {
        margin-top: 0px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        @include breakpoint($md) {
            flex-direction: row;
        }
    }

    &__column {
        width: 100%;

        &:not(:last-child) {
            margin-right: 12px;
        }
    }
}
</style>
