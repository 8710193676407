export default {
    records(state) {
        return Object.values(state.history).sort((a, b) => a.order - b.order);
    },

    filter(state) {
        return state.filter;
    },

    view(state, _, rootGetters) {
        if (state.view !== -1) {
            return state.view
        }
        if ($cookies.get('view-type') != undefined) {
            return parseInt($cookies.get('view-type'))
        }

        return rootGetters.config.view_type
    }
}
