<template>
    <div class="calendar">
        <div class="calendar__top">
            <Legend horizontal />
        </div>
        <CalendarWrapper
            v-if="events"
            ref="calendar"
            :events="events"
            :isISOWeek="config.locale.is_iso_week"
            :loading="loading"
            @date-range-change="onDateRangeChange"
        />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { DateTime } from 'luxon'
import Icon from '@/components/Icon.vue'
import StatusDot from '@/components/StatusDot.vue'
import Legend from '@/components/Legend.vue'
import connectedHubsGrid from '@/mixins/connectedHubsGrid';
import progress from '@/mixins/progress'
import EventBus from '@/event-bus'
import CalendarWrapper from '@/components/CalendarWrapper.vue'

export default {
    name: 'CalendarView',
    mixins: [connectedHubsGrid, progress],
    components: {
        CalendarWrapper,
        Icon,
        Legend,
        StatusDot
    },
    data() {
        return {
            displayed: false
        }
    },
    computed: {
        ...mapGetters({
            config: 'config',
            events: 'calendar/events',
            days: 'calendar/days',
            date: 'calendar/date'
        })
    },
    methods: {
        ...mapActions({
            fetch: 'calendar/fetch',
            pushMessage: 'messages/push'
        }),
        async onDateRangeChange (date) {
            try {
                await this.progress(() => this.fetch({ from: date }))
            } catch (e) {
                this.pushMessage(this.$t('communicates.errors.fetch-history'))
            }
        }
    },
    mounted() {
        (async () => {
            const date = new Date()
            const newDate = DateTime.fromJSDate(date)
                .startOf('month')
                .plus({ days: 15 })
            await this.onDateRangeChange(newDate.toJSDate())
            EventBus.$emit('calendarDataMounted')
        })()
    }
}
</script>
<style lang="scss" scoped>
.events {
    padding: 16px;
    padding-top: 32px;
}
.calendar {
    width: 100%;
    box-sizing: border-box;

    ::v-deep(.fc-event) {
        border: none;
        background-color: #f1f1f1;
    }

    &__top {
        display: flex;
        justify-content: center;
    }
}

.event {
    display: flex;
    padding: 4px 4px;
    background-color: rgba(194, 204, 220, 0.5);
    border-radius: 6px;
    transition: background-color .3s;
    cursor: pointer;
    text-decoration: none;
    align-items: center;

    &--past {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    &--between {
        border-radius: 0;
    }

    &--future {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .title {
        margin-left: 4px;
        font-weight: 400;
        font-size: 13px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-decoration: none;
        color: #000;
    }

    &--inPopover {
        background-color: transparent;
        border-bottom: none;

        .title {
            color: $textColor;
            white-space: normal;
            margin-left: 16px;

            &:hover {
                color: $textColor2;
            }
        }
    }

    .maintenance {
        height: 18px;
        width: 18px;
        margin-left: auto;

        &__icon {
            height: 18px;
            width: 18px;
        }
    }
}

.events {
    overflow: auto;
    max-width: 300px;
    max-height: 300px;
}
</style>
