<template>
    <VDropdown class="popover incident-popover"
        @show="fetchAll"
        @hide="onClose"
        @apply-show="onPopoverShow"
    >
        <button ref="button" :aria-label="ariaLabel">
            <Icon
                class="icon"
                :class="{ 'icon--multiple': incidents.length > 1 }"
                :name=iconName
                size="2x"
            />
        </button>
        <template #popper>
            <IncidentsPopover :incidents="incidents" :focus-trap="focusTrap"/>
        </template>
    </VDropdown>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Icon from '@/components/Icon.vue'
import incidentMixin from '@/mixins/incident.js'
import IncidentsPopover from './IncidentsPopover.vue'

export default {
    components: {
        Icon,
        IncidentsPopover
    },
    mixins: [incidentMixin],
    data() {
        return {
            focusTrap: false
        }
    },
    props: {
        ids: {
            type: Array,
            default: () => []
        },
        ariaLabel: {
            type: String,
            default: 'Incident'
        }
    },
    computed: {
        ...mapGetters({
            byId: 'incidents/byId'
        }),
        incidents() {
            return this.ids
                .map(id => this.byId(id))
                .filter(incident => !!incident);
        },
        iconName() {
            if (this.incidents.length === 1) {
                return this.incidentIconName(this.incidents[0])
            } else if (this.incidents.length > 1) {
                return 'incident-multiple'
            }
        }
    },
    methods: {
        ...mapActions({
            fetch: 'incidents/fetch',
            pushMessage: 'messages/push'
        }),
        async fetchAll() {
            try {
                await Promise.all(this.ids.map(id => this.fetch(id)));
            } catch (e) {
                this.pushMessage(this.$t('communicates.errors.fetch-incidents'))
            }
        },
        onClose() {
            if (!this.focusTrap) {
                return
            }

            this.focusTrap = false
            this.$refs.button.focus()
        },
        onPopoverShow() {
            this.focusTrap = true
        }
    }
}
</script>
<style lang="scss" scoped>
.popover {
    display: flex;
    align-items: center;

    button {
        border: none;
        background-color: transparent;
        padding-left: 0px;
        padding-right: 0px;
    }

    .icon {
        cursor: pointer;
        transition: fill 0.3s;
        width: 22px;
        height: 22px;

        &--multiple {
            width: 24px;
            height: 24px;
        }

        @include breakpoint($lg) {
            margin-left: 4px;
        }
    }
}

</style>
