import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
    history: {},
    filter: '',
    view: -1
};

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true
}
