<template>
    <div class="chart--container">
        <Line
            :data="chartData"
            :options="chartOptions"
            :aria-label="ariaLabel"
        />
    </div>
</template>

<script>
import {
    Chart as ChartJS,
    CategoryScale,
    Legend,
    LineElement,
    LinearScale,
    PointElement,
    TimeScale,
    Title,
    Tooltip
} from 'chart.js'
import 'chartjs-adapter-luxon'
import { DateTime } from 'luxon'
import { Line } from 'vue-chartjs'

ChartJS.register(
    CategoryScale,
    Legend,
    LineElement,
    LinearScale,
    PointElement,
    TimeScale,
    Title,
    Tooltip
)

export default {
    components: {
        Line
    },
    props: {
        labels: {
            type: Array,
            default: () => ['']
        },
        data: {
            type: Array,
            default: () => [0]
        },
        range: {
            type: Object,
            default: () => ({ name: 'day' })
        }
    },
    computed: {
        ariaLabel() {
          const periodName = this.$t(`history.uptime.${this.range.name}`)
          return `${this.$t(`history.uptime.chart-aria-label`,
                            { periodName: periodName })}`
        },
        chartData() {
            return {
                labels: this.labels,
                datasets: [{
                    label: 'Uptime Value',
                    lineTension: 0,
                    fill: false,
                    data: this.data,
                    steppedLine: true
                }]
            }
        },
        chartOptions() {
            return {
                animation: false,
                clip: false,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false
                    },
                },
                responsive: true,
                scales: {
                    y: {
                        max: 1,
                        min: 0,
                        ticks: {
                            stepSize: 1,
                            callback: (label) => {
                                switch(label) {
                                    case 1:
                                        return this.$t('history.uptime.labels.up')
                                    default:
                                        return this.$t('history.uptime.labels.down')
                                }
                            }
                        }
                    },
                    x: {
                        type: 'time',
                        time: {
                            displayFormats: {
                                hour: 'ha',
                                day: 'MMM d',
                                month: 'MMM yyyy'
                            },
                            unit: this.unit,
                        },
                        ticks: {
                            source: 'auto',
                            stepSize: this.xAxisStepSize,
                        }
                    }
                },
                stepped: true
            }
        },
        unit() {
            switch (this.range.name) {
                case 'day':
                    return 'hour'
                case 'week':
                    return 'day'
                case 'month':
                    return 'day'
                default:
                    return 'month'
            }
        },
        xAxisStepSize() {
            switch (this.range.name) {
                case 'day':
                    return 3
                case 'week':
                    return 1
                case 'month':
                    return 5
                case 'year':
                    return 2
                default:
                    return 1
            }
        }
    },
    methods: {
        animationDuration() {
            if (window.Cypress) {
                return 0
            } else {
                return 1000
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.chart--container {
    flex-grow: 1;
    margin-top: 16px;

    ::v-deep(canvas) {
        height: 200px !important;
        width: 100% !important;

        @include breakpoint($lg) {
            height: 300px !important;
            width: auto !important;
        }
    }
}
</style>
