export default {
    methods: {
        currentLang() {
            return this.$i18n.locale.split('-')[0].replace('dk', 'da')
        },
        setDocumentLang() {
            document.getElementsByTagName('html')[0].lang =
                this.currentLang()
        }
    }
}
