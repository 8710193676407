import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
    config: {},
    subscription: null,
    subscriber: null,
    groupsWithServices: [],
    method: null,
    editing: false,
    terms: false,
    step: 1,
    previousStep: 1
};

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true
}
