export default {
    setIncident(state, incident) {
        state.incidents[incident.id] = incident
    },

    setCurrentIncidents(state, incidents) {
        state.currentIncidentIds = incidents.map(incident => incident.id);
        incidents.forEach(incident => {
            state.incidents[incident.id] = incident
        })
    }
}
