<template>
    <VDropdown class="popover maintenance-popover"
        @show="fetchAll"
        @hide="onClose"
        @apply-show="onPopoverShow"
    >
        <button ref="button" :aria-label="ariaLabel">
            <Icon class="icon" name="maintenance" />
        </button>
        <template #popper>
            <MaintenancesPopover :maintenances="maintenances" :focus-trap="focusTrap"/>
        </template>
    </VDropdown>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Icon from '@/components/Icon.vue'
import MaintenancesPopover from './MaintenancesPopover.vue'

export default {
    components: {
        Icon,
        MaintenancesPopover
    },
    data() {
        return {
            focusTrap: false
        }
    },
    props: {
        ids: {
            type: Array,
            default: () => []
        },
        ariaLabel: {
            type: String,
            default: 'Maintenance'
        }
    },
    computed: {
        ...mapGetters({
            byId: 'maintenances/byId'
        }),
        maintenances() {
            return this.ids
                .map(id => this.byId(id))
                .filter(maintenance => !!maintenance);
        }
    },
    methods: {
        ...mapActions({
            fetch: 'maintenances/fetch',
            pushMessage: 'messages/push'
        }),
        async fetchAll() {
            try {
                await Promise.all(this.ids.map(id => this.fetch(id)));
            } catch (e) {
                this.pushMessage(this.$t('communicates.errors.fetch-maintenances'))
            }
        },
        onClose() {
            if (!this.focusTrap) {
                return
            }

            this.focusTrap = false
            this.$refs.button.focus()
        },
        onPopoverShow() {
            this.focusTrap = true
        }
    }
}
</script>
<style lang="scss" scoped>
.popover {
    display: flex;
    align-items: center;

    button {
        border: none;
        background-color: transparent;
        padding-left: 0px;
        padding-right: 0px;
    }

    .icon {
        cursor: pointer;
        fill: $maintenance-color;
        transition: fill 0.3s;
        width: 22px;
        height: 22px;

        @include breakpoint($lg) {
            margin-left: 4px;
        }

        &:hover {
            fill: $maintenance-color-hover;
        }
    }
}

</style>
