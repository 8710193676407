import { createI18n } from 'vue-i18n'
import deDE from '@/locales/de-DE'
import dkDK from '@/locales/dk-DK'
import enUS from '@/locales/en-US'
import esES from '@/locales/es-ES'
import frFR from '@/locales/fr-FR'
import noNO from '@/locales/no-NO'
import plPL from '@/locales/pl-PL'
import seSE from '@/locales/se-SE'

const i18n = createI18n({
    locale: 'en-US',
    fallbackLocale: 'en-US',
    messages: {
        'en-US': enUS,
        'de-DE': deDE,
        'dk-DK': dkDK,
        'es-ES': esES,
        'fr-FR': frFR,
        'no-NO': noNO,
        'pl-PL': plPL,
        'se-SE': seSE
    }
})

export default i18n
