<template>
    <div class="events events--planned">
        <div class="events--planned__list">
            <EventShort
                :event="maintenance"
                event-type="maintenance"
                v-for="(maintenance, index) in maintenancesPlanned.slice(0, 3)"
                :key="`${maintenance.id}`"
            >
            </EventShort>
        </div>
        <div
            v-if="maintenancesPlanned.length > 3"
            class="events events--planned events--planned__more"
        >
            <i18n-t
                :keypath="i18nKey('summary.events_list.see-more-in')"
                tag="span"
            >
                <template v-slot:link>
                    <a
                        class="planned-events__more-url"
                        @click="switchToCalendar"
                        v-on:click.prevent
                        href="#"
                    >{{ $t('summary.events_list.see-more-link-text') }}</a>
                </template>
            </i18n-t>
        </div>
    </div>
</template>
<script>
import customTranslations from '@/mixins/customTranslations'
import EventBus from '@/event-bus';
import EventShort from '@/views/hub/components/events/EventShort.vue'

export default {
    name: 'PlannedEvents',
    components: {
        EventShort
    },
    mixins: [customTranslations],
    data() {
        return {
            view: 0
        }
    },
    props: [
        'maintenancesPlanned'
    ],
    methods: {
        switchToCalendar() {
            EventBus.$emit('switch-to-calendar')
        }
    }
}
</script>
<style lang="scss" scoped>
.events.events--planned {
    .item:nth-of-type(3) {
        border-bottom: none;
    }

    &__more {
        border-top: 1px solid $borderColor;
        background-color: $backgroundColorLight;
        color: $textColor2;
        font-weight: 600;
        font-size: 0.875rem;
        text-align: center;
        padding: 0.5rem;

        a {
            color: $textColorBlue;
        }
    }
}
</style>
