export default {
    methods: {
        headers() {
            if (this.isISOWeek === true) {
                return this.$tm('components.calendar.headers')
            } else {
                let dayLabels = this.$tm('components.calendar.headers').slice();
                dayLabels.unshift(dayLabels.pop())
                return dayLabels
            }
        }
    }
}
