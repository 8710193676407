<template>
    <div class="mobile-menu">
        <a class="mobile-menu__link"
           :aria-label="$t('topbar.mobile.menu-button-aria-label')"
           href="#"
           @click="toggle"
           v-on:click.prevent
        >
            <Icon class="mobile-menu__bars" name="bars" size="1x" />
        </a>
        <transition name="slide">
            <div class="mobile-menu__slider" v-if="opened">
                <a class="mobile-menu__close-icon mobile-menu__link"
                   :aria-label="$t('topbar.mobile.close-button-aria-label')"
                   href="#"
                   @click="toggle"
                   v-on:click.prevent
                >
                    <Icon class="mobile-menu__close" name="times" size="2x" />
                </a>
                <ul class="mobile-menu__list">
                     <li class="mobile-menu__list-item">
                        <a class="mobile-menu__link name" @click="redirect">{{ config.name }}</a>
                    </li>
                    <li class="mobile-menu__list-item" v-if="config.navbar_link && config.navbar_link.name">
                       <a
                            target="_blank"
                            class="mobile-menu__link"
                            :href="config.navbar_link.url"
                            :disabled="offline"
                        >{{config.navbar_link.name}}</a>
                    </li>
                    <li class="mobile-menu__list-item" v-if="canSubscribe">
                        <router-link
                            @click.native="toggle"
                            tag="a"
                            class="mobile-menu__link subscribe"
                            :disabled="offline"
                            :to="{ path: '/subscribers' }"
                        >{{ $t('topbar.subscribe') }}</router-link>
                    </li>
                    <li class="mobile-menu__list-item" v-if="canEditSubscription">
                        <router-link
                            @click.native="toggle"
                            tag="a"
                            class="mobile-menu__link edit-subscription"
                            :disabled="offline"
                            :to="{ path: `/subscribers/${token}/edit` }"
                        >{{ $t('topbar.edit-subscription') }}
                        </router-link>
                    </li>
                    <li class="mobile-menu__list-item" v-if="signedIn">
                        <a
                            :disabled="offline"
                            class="mobile-menu__link logout"
                            :href="logoutUrl"
                        >{{ $t('topbar.sign-out') }}</a>
                    </li>
                    <li class="mobile-menu__list-item" v-if="$route.path === '/'">
                        <Timezone class="mobile-menu__timezone" />
                    </li>
                    <li class="mobile-menu__list-item" v-if="config.languages.available && config.languages.available.length > 1">
                        <LanguageSwitcher class="mobile-menu__language-switcher" />
                    </li>
                    <li class="mobile-menu__list-item" v-if="config.hubs_switcher">
                        <HubSwitcher class="mobile-menu__hub-switcher" />
                    </li>
                </ul>
            </div>
        </transition>
    </div>
</template>
<script>
import Icon from '@/components/Icon.vue'
import HubSwitcher from '@/components/HubSwitcher.vue'
import Timezone from '@/components/Timezone.vue'
import LanguageSwitcher from '@/components/LanguageSwitcher.vue'
import { mapGetters } from 'vuex'

export default {
    components: {
        Icon,
        Timezone,
        HubSwitcher,
        LanguageSwitcher
    },
    props: {
        logoutUrl: String,
        token: String,
        canSubscribe: Boolean,
        canEditSubscription: Boolean,
        signedIn: Boolean
    },
    computed: {
        ...mapGetters({
            config: 'config',
            offline: 'offlineAttr'
        })
    },
    data() {
        return {
            opened: false
        }
    },
    methods: {
        toggle() {
            this.opened = !this.opened

            this.opened ?
                document.getElementsByTagName('body')[0].classList.add('scroll-disabled') :
                document.getElementsByTagName('body')[0].classList.remove('scroll-disabled')
        },
        redirect() {
            this.toggle()
            this.$emit('redirect')
        }
    }
}
</script>
<style lang="scss" scoped>
.mobile-menu {
    display: flex;
    align-items: center;

    &__bars {
        font-size: 2.0rem;
        padding-top: 6px;
    }

    &__slider {
        position: fixed;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        z-index: 30;
        background-color: #F1F3F5;
        overflow: auto;
    }

    &__close-icon {
        position: absolute;
        right: 36px;
        top: 36px;
        fill: #000;

        &:active {
            fill: rgba(0,0,0,0.5);
        }
    }

    &__list {
        padding: 0 48px;
        display: flex;
        flex-direction: column;
        margin-top: 96px;
        list-style-type: none;
    }

    &__list-item {
        padding: 24px 0;
    }

    &__link {
        font-size: 1.5rem;
        text-transform: uppercase;
        text-decoration: none;
        color: #1E364A;
        font-weight: 300;
        display: block;

        &[disabled] {
            pointer-events: none;
            opacity: 0.5;
        }
    }

    &__timezone, &__hub-switcher, &__language-switcher {
        width: 100%;
        height: 48px;

        ::v-deep(input) {
            font-size: 0.9375rem;
        }

        ::v-deep(.vs__dropdown-option) {
            font-size: 0.9375rem;
        }

        ::v-deep(.vs__selected) {
            font-size: 0.9375rem;
        }
    }

    .slide-enter-active {
        transition: all .3s ease;
    }
    .slide-leave-active {
        transition: all .3s ease;
    }
    .slide-enter, .slide-leave-to {
        transform: translateX(-100%);
    }
}
</style>
