import Counters from '@/views/hub/components/summary/details/Counters.vue'
import Description from '@/views/hub/components/summary/details/Description.vue'
import Events from '@/views/hub/components/Events.vue'
import EventsList from '@/views/hub/components/events/EventsList.vue'
import History from '@/views/hub/components/History.vue'
import NoEventsBox from '@/views/hub/components/events/NoEventsBox.vue'
import Statuses from '@/views/hub/components/summary/details/Statuses.vue'

export default {
    components: {
        Counters,
        Description,
        Events,
        EventsList,
        History,
        NoEventsBox,
        Statuses
    }
}
