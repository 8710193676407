<template>
    <div class="toast" role="alert">
        <Icon @click.native="close" class="toast__close" name="times" />
        <div class="toast__text" v-html="message"></div>
    </div>
</template>
<script>
import Icon from '@/components/Icon.vue'

export default {
    components: {
        Icon
    },
    props: {
        message: {
            type: String,
            required: true
        }
    },
    methods: {
        close() {
            this.$emit('close');
        }
    },
    mounted() {
        setTimeout(() => this.close(), 5000);
    }
}
</script>
<style lang="scss" scoped>

.toast {
    width: 100%;
    padding: 16px;
    background-color: rgba(0,0,0,0.7);
    box-sizing: border-box;
    box-shadow: 10px 10px 30px 0px rgba(0,0,0,0.75);
    position: relative;
    margin-top: 8px;
    transition: background-color 0.3s;

    &:hover {
        background-color: rgba(0,0,0,0.8);
    }

    &__close {
        color: #fff;
        position: absolute;
        top: 0;
        right: 0;
        opacity: 0.7;
        cursor: pointer;
        padding: 20px;

        &:hover {
            opacity: 1;
        }
    }

    &__text {
        color: #fff;
        padding-right: 32px;
    }
}
</style>
