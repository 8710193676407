<template>
    <div class="metrics">
        <div class="metrics__header">
            <h2 class="header">{{ $t('history.metrics.label') }}</h2>
        </div>
        <div class="metrics__frames">
            <iframe class="frame" :src="source" v-for="source in config.external_metrics" :key="source" />
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    computed: {
        ...mapGetters({
            config: 'config'
        })
    }
}
</script>
<style lang="scss" scoped>
.metrics {
    width: 100%;
    box-sizing: border-box;

    &__header {
        margin-top: 48px;
        margin-bottom: 16px;

        .header {
            font-weight: 500;
            padding-top: 16px;
            padding-bottom: 16px;
            font-size: 1.75rem;
            color: $textColor2;
            margin-block-start: 0;
            margin-block-end: 0;
        }
    }

    &__frames {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .frame {
            border: none;
            flex: 1 0 100%;
            margin-bottom: 32px;
            min-height: 380px;
        }
    }
}
</style>
