<template>
    <label
        class="input-control"
        :data-label="label"
        :data-error="validationVal && isValid"
        :class="{
            'input-control--error': validationVal && !isValid,
            'input-control--label': label,
            'input-control--search': inputType === 'search'
        }"
    >
        <Icon class="icon" v-if="iconName && !modelValue" :name="iconName" />
        <Field
            v-if="validationVal"
            class="input"
            ref="input"
            :aria-label="ariaLabel"
            :autocomplete="autocomplete"
            :disabled="disabled"
            :name="name"
            :placeholder="placeholder"
            :rules="validationVal"
            :type="inputType"
            :validateOnInput="true"
            :value="modelValue"
            @input="update"
            @search="reset"
            v-slot="{ meta }"
        />
        <input
            v-else
            class="input"
            :aria-label="ariaLabel"
            :autocomplete="autocomplete"
            :disabled="disabled"
            :name="name"
            :placeholder="placeholder"
            :type="inputType"
            :value="modelValue"
            @input="update"
            @search="reset"
        />
        <ErrorMessage
            v-if="validationVal && !isValid"
            class="error-message"
            role="alert"
            :name="name"
        />
    </label>
</template>

<script>
import debounce from 'debounce';
import EventBus from '@/event-bus';
import Icon from '@/components/Icon.vue'
import { Field, ErrorMessage } from 'vee-validate'

export default {
    components: {
        ErrorMessage,
        Field,
        Icon
    },
    data() {
        return {
            isValid: true
        }
    },
    inject: {
        additionalErrors: {
            default: []
        }
    },
    props: {
        classes: {
            type: String,
            default: ''
        },
        ariaLabel: {
            type: String
        },
        shortKey: {
            type: Array,
            default: () => []
        },
        debounce: {
            type: Number,
            default: 0
        },
        autocomplete: {
            type: String,
            default: 'on'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        icon: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: ''
        },
        name: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        },
        inputType: {
            type: String,
            default: 'text'
        },
        validation: {
            type: [String, Object],
            default: ''
        },
        validation2: {
            type: [String, Object],
            default: ''
        },
        modelValue: {
            type: String,
            default: ''
        }
    },
    computed: {
        iconName() {
            if (this.inputType === 'search') {
                return 'search'
            }
            return this.icon;
        },
        validationVal() {
            return this.validation || this.validation2
        }
    },
    watch: {
        additionalErrors: {
            deep: true,
            handler(values) {
                if (values.length == 0) {
                    return
                }

                const err = values.find((error) => {
                    return error.field === this.name
                })

                this.isValid = err === undefined
            }
        }
    },
    created() {
        const fn = async (event) => {
            if (this.validationVal) {
                await this.$refs.input.validate()
                this.isValid = this.$refs.input.meta.valid
                this.$emit('update:modelValue', {
                    isValid: this.isValid,
                    value: event.target.value
                })
            } else {
                this.$emit('update:modelValue', {
                    value: event.target.value
                })
            }
        }

        this.update = this.debounce ?
            debounce(fn, this.debounce) :
            fn

        EventBus.$on('languageChanged', async () => {
            if (!this.validationVal) {
                return
            }

            await this.$refs.input.validate()
            this.isValid = this.$refs.input.meta.valid
        })
        EventBus.$on('revalidateForm', async (data) => {
            if (!this.validationVal) {
                return
            }
            if (data?.field !== this.name) {
                return
            }

            if (this.$refs.input) {
                await this.$refs.input.validate()
                this.isValid = this.$refs.input.meta.valid
            }
        })
    },
    methods: {
        reset(event) {
            if (event.target.value === '') {
                this.update(event);
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.input-control {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    height: 40px;
    box-sizing: border-box;

    .input {
        box-sizing: border-box;
        padding-left: 16px;
        padding-right: 16px;
        border: 1px solid#D3D3D3;
        border-radius: 4px;
        font-size: 0.875rem;
        color: $textColor;
        width: 100%;
        height: 100%;
        background-color: transparent;

        &::placeholder {
            color:#D3D3D3;
        }

        &::-ms-clear {
            display: none;
            width: 0;
            height: 0;
        }
    }

    &--search {
        border-radius: 20px;
        background-color: $backgroundColor;

        .input {
            border: none;

            &::placeholder {
                color: $textColor;
            }
        }
    }

    &--label {
        margin-top: 32px;
        position: relative;

        &:before {
            content: attr(data-label);
            top: -1.25rem;
            left: 0;
            position: absolute;
            font-size: 0.8125rem;
            color: $textColor2;
            font-weight: 500;
            padding-bottom: 8px;
        }
    }

    &--error {
        margin-bottom: 32px;
        position: relative;
        border: none;

        .input {
            color: $validationErrorColor !important;
            border: 1px solid $validationErrorColor !important;
            border-radius: 4px;

            &::placeholder {
                color: $validationErrorColor !important;
            }
        }

        .error-message {
            position: absolute;
            padding: 5px 8px;
            color: $validationErrorColor;
            z-index: 1;
            font-size: 0.8125rem;
            height: 18px;
            line-height: 1.125rem;
            white-space: nowrap;
            word-wrap: normal;
            bottom: -30px;
            left: 0;
            border-radius: 4px;
        }
    }

    .icon {
        fill: $textColor2;
        padding-left: 12px;
    }
}
</style>
