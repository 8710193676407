import api from '@/api-client';
import EventBus from '@/event-bus';

export default {
    async fetchServices({ dispatch, commit, rootGetters }) {
        let dataValue
        if (rootGetters.isGridView) {
            const { data } = await api.get('connected_hub_services');
            dataValue = data
        } else {
            dataValue = []
        }
        commit('setServices', dataValue);
        await dispatch('setHubNames')
        EventBus.$emit('new-content-loaded')
        return dataValue
    },

    setHubNames({ commit, getters }) {
        const data = {}
        getters.services.forEach(hub => {
            return hub.service_ids.forEach(service_id => {
                data[service_id] = hub.name
            })
        })
        commit('setHubNames', data)
    }
}
