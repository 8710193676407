<template>
    <div class="list">
        <div class="list__top">
            <Legend horizontal with-n-a/>
        </div>
        <div class="list__groups">
            <template v-if="!loading">
                <NowGroup
                    v-for="(group, index) in groups"
                    :key="group.group_id"
                    :group="group"
                />
                <span v-if="!groups.length"
                    class="no-results"
                >
                    {{ $t('history.list.no-results') }}
                </span>
            </template>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import NowGroup from './NowGroup.vue'
import progress from '@/mixins/progress'
import Legend from '@/components/Legend.vue'

export default {
    name: 'NowView',
    mixins: [progress],
    components: {
        NowGroup,
        Legend
    },
    computed: {
        ...mapGetters({
            groups: 'statuses/groups'
        })
    },
    methods: {
        ...mapActions({
            fetch: 'statuses/fetch',
            fetchConnectedHubServices: 'connectedHubs/fetchServices'
        })
    },
    async created() {
        await this.fetch();
        await this.fetchConnectedHubServices();
    }
}
</script>
<style lang="scss" scoped>
.list {
    width: 100%;
    box-sizing: border-box;

    &__top {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        @include breakpoint($md) {
            flex-direction: row;
            justify-content: center;
        }

        .search {
            min-width: 250px;
        }
    }
}
</style>
