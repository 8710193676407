export default {
    config(state) {
        return state.config;
    },
    subscriber(state) {
        return state.subscriber;
    },

    subscription(state) {
        return state.subscription;
    },

    groupsWithServices(state) {
        return state.groupsWithServices; // with selections
    },

    groupsWithServicesForPreview(_, getters) {
        return subscribedMethod => {
            let records = [];
            const groups = getters.groupsWithServices;

            groups.forEach(group => {
                if (!group.services.length) return;

                group.services.forEach(service => {
                    if (!subscribedMethod || (subscribedMethod.services && !subscribedMethod.services.ids.includes(service.id) && !subscribedMethod.services.all)) return;

                    records = [...records, {
                        serviceName: service.name,
                        groupName: group.name
                    }];
                });
            });

            return records;
        }
    },

    selectedMethod(state) {
        return state.method;
    },

    terms(state) {
        return state.terms
    },

    termsAvailable(_, getters, rootGetters) {
        return !(rootGetters.config.no_tos_checkbox || getters.subscription.methods.length)
    },

    editing(state) {
        return state.editing
    },

    step(state) {
        return state.step
    },

    previousStep(state) {
        return state.previousStep
    }
}
