<template>
    <Select
        class="language-switcher"
        label-key="label"
        :options="languages"
        v-model="selected"
    />
</template>

<script>
import EventBus from '@/event-bus';
import Select from '@/components/Select.vue'
import { mapGetters } from 'vuex'
import locale from '@/mixins/locale';
import { localize } from '@vee-validate/i18n'

export default {
    components: {
        Select
    },
    mixins: [locale],
    data() {
        return {
            language: ''
        }
    },
    computed: {
        ...mapGetters([ 'config' ]),
        languages() {
            return this.config.languages && this.config.languages.available.map(lang => ({
                value: lang,
                label: this.$t(`langs.${lang}`)
            }))
        },
        selected: {
            get() {
                const locale = this.$cookies.get('locale') || this.$i18n.locale || this.$i18n.fallbackLocale;
                return this.language || this.languages.find(option => option.value === locale);
            },
            set(language) {
                this.language = language;
                this.$cookies.set('locale', language.value);
                this.$i18n.locale = language.value
                this.setDocumentLang()
                localize(this.currentLang())
                EventBus.$emit('languageChanged', language.value);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .language-switcher {
        height: 36px;
    }
</style>
