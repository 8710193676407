import api from '@/api-client';
import { dateAsISOTime } from '@/utils';
import EventBus from '@/event-bus';

export default {
    async fetch({ commit }, { from = new Date() } = {}) {
        const { data } = await api
            .get(`calendar?date=${dateAsISOTime(from)}&all_statuses=true`);

        const events = data.events.filter(event => event.length > 0) // to remove in future
        commit('setEvents', events);
        commit('setCurrent', events)
        commit('setDays', data.days)
        commit('setDate', from)
        EventBus.$emit('new-content-loaded')
        return data
    }
}
