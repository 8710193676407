<template>
    <focus-trap v-model:active="isActive">
        <div ref="target" class="content">
            <div v-close-popper ref="close" class="close" tabindex="0" v-on:keydown.enter="onEnterKeydown">
                <Icon ref="closeIcon" class="close-icon" name="times" @click="close" />
            </div>
            <div class="header">
                {{ $t('history.list.group.services.popovers.incidents-history' )}}
            </div>
            <div
                class="incident"
                v-for="(incident, index) in incidents"
                :key="incident.id"
            >
                <div v-if="incident.id" class="title">
                    <router-link
                        ref="headerLink"
                        class="title__link"
                        :to="{ name: 'incident', params: { id: incident.id }}"
                        target="_blank"
                    >
                        {{ incidentTitle(incident) }} - {{ formatTimestamp(incident.timestamp_start) }}
                    </router-link>
                </div>
                <div
                    class="update"
                    v-for="(update, u) in incident.updates"
                    :key="update.id"
                >
                    <div class="update__title">
                        <Icon
                            class="type"
                            :name=incidentTypeIconName(update)
                        />
                        <span class="status">{{ $tm(i18nKey('incident-status'))[update.type] }}</span>
                        <span class="date">&nbsp;- {{ formatTimestamp(update.timestamp) }}</span>
                    </div>
                    <div class="update__message" v-html="update.message"></div>
                    <AffectedServices v-if="showAffectedServices" :services="update.services" />
                </div>
            </div>
        </div>
    </focus-trap>
</template>

<script>
import customTranslations from '@/mixins/customTranslations'
import Icon from '@/components/Icon.vue'
import incident from '@/mixins/incident'
import popover from '@/mixins/popover'
import timeOperations from '@/mixins/timeOperations'
import AffectedServices from '@/components/AffectedServices/AffectedServices.vue'

export default {
    components: {
        AffectedServices,
        Icon
    },
    mixins: [
        customTranslations,
        incident,
        popover,
        timeOperations
    ],
    props: {
        incidents: {
            type: Array,
            default: () => []
        },
        focusTrap: {
            type: Boolean,
            default: false
        },
        showAffectedServices: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        isActive: {
            get() {
                return this.focusTrap
            },
            set(value) {
                if (value) {
                    this.$refs.close.focus()
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/views/events.scss';

.content {
    display: flex;
    flex-direction: column;
    width: 360px;
    max-height: 400px;
    overflow: auto;
    border-radius: 6px;
    margin-bottom: 16px;

    @include breakpoint($md) {
        width: 425px;
    }

    @include breakpoint($lg) {
        width: 500px;
    }

    .header {
        padding: 16px;
        color: $textColor2;
        background-color: $backgroundColor;
        font-weight: 300;

        @include breakpoint($md) {
            padding: 16px 32px;
        }
    }

    .close {
        position: absolute;
        right: 20px;
        top: 18px;

        .close-icon {
            color: silver;
            fill: silver;
            cursor: pointer;
            transition: fill 0.3s;
            font-size: 1.3rem;

            &:hover {
                fill: $textColor2;
            }
        }
    }

    .incident {
        .title {
            padding: 16px;
            text-align: left;
            transition: color 0.3s;
            cursor: pointer;
            overflow: hidden;
            background-color: rgba(244,247,252, 0.4);

            @include breakpoint($md) {
                padding: 16px 32px;
            }

            &__link {
                font-weight: bold;
                font-size: 0.9375rem;
                color: $blue;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .update {
            font-size: 0.8125rem;
            padding: 16px;
            background-color: #fff;
            font-size: 0.8125rem;

            @include breakpoint($md) {
                padding: 16px 32px;
            }

            &__title {
                display: flex;
                align-items: center;
                padding-bottom: 8px;

                .status {
                    font-weight: bold;
                }

                .date {
                    overflow: hidden;
                }

                .type {
                    padding-right: 8px;
                    fill: $gray;
                }
            }

            &__message {
                color: $textColor;
                text-align: left;
                overflow: hidden;

                @include quill-styles(13px);
            }
        }
    }
}
</style>
