<template>
    <button
        :disabled="disabled"
        class="button button--default"
        :class="{
            'button--submit': submit,
            'button--primary': primary,
            'button--content-reverse': contentReverse
        }"
        >
        <slot />
        <div class="icon-container" v-if="icon">
            <Icon class="icon" :name="icon" />
        </div>
    </button>
</template>
<script>
import Icon from '@/components/Icon.vue'

export default {
    components: {
        Icon
    },
    props: {
        icon: {
            type: String,
            default: ''
        },
        submit: {
            type: Boolean,
            default: false
        },
        primary: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        contentReverse: {
            type: Boolean,
            default: false
        }
    }
}
</script>
<style lang="scss" scoped>
.button {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 40px;
    border: none;
    font-family: 'Avenir Next', Avenir, Open Sans,ITC Franklin Gothic,Helvetica Neue,helvetica,sans-serif;
    font-size: 0.875rem;
    background-color: #fff;
    color: $textColor;
    cursor: pointer;
    transition: background-color 0.3s;
    white-space: nowrap;
    position: relative;
    border-radius: 4px;
    padding: 0 48px;
    text-transform: uppercase;
    font-weight: 600;
    max-width: 300px;

    .icon-container {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        height: 100%;
        width: 48px;
        left: 0;
        top: 0;
    }

    &--content-reverse {
        flex-direction: row-reverse;

        .icon-container {
            right: 0;
            left: auto;
        }
    }

    &:hover:not([disabled]) {
        background-color:rgba(252, 252, 252, 0.8);
    }

    &--default {
        background-color: #fff;
        color: $textColor;
        border-color: $textColor;
        border: 1px solid $textColor;

        &:hover:not([disabled]) {
            background-color:rgba(252, 252, 252, 0.8);
        }
    }

    &--submit {
        background-color: $cta;
        color: $ctaText;
        border: none;

        &:hover:not([disabled]) {
            background-color: $cta;
        }
    }

    &--primary {
        background-color: $blue;
        color: #fff;
        border: none;

        &:hover:not([disabled]) {
            background-color: rgb(14, 101, 172);
        }
    }

    &--disabled, &[disabled] {
        box-shadow: none;
        pointer-events: disabled;
        background-color:#DCE0E4;
        cursor: default;
    }
}
</style>
