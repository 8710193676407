import { configure } from 'vee-validate';
import { localize } from '@vee-validate/i18n';
import da from '@/locales/dk-DK.json'
import en from '@/locales/en-US.json'
import es from '@/locales/es-ES.json'
import fr from '@/locales/fr-FR.json'
import no from '@/locales/no-NO.json'
import pl from '@/locales/pl-PL.json'
import se from '@/locales/se-SE.json'

const dictionary = {}
const TRANSLATIONS = [
    { label: 'da', translations: da },
    { label: 'en', translations: en },
    { label: 'es', translations: es },
    { label: 'fr', translations: fr },
    { label: 'no', translations: no },
    { label: 'pl', translations: pl },
    { label: 'se', translations: se }
]

TRANSLATIONS.forEach(lang => {
    dictionary[lang.label] = {
        attributes: {
            phone: lang.translations.subscriptions['field-names'].phone,
            email: lang.translations.subscriptions['field-names'].email,
            url: lang.translations.subscriptions['field-names'].url
        },
        messages: {
            required: field => lang.translations.subscriptions['form-errors']
                .required.replace('{field}', field.name),
            phone: () => lang.translations.subscriptions
                .new['phone-form']['invalid-phone'],
            email: () => lang.translations.subscriptions
                .new['email-form']['invalid-email'],
            url: () => lang.translations.subscriptions['form-errors'].url
        }
    }
})
configure({
  generateMessage: localize(dictionary)
});
