<script setup>
import { ScheduleXCalendar } from '@schedule-x/vue'
import { createCalendar, viewMonthAgenda } from '@schedule-x/calendar?small'
import { createCalendarControlsPlugin } from '@schedule-x/calendar-controls'
import { createEventsServicePlugin } from '@schedule-x/events-service'
import '@/assets/css/schedule-x.css'
import MaintenancesCalendarList from './MaintenancesCalendarList.vue'
import Icon from '@/components/Icon.vue'
import { DateTime, Duration } from 'luxon'
import api from '@/api-client'
import { ref, computed, onMounted } from 'vue'
import EventBus from '@/event-bus'

const ENDPOINT = 'blocks/calendar/v1'

let calendarApp
const calendarControls = createCalendarControlsPlugin()
const eventsServicePlugin = createEventsServicePlugin()
const isCalendarReady = ref(false)

const activeDate = ref(DateTime
    .now({ zone: $cookies.get('timezone') })
    .toFormat('yyyy-MM-dd'))

const events = ref(null)
const formatEvents = (events) => events
    .map((event) => ({
        ...event,
        start: DateTime
            .fromSeconds(event.start)
            .setZone($cookies.get('timezone'))
            .toFormat('yyyy-MM-dd HH:mm'),
        end: DateTime
            .fromSeconds(event.end)
            .setZone($cookies.get('timezone'))
            .toFormat('yyyy-MM-dd HH:mm')
    }))
const fetchEvents = async () => {
    const offset = Duration.fromObject({ days: meta.value.iso_week ? 0 : 1 })
    const { data } = await api.get('blocks/calendar/v1', {
        params: {
            from: DateTime.fromISO(activeDate.value, { zone: $cookies.get('timezone') })
                .startOf('month')
                .startOf('week')
                .minus(offset)
                .startOf('day')
                .toUTC()
                .toISO(),
            to: DateTime.fromISO(activeDate.value, { zone: $cookies.get('timezone') })
                .endOf('month')
                .plus(offset)
                .endOf('week')
                .minus(offset)
                .endOf('day')
                .toUTC()
                .toISO(),
            incidents: false,
            maintenances: true
        }
    })
    events.value = { ...data, data: formatEvents(data.data) }
}

const meta = ref(null)
const fetchMeta = async () => {
    const { data } = await api.get(ENDPOINT)
    meta.value = data.meta
}
const fetchData = async () => {
    if (!meta.value) await fetchMeta()
    await fetchEvents()
}

onMounted(async () => {
    await fetchData()
    calendarApp = createCalendar({
        views: [viewMonthAgenda],
        firstDayOfWeek: meta.value.iso_week ? 1 : 0,
        plugins: [
            calendarControls,
            eventsServicePlugin
        ],
        events: events.value.data,
        callbacks: {
            onClickAgendaDate: switchToListView
        }
    })
    isCalendarReady.value = true
})

const currentMonthLabel = computed(() => {
    if (!activeDate.value) return ''
    return DateTime
        .fromFormat(activeDate.value, 'yyyy-MM-dd')
        .toFormat('LLLL yyyy')
})
const changeMonth = async (shift) => {
    activeDate.value = DateTime
        .fromFormat(activeDate.value, 'yyyy-MM-dd')
        .plus({ months: shift })
        .toFormat('yyyy-MM-dd')
    await fetchEvents()
    calendarControls.setDate(activeDate.value)
    eventsServicePlugin.set(events.value.data)

}
const onPrevMonth = () => changeMonth(-1)
const onNextMonth = () => changeMonth(1)

const currentView = ref('calendar')
const switchToListView = (date) => {
    activeDate.value = date
    calendarControls.setDate(date)
    currentView.value = 'list'
}
const switchToCalendarView = () => {
    currentView.value = 'calendar'
}
const isCalendarView = computed(() => currentView.value === 'calendar')
const isListView = computed(() => currentView.value === 'list')

EventBus.$on('data-refresh', async () => {
    await fetchData()
    eventsServicePlugin.set(events.value.data)
})
</script>

<template>
    <div class="calendar calendar__header">
        <h2 class="calendar__header__text">{{ $t('summary.planned-maintenance.label') }}</h2>
        <button
            v-if="isListView"
            class="calendar__header__back-button"
            @click="switchToCalendarView"
        >
            <Icon name="arrow-left" /> {{ $t('summary.planned-maintenance.back-to-calendar') }}
        </button>
    </div>
    <div class="calendar calendar--maintenances">
        <template v-if="isCalendarView && isCalendarReady">
            <div class="calendar--maintenances__controls">
                <Icon
                    name="chevron-left"
                    aria-label="previous month"
                    data-test-id="maintenances-calendar-prev-month-button"
                    @click="onPrevMonth"
                />
                <p>{{ currentMonthLabel }}</p>
                <Icon
                    name="chevron-right"
                    aria-label="next month"
                    data-test-id="maintenances-calendar-next-month-button"
                    @click="onNextMonth"
                />
            </div>
            <ScheduleXCalendar v-if="isCalendarReady" :calendar-app="calendarApp" />
        </template>
        <template v-if="isListView">
            <MaintenancesCalendarList
                :date="activeDate"
            />
        </template>
    </div>
</template>

<style lang="scss">
.calendar {
    &__header {
        padding: 16px;
        display: flex;
        justify-content: space-between;

        &__text {
            font-weight: 600;
            font-size: 1.125rem;
            color: #29394A;
            margin-block-start: 0;
            margin-block-end: 0;
        }

        &__back-button {
            display: flex;
            align-items: center;
            font-weight: 500;

            .icon {
                font-size: 0.75rem;
                width: 0.75rem;
                margin-right: 0.5rem;
            }
        }
    }
}

.calendar--maintenances {
    &__controls,
    .sx-vue-calendar-wrapper {
        background-color: #FFFFFF;
    }

    .sx__calendar-header,
    .sx__month-agenda-events{
        display: none;
    }

    .sx__month-agenda-day {
        cursor: pointer;
    }

    &__controls {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem 0;
        border-radius: 6px 6px 0 0 ;

        p {
            margin: 0;
            min-width: 9rem;
            text-align: center;
            font-weight: 700;
        }

        svg {
            margin: 0 1rem;
            cursor: pointer;
        }
    }

    .sx-vue-calendar-wrapper {
        border-radius: 0 0 6px 6px;
        overflow: hidden;

        .sx__calendar .sx__view-container {
            border: none;
        }
    }

    .maintenances-calendar-list {
        height: 331px;
    }
}
</style>
