<template>
    <div class="description"
        v-if="data.description.length > 0"
    >
        <div class="description__text"
            v-html="data.description"></div>
    </div>
</template>

<script>
import api from '@/api-client';
import EventBus from '@/event-bus';

export default {
    data() {
        return {
            data: {
                description: ''
            },
            meta: {}
        }
    },
    methods: {
        async get() {
            const { data } = await api.get('blocks/description/v1')
            this.data = data.data
            this.meta = data.meta
        }
    },
    mounted() {
        this.get()
        EventBus.$on('data-refresh', () => this.get())
    }
}
</script>

<style lang="scss" scoped>
@import '@/views/events.scss';

.description {
    background-color: #fff;
    border-radius: 6px;
    box-sizing: border-box;
    height: 100%;
    min-width: 300px;
    padding: 24px;

    &__text, &__text ::v-deep(a) {
        color: $textColor;
        font-size: 0.9375rem;
    }

    &__text {
        @include quill-styles(15px);
    }

    &__text ::v-deep(h1),
            ::v-deep(h2),
            ::v-deep(h3),
            ::v-deep(h4),
            ::v-deep(h5),
            ::v-deep(h6) {
        margin: 0px;
        line-height: 1.42;
    }
}
</style>
