import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters({
            customTranslations: 'customTranslations'
        })
    },
    methods: {
        i18nKey(initialKey) {
            return this.customTranslations[initialKey] || initialKey
        }
    }
}
