import { DateTime, IANAZone } from 'luxon'
import { mapGetters } from 'vuex';
import timezones from '@/timezones.json'
import { findTimeZone, getUTCOffset } from '@protontech/timezone-support/src/index-1970-2038'

const DATE_FORMATS = {
    'long:en:12h': { fmt: 'dd/LL/yyyy hh:mm:ssa', withTZ: true },
    'long:en:24h': { fmt: 'dd/LL/yyyy HH:mm:ss', withTZ: true },
    'long:en-US:12h': { fmt: 'LL/dd/yyyy hh:mm:ssa', withTZ: true },
    'long:en-US:24h': { fmt: 'LL/dd/yyyy HH:mm:ss', withTZ: true },
    'short:en:12h': { fmt: 'dd/LL/yyyy hh:mma', withTZ: true },
    'short:en:24h': { fmt: 'dd/LL/yyyy HH:mm', withTZ: true },
    'short:en-US:12h': { fmt: 'LL/dd/yyyy hh:mma', withTZ: true },
    'short:en-US:24h': { fmt: 'LL/dd/yyyy HH:mm', withTZ: true },
    'date:en:12h': { fmt: 'dd/LL/yyyy', withTZ: false },
    'date:en:24h': { fmt: 'dd/LL/yyyy', withTZ: false },
    'date:en-US:12h': { fmt: 'LL/dd/yyyy', withTZ: false },
    'date:en-US:24h': { fmt: 'LL/dd/yyyy', withTZ: false },
    'time:en:12h': { fmt: 'hh:mma', withTZ: true },
    'time:en:24h': { fmt: 'HH:mm', withTZ: true },
    'time:en-US:12h': { fmt: 'hh:mma', withTZ: true },
    'time:en-US:24h': { fmt: 'HH:mm', withTZ: true }
}

export default {
    computed: {
        ...mapGetters({
                config: 'config',
                timeZone: 'timeZone'
        })
    },
    methods: {
        dateFormat(formatID) {
            const locale = this.config.locale?.name || 'en-US'
            const mode = this.config.locale?.time_format || '12h'
            const formatKey = `${formatID}:${locale}:${mode}`
            return DATE_FORMATS[formatKey]
        },
        dateFromTimestamp(timestamp) {
            if ((timestamp || 0) <= 0) {
                return DateTime.fromSeconds(0)
            }

            return DateTime.fromSeconds(timestamp)
        },
        formatTimestamp(timestamp, formatID = 'long') {
            if ((timestamp || 0) === 0) {
                return ''
            }

            const date = this.dateFromTimestamp(timestamp)
            const format = this.dateFormat(formatID)
            const withoutTZ = date
                .setZone(IANAZone.create(this.timeZone.value))
                .toFormat(format.fmt)
            if(!format.withTZ) {
                return withoutTZ
            }

            const tz = findTimeZone(this.timeZone.value)
            const offset = getUTCOffset(date.toJSDate(), tz)
            return `${withoutTZ} ${offset.abbreviation}`
        },
        timeZoneByName(name, fallback = 'Etc/UTC') {
            return timezones.find(tz => tz.value === name) ||
                timezones.find(tz => tz.value === fallback)
        }
    }
}
