<script setup>
import { ScheduleXCalendar } from '@schedule-x/vue'
import { createCalendar, viewMonthGrid, viewMonthAgenda, viewWeek, viewDay } from '@schedule-x/calendar'
import { createEventsServicePlugin } from '@schedule-x/events-service'
import { createEventModalPlugin } from '@schedule-x/event-modal'
import { createCalendarControlsPlugin } from '@schedule-x/calendar-controls'
import '@/assets/css/schedule-x.css'
import CalendarEvent from '@/components/CalendarEvent.vue'
import CalendarEventModal from '@/components/CalendarEventModal.vue'
import { DateTime, Duration } from 'luxon'
import { ref, onMounted } from 'vue'
import api from '@/api-client/index.js';
import EventBus from '@/event-bus'

const ENDPOINT = 'blocks/calendar/v1'

let calendarApp
const eventsServicePlugin = createEventsServicePlugin()
const calendarControls = createCalendarControlsPlugin()
const calendarEventModal = createEventModalPlugin()
const isCalendarReady = ref(false)

const activeDate = ref(DateTime
    .now({ zone: $cookies.get('timezone') })
    .toFormat('yyyy-MM-dd'))

const events = ref(null)
const formatEvents = (events) => events
    .map((event) => ({
        ...event,
        start: DateTime
            .fromSeconds(event.start)
            .setZone($cookies.get('timezone'))
            .toFormat('yyyy-MM-dd HH:mm'),
        end: event.end === 0
            ? DateTime
                .now()
                .setZone($cookies.get('timezone'))
                .toFormat('yyyy-MM-dd HH:mm')
            : DateTime
                .fromSeconds(event.end)
                .setZone($cookies.get('timezone'))
                .toFormat('yyyy-MM-dd HH:mm')
    }))
const fetchEvents = async (from, to) => {
    const { data } = await api.get(ENDPOINT, {
        params: {
            from,
            to,
            incidents: true,
            maintenances: true
        }
    })
    events.value = { ...data, data: formatEvents(data.data) }
}

const meta = ref(null)
const fetchMeta = async () => {
    const { data } = await api.get(ENDPOINT)
    meta.value = data.meta
}

const onRangeUpdate = async ({ start, end }) => {
    await fetchEvents(
        DateTime
            .fromISO(start.split(' ')[0], { zone: $cookies.get('timezone') })
            .startOf('day')
            .toUTC()
            .toISO(),
        DateTime
            .fromISO(end.split(' ')[0], { zone: $cookies.get('timezone') })
            .endOf('day')
            .toUTC()
            .toISO())
    eventsServicePlugin.set(events.value.data)
}

const fetchData = async () => {
    if (!meta.value) await fetchMeta()

    const weekdayOffset = Duration.fromObject({
        days: meta.value.iso_week ? 0 : 1
    })
    await fetchEvents(
        DateTime.fromISO(activeDate.value)
            .setZone($cookies.get('timezone'))
            .startOf('month')
            .startOf('week')
            .minus(weekdayOffset)
            .startOf('day')
            .toUTC()
            .toISO(),
        DateTime.fromISO(activeDate.value)
            .setZone($cookies.get('timezone'))
            .endOf('month')
            .plus(weekdayOffset)
            .endOf('week')
            .minus(weekdayOffset)
            .endOf('day')
            .toUTC()
            .toISO()
    )
}

onMounted(async () => {
    await fetchData()
    calendarApp = createCalendar({
        views: [viewMonthGrid, viewMonthAgenda, viewWeek, viewDay],
        plugins: [
            calendarControls,
            calendarEventModal,
            eventsServicePlugin
        ],
        defaultView: viewMonthGrid.name,
        firstDayOfWeek: meta.value.iso_week ? 1 : 0,
        events: events.value.data,
        weekOptions: {
            gridHeight: 1021
        },
        monthGridOptions: {
            nEventsPerDay: 3
        },
        callbacks: {
            onRangeUpdate
        }
    })
    isCalendarReady.value = true

    EventBus.$on('data-refresh', async () => {
        await fetchData()
        eventsServicePlugin.set(events.value.data)
    })
})
</script>

<template>
    <div class="calendar">
        <ScheduleXCalendar v-if="isCalendarReady" :calendar-app="calendarApp">
            <template #timeGridEvent="{ calendarEvent }">
                <CalendarEvent :event="calendarEvent" />
            </template>
            <template #dateGridEvent="{ calendarEvent }">
                <CalendarEvent :event="calendarEvent" />
            </template>
            <template #monthGridEvent="{ calendarEvent }">
                <CalendarEvent :event="calendarEvent" />
            </template>
            <template #eventModal="{ calendarEvent }">
                <CalendarEventModal :event="calendarEvent" @close="calendarEventModal.close()" />
            </template>
        </ScheduleXCalendar>
    </div>
</template>

<style lang="scss">
.calendar {
    ul {
        list-style: none;
        padding: 0;
    }

    input,
    button {
        font-family: inherit;
        outline: none;
    }

    button {
        background-color: inherit;
        outline: 0;
        border: none;
        cursor: pointer;
    }

    .sx__view-container {
        overflow-x: hidden;
    }

    .sx__calendar {
        border-radius: 0;
        border: none;

        .sx__ripple:not(.sx__chevron-wrapper) {
            border: var(--sx-border);
        }

        .sx__date-picker__day--today {
            background-color: var(--sx-color-primary);
        }

        .sx__view-container {
            border: 1px solid #c4c7c5;
            border-top: none;

            .sx__month-grid-week {
                min-height: 193px;

                &:first-of-type {
                    min-height: 249px;
                }
            }
        }

        .sx__week-grid {
            .sx__event {
                overflow: visible;
                overflow-x: clip;
            }

            .event {
                &__duration-indicator {
                    display: block;
                }
            }
        }

        .sx__month-grid-day__header-day-name {
            display: flex;
            align-items: center;
            position: relative;
            top: -0.5rem;
            width: 100%;
            height: 56px;
            padding: 0.5rem;
            background-color: #F9FAFC;
            border-bottom: 1px solid #c4c7c5;
            text-align: left;
            font-size: 1rem;
            font-weight: 300;
            text-transform: capitalize;
        }

        .sx__month-grid-cell,
        .sx__date-grid-cell {
            height: 30px;
        }

        .sx__month-grid-day__header-date {
            width: 100%;
            padding: 0.5rem;
            font-size: 1rem;
            font-weight: 700;
            justify-content: flex-end;
        }

        .sx__month-grid-day__header-date.sx__is-today {
            background: none;
            color: #000;
        }

        .is-leading-or-trailing  {
            color: var(--sx-color-neutral);

            .sx__month-grid-day__header-date {
                color: var(--sx-color-neutral);
            }
        }
    }

    .sx__date-input-chevron-wrapper {
        width: 1.5rem;
        height: 1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
</style>
