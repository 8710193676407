<template>
    <focus-trap v-model:active="isActive">
        <div class="content">
            <div v-close-popper ref="close" class="close" tabindex="0" v-on:keydown.enter="onEnterKeydown">
                <Icon ref="closeIcon" class="close-icon" name="times" @click="close" />
            </div>
            <div class="header">
                {{ $t('history.list.group.services.popovers.maintenances-history' )}}
            </div>
            <div
                class="maintenance"
                v-for="(maintenance, index) in maintenances"
                :key="maintenance.id"
            >
                <div v-if="maintenance.id" class="title">
                    <router-link
                        class="title__link"
                        :to="{ name: 'maintenance', params: { id: maintenance.id }}"
                        target="_blank"
                    >
                        {{ maintenance.title }}
                    </router-link>
                </div>
                <div
                    class="message"
                >
                    <div class="message__title">
                        <span class="date">{{ maintenance.timestamp_start_formatted }} - {{ maintenance.timestamp_end_formatted }}</span>
                    </div>
                    <div class="message__text" v-html="maintenance.message"></div>
                </div>
                <AffectedServices v-if="showAffectedServices" :services="maintenance.services" />
            </div>
        </div>
    </focus-trap>
</template>

<script>
import AffectedServices from '@/components/AffectedServices/AffectedServices.vue'
import Icon from '@/components/Icon.vue'
import popover from '@/mixins/popover'

export default {
    components: {
        AffectedServices,
        Icon
    },
    mixins: [popover],
    props: {
        maintenances: {
            type: Array,
            default: () => []
        },
        focusTrap: {
            type: Boolean,
            default: false
        },
        showAffectedServices: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        isActive: {
            get() {
                return this.focusTrap
            },
            set(value) {
                if (value) {
                    this.$refs.close.focus()
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/views/events.scss';

.content {
    display: flex;
    flex-direction: column;
    width: 300px;
    max-height: 400px;
    overflow: auto;
    border-radius: 6px;
    margin-bottom: 16px;

    @include breakpoint($md) {
        width: 425px;
    }

    @include breakpoint($lg) {
        width: 500px;
    }

    .header {
        padding: 16px;
        color: $textColor2;
        background-color: $backgroundColor;
        font-weight: 300;

        @include breakpoint($md) {
            padding: 16px 32px;
        }
    }

    .close {
        position: absolute;
        right: 20px;
        top: 18px;

        .close-icon {
            color: silver;
            fill: silver;
            cursor: pointer;
            transition: fill 0.3s;
            font-size: 1.3rem;

            &:hover {
                fill: $textColor2;
            }
        }
    }

    .maintenance {
        .title {
            padding: 16px;
            text-align: left;
            transition: color 0.3s;
            cursor: pointer;
            background-color: rgba(244,247,252, 0.4);

            @include breakpoint($md) {
                padding: 16px 32px;
            }

            &__link {
                text-decoration: none;
                color: $blue;
                font-weight: bold;
                font-size: 0.9375rem;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .message {
            font-size: 0.8125rem;
            padding: 16px;
            background-color: #fff;
            font-size: 0.8125rem;

            @include breakpoint($md) {
                padding: 16px 32px;
            }

            &__title {
                display: flex;

                &__status {
                    font-weight: bold;
                }

                .date {
                    font-weight: 600;
                }
            }

            &__text {
                color: $textColor;
                text-align: left;

                @include quill-styles(13px);
            }
        }
    }
}
</style>
