import api from '@/api-client';
import EventBus from '@/event-bus';

export default {
    async fetch({ commit }, id) {
        const { data: incident } = await api.get(`incidents/${id}`);
        commit('setIncident', incident);
        EventBus.$emit('new-content-loaded')
        return incident
    },

    async fetchCurrent({ commit, dispatch }) {
        const { data: incidents } = await api.get(`incidents`);
        commit('setCurrentIncidents', incidents);
        incidents.forEach(incident => {
            dispatch('fetch', incident.id)
        })
        EventBus.$emit('new-content-loaded')
        return incidents
    }
}
