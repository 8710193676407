import { mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters({
            config: 'config'
        }),
        themeClass() {
            return this.config?.theme?.name
                ? `theme--${this.config.theme.name}`
                : undefined
        }
    }
}
