export default {
    setGroups(state, groups) {
        state.groups = groups;
    },
    setServices(state, { groupId, services }) {
        const group = state.groups.find(({ id }) => id === groupId)

        group.services = services
    },
    setChartData(state, { serviceId, data }) {
        state.data[serviceId] = data
    },
    setService(state, service) {
        state.service = service
    },
    setRange(state, range) {
        state.range = range;
    }
}
