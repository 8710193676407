<template>
    <div class="group">
        <span class="name">{{ group.group_name }}</span>
        <div class="services">
            <a v-for="(status, i) in group.services"
                :key="status.service_id"
                class="link"
                :class="{ 'link--inactive': view !== views.LIST }"
                v-tooltip="{
                    content: serviceName(status), theme: 'info-tooltip'
                }"
                v-smooth-scroll="{ duration: 1000 }"
                :href="view === views.LIST ? `#service-${status.service_id}` : ''"
                :aria-label="getAriaLabel(status)"
            >
                <StatusDot class="service-tooltip" :status="status.status" />
            </a>
        </div>
    </div>
</template>
<script>
import StatusDot from '@/components/StatusDot.vue';
import connectedHubsGrid from '@/mixins/connectedHubsGrid';
import { mapGetters } from 'vuex';

const VIEWS = {
    LIST: 0,
    CALENDAR: 1
}

export default {
    components: {
        StatusDot
    },
    mixins: [connectedHubsGrid],
    props: {
        group: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            views: VIEWS
        }
    },
    computed: {
        ...mapGetters({
            view: 'history/view'
        })
    },
    methods: {
        getAriaLabel(status) {
            var labelKey;
            if (status.incident_ids) {
                labelKey = 'summary.details.statuses.incident-on'
            } else if (status.maintenance_ids) {
                labelKey = 'summary.details.statuses.under-maintenance'
            } else {
                labelKey = 'summary.details.statuses.no-events'
            }
            return this.$t(labelKey, { name: this.serviceName(status) })
        }
    }
}
</script>
<style lang="scss" scoped>
.group {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    background-color: #fff;
    border-radius: 6px;
    padding: 12px;

    .name {
        width: 50%;
        text-align: right;
        color: $textColor;
        padding-right: 32px;
        font-size: 0.8125rem;
    }

    .services {
        width: 50%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .link {
            text-decoration: none;
            color: #fff;
            margin-left: 8px;
            margin-right: 8px;
            margin-bottom: 3px;

            &--inactive {
                cursor: default
            }
        }
    }
}
</style>
