import EventBus from '@/event-bus';
import { hideAllPoppers } from 'floating-vue'

export default {
    mounted() {
        EventBus.$on('historyTabSwitch', () => {
            hideAllPoppers()
        })
    },
    methods: {
        onEnterKeydown () {
            this.$refs.close.click()
        },
        close () {
            this.$emit('close')
        }
    }
}
