<template>
    <a
        class="skip-to-content-link"
        :href="target">
        {{ $t('page.skip-to-content')}}
    </a>
</template>

<script>
export default {
    name: 'SkipToContent',
    props: {
        target: {
            type: String,
            default: '#main'
        }
    }
}
</script>

<style lang="scss" scoped>
.skip-to-content-link {
    background: $backgroundColorDarker;
    height: 30px;
    left: 50%;
    padding: 16px;
    position: absolute;
    transform: translate(-50%, -100%);
    z-index: 999;

    &:focus {
        transform: translate(-50%, 6px);
    }
}
</style>
