<template>
    <div
        class="status"
        :class="{
            'status--unknown': status === 0,
            'status--up': status === 1,
            'status--issue': status === 2,
            'status--down': status === 3,
            'status--content': $slots.default
        }"
    >
        <Icon class="status__incident" v-if="incident" name="exclamation-triangle" />
        <Icon class="status__maintenance" v-if="!incident && maintenance" name="maintenance" />
        <span><slot /></span>
    </div>
</template>

<script>
import Icon from '@/components/Icon.vue'

export default {
    components: {
        Icon
    },
    props: {
        status: {
            type: Number,
            default: 1
        },
        incident: {
            type: Boolean,
            default: false
        },
        maintenance: {
            type: Boolean,
            default: false
        }
    }
}
</script>
<style lang="scss" scoped>
.status {
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: top;
    height: 22px;
    min-width: 22px;
    border-radius: 100%;
    max-width: 250px;
    position: relative;

    span {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    &--content {
        color: #fff;
        padding: 6px 12px;
        margin-top: 4px;
        border-radius: 5px;
        margin-right: 5px;
    }

    &--up {
        background-color: $green;
    }

    &--unknown {
        background-color: $gray;
    }

    &--issue {
        background-color: $orange;

        &:not(.status--content):after {
            content: '';
            width: 8px;
            height: 8px;
            border-radius: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: #000;
        }
    }

    &--down {
        background-color: $red;

        &:not(.status--content):after {
            content: '';
            width: 14px;
            border-bottom: 4px solid #000;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &__incident {
        width: 12px;
        height: 12px;
        cursor: pointer;
        margin-bottom: 2px;
        svg { fill: red; }
    }

    &__maintenance {
        width: 12px;
        height: 12px;
        cursor: pointer;
        margin-top: 2px;
    }
}
</style>
