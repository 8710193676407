export default {
    setConfig(state, config) {
        state.config = config;
    },

    setConfigLoaded(state, loaded = true) {
        state.configLoaded = loaded
    },
    setHubsSwitcher(state, list) {
        state.hubsSwitcher = list
    },
    setRefreshing(state, value) {
        state.refreshing = value;
    },
    setTimeZone(state, tz) {
        state.timeZone = tz;
    },

    setAuthFail(state, authFailed) {
        state.authFailed = authFailed
    },

    set404(state, is404) {
        state.pageNotFound = is404
    },

    setOffline(state, offline) {
        state.offline = offline
    }
}
