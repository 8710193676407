<script>
import { mapGetters } from 'vuex'
import Service from '@/components/AffectedServices/Service.vue'
import StatusDot from '@/components/StatusDot.vue'
import connectedHubsGrid from '@/mixins/connectedHubsGrid.js'

export default {
    name: 'EventCardServices',
    mixins: [connectedHubsGrid],
    components: {
        Service,
        StatusDot
    },
    props: {
        services: {
            type: Array,
            required: true
        }
    },
    computed: {
        ...mapGetters({
            config: 'config'
        }),
        isStatusCountersView () {
            return this.config.event_services_list_mode === 0
        },
        isServicesListView () {
            return this.config.event_services_list_mode !== 0
        },
        statuses () {
            if (this.isStatusCountersView) {
                const [ upCount, affectedCount, downCount ] = [
                    this.getStatusCount(1),
                    this.getStatusCount(2),
                    this.getStatusCount(3)
                ]
                return [
                    {
                        count: upCount,
                        label: this.$t('summary.details.counters.up'),
                        status: 1
                    }, {
                        count: affectedCount,
                        label: this.$t('summary.details.counters.affected'),
                        status: 2
                    }, {
                        count: downCount,
                        label: this.$t('summary.details.counters.down'),
                        status: 3
                    }
                ].filter(({ count }) => count)
            } else {
                return this.services.map(service => ({
                    label: this.serviceName(service),
                    status: service.status
                }));
            }
        }
    },
    methods: {
        getStatusCount (status) {
            return this.services
                ?.filter(service => service.status === status)
                .length
        }
    }
}
</script>

<template>
    <div
        v-if="statuses.length"
        class="event-card-services"
        :class="[
            {
                'event-card-services--status-counters-view': isStatusCountersView,
                'event-card-services--services-list-view': isServicesListView
            },
            `event-card-services--count-${statuses.length}`
        ]"
    >
        <div
            v-for="{ count, label, status } in statuses"
            class="event-card-services__item"
            v-tooltip="isServicesListView ? label : undefined"
        >
            <StatusDot :status="status" />
            <p>
                <span v-if="count !== undefined" class="event-card-services__item__count">{{ count }}&nbsp;</span><span class="event-card-services__item__label">{{ label }}</span>
            </p>
        </div>
    </div>
</template>

<style scoped lang="scss">
.event-card-services {
    &__item {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        margin-right: 0.75rem;
        font-size: 0.875rem;
        font-weight: 600;

        .status {
            transform: scale(0.7);
            margin: 0 0.125rem;
        }

        p {
            display: flex;
            align-items: center;
            font-size: 0.8125rem;
            font-weight: 500;
            color: #082C4A;
            margin: 0;
            padding: 0;
        }

        &__label {
            text-transform: capitalize;
        }

        &__count {
            font-weight: 800;
        }
    }

    &--status-counters-view {
        display: flex;
        align-items: center;
        height: 100%;
    }

    &--services-list-view {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .event-card-services__item {
            display: flex;
            align-items: center;

            &__label {
                display: inline-block;
                max-width: 220px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                margin-left: 0.25rem;
            }

            &:not(:first-child) {
                margin: 0.5rem 0 0;
            }

            &:last-child:not(:first-of-type) {
                margin-bottom: 0.5rem;
            }
        }
    }

    &--count-3 {
        display: block;

        @include breakpoint($sm) {
            display: flex;
        }
    }
}
</style>
