import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'

const EditSubscriptions = () => import('@/views/subscriptions/edit/Index.vue');
const ForgotSubscriptionLink = () => import('@/views/subscriptions/forgotLink/Index.vue')
const Hub = () => import('@/views/hub/Index.vue')
const Incident = () => import('@/views/incidents/Index.vue');
const Maintenance = () => import('@/views/maintenances/Index.vue');
const NewSubscription = () => import('@/views/subscriptions/new/Index.vue');
const NotFound = () => import('@/views/404/Index.vue')
const RemoveSubscription = () => import('@/views/subscriptions/unsubscribe/Index.vue');
const Subscriptions = () => import('@/views/subscriptions/Index.vue');

const before404Enter = (to) => {
    store.dispatch('set404')
}

const routes = [
    {
        name: 'subscribers',
        path: '/subscribers',
        component: Subscriptions,
        redirect: '/subscribers/new',
        children: [
            {
                name: 'subscribers-new',
                path: 'new',
                component: NewSubscription
            }, {
                name: 'subscribers-with-token-new',
                path: ':token/new',
                component: NewSubscription
            }, {
                name: 'subscribers-edit',
                path: ':token/edit',
                component: EditSubscriptions
            }, {
                name: 'subscribers-unsub',
                path: ':token/unsubscribe',
                component: RemoveSubscription
            }, {
                name: 'subscribers-forgot-link',
                path: 'forgot-link',
                component: ForgotSubscriptionLink
            }
        ]
    },
    { path: '/incidents/:id', component: Incident, name: 'incident' },
    { path: '/maintenances/:id', component: Maintenance, name: 'maintenance' },
    { path: '/', component: Hub },
    { path: '/404', beforeEnter: before404Enter },
]
const router = createRouter({
    history: createWebHistory(''),
    scrollBehavior(to) {
        if ((to.hash || '').length > 0) {
            return { el: to.hash }
        }

        return { top: 0 }
    },
    routes
})

export default router
