export default {
    forGroups(state) {
        return state.groups.filter(group => group.services.length);
    },
    groups(state) {
        return state.groups;
    },
    counters(state) {
        return state.counters;
    }
}
