export default {
    events(state) {
        return state.current.map(id => state.events[id])
    },
    days(state) {
        return state.days
    },
    date(state) {
        return state.date
    }
}
