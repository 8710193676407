import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
    events: {},
    current: [],
    days: [],
    date: new Date()
};

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true
}
