<template>
    <div class="planned-maintenances">
        <div class="header" v-if="showHeading">
            <h2 class="header__text">{{ $t('summary.planned-maintenance.label') }}</h2>
        </div>
        <MaintenancesCalendar
            v-if="futureEventsExist && config.maintenance_list_mode === 0"
        />
        <PlannedMaintenancesList
            v-else-if="futureEventsExist && config.maintenance_list_mode === 1"
            :maintenances="maintenances"
            :paginated="config.planned_maintenance_list_pagination"
        />
        <NoEventsBox
            v-else-if="config.maintenance_list_mode !== 2"
            :text="$t('summary.planned-maintenance.no-events')"
        />
    </div>
</template>
<script>
import Maintenance from './notification/Maintenance.vue'
import Status from '@/components/StatusDot.vue';
import DatePicker from './planned-maintenances/DatePicker.vue'
import PlannedMaintenancesList from './planned-maintenances/PlannedMaintenancesList.vue'
import MaintenancesPopover from '@/views/hub/components/history/list/group/popovers/MaintenancesPopover.vue'
import NoEventsBox from './NoEventsBox.vue'
import MaintenancesCalendar from './planned-maintenances/MaintenancesCalendar.vue'
import { mapGetters, mapActions } from 'vuex';

export default {
    components: {
        Maintenance,
        MaintenancesCalendar,
        Status,
        DatePicker,
        MaintenancesPopover,
        PlannedMaintenancesList,
        NoEventsBox
    },
    computed: {
        ...mapGetters({
            config: 'config',
            maintenances: 'maintenances/planned',
            futureEventsExist: 'maintenances/futureEventsExist'
        }),
        showHeading () {
            return ![0, 2].includes(this.config.maintenance_list_mode) ||
                (!this.futureEventsExist && this.config.maintenance_list_mode !== 2)
        }
    }
}
</script>
<style lang="scss" scoped>
.planned-maintenances {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;

    a:hover, a:visited, a:link, a:active {
        text-decoration: none;
    }

    @include breakpoint($lg) {
        margin-top: 32px;
    }

    .header {
        padding: 16px;

        &__text {
            font-weight: 600;
            font-size: 1.125rem;
            color: #29394A;
            margin-block-start: 0;
            margin-block-end: 0;
        }
    }

    .icon {
        color: $maintenance-color;
    }

    .day {
        &--event {
            .circle {
                background-color: $blue;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 36px;
                width: 36px;
                border-radius: 50%;
                margin: auto;
                color:#fff;
                line-height: 2.25rem;
                text-align: center;
                cursor: pointer;
            }
        }

        &--past.day--event {
            .circle {
                background-color: $textColor;
            }
        }
    }
}
</style>
